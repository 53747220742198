import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { getUser, Login } from "../../utils/UserController";
import LoginHeader from "../common/LoginHeader";
import forgetLockImg from "../../assets/logo/forgetlock.png";
import { checkCircle, checkImg, eyeClose, eyeOpen } from "../../assets/media";
import Toast from "../components/Toast";
import { showLoader } from "../../utils/Utils";
import Cookies from 'js-cookie';
const LoginPage = () => {
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginTab, setLoginTab] = useState("phone");
  const [viewPhonePass, setViewPhonePass] = useState(false);
  const [viewEmailPass, setViewEmailPass] = useState(false);
  const navigate = useNavigate();
  const [pcode, setPcode] = useState("+91");
  const [cphoneview, setCphoneview] = useState(false);

  const [agree, setAgree] = useState(true);
  const handleAgree = () => {
    setAgree(!agree);
  };

  const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });

  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 3000); // Hide after 3 seconds
  };

  const handlePcode = () => {
    setCphoneview(!cphoneview);
  };

  const getCurrentFormattedDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleLogin = async () => {
    if (loginTab === "email") {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Please login with your phone number`
      );
      return false;
    }

    if (
      (phone === "" || phone.length < 10) &&
      (password === "" || password.length < 6)
    ) {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Enter valid phone or password`
      );
      return false;
    }
    showLoader(true);
    const response = await Login(phone, password);

    if (response.data.status) {
      const token = response.data.value;

      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 5);

      // expires: expirationDate
      // Cookies.set('auth', response.data.value, { secure: true, sameSite: 'None',    });
      Cookies.set('auth', response.data.value, {
        secure: true,         // Ensures the cookie is sent over HTTPS only
        sameSite: 'None',     // Required for cross-domain cookies
        domain: '.chennaiclub.org' // Set to the primary domain, starting with a dot for subdomain access
      });


      const formattedDate = getCurrentFormattedDate();
      localStorage.setItem('currentDate', formattedDate);
      localStorage.setItem("token", token);
      // localStorage.setItem("uid", response.data.uid);
      localStorage.setItem("bcRememberedPhone", phone);
      localStorage.setItem("bcRememberedPassword", password);
      navigate("/");
    } else {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>${response.data.message}`
      );
    }
    showLoader(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        handleLogin();
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [phone, password]);

  useEffect(() => {
    const rememberedPhone = localStorage.getItem("bcRememberedPhone");
    const rememberedPassword = localStorage.getItem("bcRememberedPassword");

    if (rememberedPhone && rememberedPassword) {
      setPhone(rememberedPhone);
      setPassword(rememberedPassword);
    }

    const getUserData = async () => {
      showLoader(true);
      try {
        const res = await getUser();
        if (res.data.status) {
          showToast(
            `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>${res.data.message}`
          );
        }
        showLoader(false);
      } catch (error) {
        console.log(error);
      }
    };
    getUserData();
  }, []);

  return (
    <>
      <div className="container">
        <LoginHeader path="/" heading="Login" />
        <div className="info">
          <span className="info-heading">Log in</span>
          <span className="info-text">
            Please log in with your phone number or email
          </span>
          <span className="info-text">
            If you forget your password, please contact customer service
          </span>
        </div>
        <div className="tab">
          <div
            className={`tab1 ${loginTab === "phone" ? "active" : ""}`}
            onClick={() => setLoginTab("phone")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25px"
              height="25px"
              viewBox="0 0 48 48"
            >
              <defs>
                <mask id="ipSPhone0">
                  <g fill="none" strokeWidth="4">
                    <path
                      fill="#fff"
                      stroke="#fff"
                      strokeLinejoin="round"
                      d="M8 30h32v12a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2z"
                    />
                    <path
                      stroke="#fff"
                      strokeLinejoin="round"
                      d="M40 30V6a2 2 0 0 0-2-2H10a2 2 0 0 0-2 2v24"
                    />
                    <path stroke="#000" strokeLinecap="round" d="M22 37h4" />
                  </g>
                </mask>
              </defs>
              <path
                className="color"
                fill="#768096"
                d="M0 0h48v48H0z"
                mask="url(#ipSPhone0)"
              />
            </svg>
            <span className="phone-login-text">Login with phone</span>
          </div>
          <div
            className={`tab2 ${loginTab === "email" ? "active" : ""}`}
            onClick={() => setLoginTab("email")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25px"
              height="25px"
              viewBox="0 0 36 36"
            >
              <path
                fill="#768096"
                d="M32.33 6a2 2 0 0 0-.41 0h-28a2 2 0 0 0-.53.08l14.45 14.39Z"
                className="clr-i-solid clr-i-solid-path-1 color"
              />
              <path
                fill="#768096"
                d="m33.81 7.39l-14.56 14.5a2 2 0 0 1-2.82 0L2 7.5a2 2 0 0 0-.07.5v20a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V8a2 2 0 0 0-.12-.61M5.3 28H3.91v-1.43l7.27-7.21l1.41 1.41Zm26.61 0h-1.4l-7.29-7.23l1.41-1.41l7.27 7.21Z"
                className="clr-i-solid clr-i-solid-path-2 color"
              />
              <path fill="none" d="M0 0h36v36H0z" />
            </svg>
            <span className="email-login-text">Email Login</span>
          </div>
        </div>
        {loginTab === "phone" ? (
          <div className="login-container">
            <div className="input-container">
              <div className="label">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"
                  height="25px"
                  viewBox="0 0 48 48"
                >
                  <defs>
                    <mask id="ipSPhone0">
                      <g fill="none" strokeWidth="4">
                        <path
                          fill="#fff"
                          stroke="#fff"
                          strokeLinejoin="round"
                          d="M8 30h32v12a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2z"
                        />
                        <path
                          stroke="#fff"
                          strokeLinejoin="round"
                          d="M40 30V6a2 2 0 0 0-2-2H10a2 2 0 0 0-2 2v24"
                        />
                        <path
                          stroke="#000"
                          strokeLinecap="round"
                          d="M22 37h4"
                        />
                      </g>
                    </mask>
                  </defs>
                  <path
                    className="color"
                    fill="#6485ff"
                    d="M0 0h48v48H0z"
                    mask="url(#ipSPhone0)"
                  />
                </svg>
                <span>Phone number</span>
              </div>
              <div className="input-group">
                <div
                  className="input"
                  style={{ position: "relative" }}
                  onClick={handlePcode}
                >
                  {pcode}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25px"
                    height="25px"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="none"
                      stroke="#768096"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m7 10l5 5m0 0l5-5"
                    />
                  </svg>
                  <div className={`pcode-dropdown ${cphoneview ? "show" : ""}`}>
                    <ul>
                      <li>+91 - India ( भारत )</li>
                    </ul>
                  </div>
                </div>
                <input
                  type="number"
                  className="d-input"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  style={{ marginLeft: "10px" }}
                  placeholder="Enter phone number"
                />
              </div>
            </div>

            <div className="input-container">
              <div className="label">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"
                  height="25px"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#6485ff"
                    fillRule="evenodd"
                    d="M5.25 10.055V8a6.75 6.75 0 0 1 13.5 0v2.055c1.115.083 1.84.293 2.371.824C22 11.757 22 13.172 22 16c0 2.828 0 4.243-.879 5.121C20.243 22 18.828 22 16 22H8c-2.828 0-4.243 0-5.121-.879C2 20.243 2 18.828 2 16c0-2.828 0-4.243.879-5.121c.53-.531 1.256-.741 2.371-.824M6.75 8a5.25 5.25 0 0 1 10.5 0v2.004C16.867 10 16.451 10 16 10H8c-.452 0-.867 0-1.25.004zM14 16a2 2 0 1 1-4 0a2 2 0 0 1 4 0"
                    clipRule="evenodd"
                  />
                </svg>
                <span>Password</span>
              </div>
              <div className="input-group">
                <input
                  type={viewPhonePass ? "text" : "password"}
                  className="d-input password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                />
                <img
                  src={`${viewPhonePass ? eyeOpen : eyeClose}`}
                  alt=""
                  onClick={() => setViewPhonePass(!viewPhonePass)}
                />
              </div>
            </div>
            <div className="input-container">
              <div className="label">
                <div
                  className="d-flex align-center"
                  style={{ gap: "10px" }}
                  onClick={handleAgree}
                >
                  <img
                    src={agree ? checkImg : checkCircle}
                    alt=""
                    className="check-img"
                  />
                  <span className="rp">Remember password</span>
                </div>
              </div>
            </div>
            <div className="button-container">
              <div className="btn active" onClick={handleLogin}>
                Log in
              </div>
              <div className="btn">
                <Link to="/register">Register</Link>
              </div>
            </div>
            <div className="other-container">
              <div className="other1">
                <Link to="/promotion/CustomerSupport">
                  <img src={forgetLockImg} alt="" />
                </Link>
                <span>Forgot Password</span>
              </div>
              <div className="other2">
                <Link to="/promotion/CustomerSupport">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40px"
                    height="40px"
                    viewBox="0 0 48 48"
                  >
                    <path fill="#bf360c" d="M13 30h22v12H13z" />
                    <g fill="#ffa726">
                      <circle cx="10" cy="26" r="4" />
                      <circle cx="38" cy="26" r="4" />
                    </g>
                    <path
                      fill="#ffb74d"
                      d="M39 19c0-12.7-30-8.3-30 0v10c0 8.3 6.7 15 15 15s15-6.7 15-15z"
                    />
                    <g fill="#784719">
                      <circle cx="30" cy="26" r="2" />
                      <circle cx="18" cy="26" r="2" />
                    </g>
                    <path
                      fill="#ff5722"
                      d="M24 2C15.5 2 3 7.8 3 35.6L13 42V24l16.8-9.8L35 21v21l10-8.2c0-5.6-.9-29-15.4-29L28.2 2z"
                    />
                    <path
                      fill="#757575"
                      d="M45 24c-.6 0-1 .4-1 1v-7c0-8.8-7.2-16-16-16h-9c-.6 0-1 .4-1 1s.4 1 1 1h9c7.7 0 14 6.3 14 14v10c0 .6.4 1 1 1s1-.4 1-1v2c0 3.9-3.1 7-7 7H24c-.6 0-1 .4-1 1s.4 1 1 1h13c5 0 9-4 9-9v-5c0-.6-.4-1-1-1"
                    />
                    <g fill="#37474f">
                      <path d="M45 22h-1c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2h1c1.1 0 2-.9 2-2v-4c0-1.1-.9-2-2-2" />
                      <circle cx="24" cy="38" r="2" />
                    </g>
                  </svg>
                </Link>
                <span>Customer Service</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="login-container">
            <div className="input-container">
              <div className="label">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"
                  height="25px"
                  viewBox="0 0 36 36"
                >
                  <path
                    fill="#6485ff"
                    d="M32.33 6a2 2 0 0 0-.41 0h-28a2 2 0 0 0-.53.08l14.45 14.39Z"
                    className="clr-i-solid clr-i-solid-path-1 color"
                  />
                  <path
                    fill="#6485ff"
                    d="m33.81 7.39l-14.56 14.5a2 2 0 0 1-2.82 0L2 7.5a2 2 0 0 0-.07.5v20a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V8a2 2 0 0 0-.12-.61M5.3 28H3.91v-1.43l7.27-7.21l1.41 1.41Zm26.61 0h-1.4l-7.29-7.23l1.41-1.41l7.27 7.21Z"
                    className="clr-i-solid clr-i-solid-path-2 color"
                  />
                  <path fill="none" d="M0 0h36v36H0z" />
                </svg>
                <span>Mail</span>
              </div>
              <div className="input-group">
                <input
                  type="text"
                  className="d-input"
                  value={email}
                  placeholder="Enter your email address"
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ marginLeft: "10px" }}
                />
              </div>
            </div>

            <div className="input-container">
              <div className="label">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"
                  height="25px"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#6485ff"
                    fillRule="evenodd"
                    d="M5.25 10.055V8a6.75 6.75 0 0 1 13.5 0v2.055c1.115.083 1.84.293 2.371.824C22 11.757 22 13.172 22 16c0 2.828 0 4.243-.879 5.121C20.243 22 18.828 22 16 22H8c-2.828 0-4.243 0-5.121-.879C2 20.243 2 18.828 2 16c0-2.828 0-4.243.879-5.121c.53-.531 1.256-.741 2.371-.824M6.75 8a5.25 5.25 0 0 1 10.5 0v2.004C16.867 10 16.451 10 16 10H8c-.452 0-.867 0-1.25.004zM14 16a2 2 0 1 1-4 0a2 2 0 0 1 4 0"
                    clipRule="evenodd"
                  />
                </svg>
                <span>Password</span>
              </div>
              <div className="input-group">
                <input
                  type={viewEmailPass ? "text" : "password"}
                  className="d-input password"
                  value={password}
                  placeholder="Enter your password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <img
                  src={`${viewEmailPass ? eyeOpen : eyeClose}`}
                  alt=""
                  onClick={() => setViewEmailPass(!viewEmailPass)}
                />
              </div>
            </div>
            <div className="input-container">
              <div className="label">
                <div
                  className="d-flex align-center"
                  style={{ gap: "10px" }}
                  onClick={handleAgree}
                >
                  <img
                    src={agree ? checkImg : checkCircle}
                    alt=""
                    className="check-img"
                  />
                  <span className="rp">Remember password</span>
                </div>
              </div>
            </div>
            <div className="button-container">
              <div className="btn active" onClick={handleLogin}>
                Log in
              </div>
              <div className="btn">
                <Link to="/register">Register</Link>
              </div>
            </div>
            <div className="other-container">
              <div className="other1">
                <Link to="/promotion/CustomerSupport">
                  <img src={forgetLockImg} alt="" />
                </Link>
                <span>Forgot Password</span>
              </div>
              <div className="other2">
                <Link to="/promotion/CustomerSupport">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40px"
                    height="40px"
                    viewBox="0 0 48 48"
                  >
                    <path fill="#bf360c" d="M13 30h22v12H13z" />
                    <g fill="#ffa726">
                      <circle cx="10" cy="26" r="4" />
                      <circle cx="38" cy="26" r="4" />
                    </g>
                    <path
                      fill="#ffb74d"
                      d="M39 19c0-12.7-30-8.3-30 0v10c0 8.3 6.7 15 15 15s15-6.7 15-15z"
                    />
                    <g fill="#784719">
                      <circle cx="30" cy="26" r="2" />
                      <circle cx="18" cy="26" r="2" />
                    </g>
                    <path
                      fill="#ff5722"
                      d="M24 2C15.5 2 3 7.8 3 35.6L13 42V24l16.8-9.8L35 21v21l10-8.2c0-5.6-.9-29-15.4-29L28.2 2z"
                    />
                    <path
                      fill="#757575"
                      d="M45 24c-.6 0-1 .4-1 1v-7c0-8.8-7.2-16-16-16h-9c-.6 0-1 .4-1 1s.4 1 1 1h9c7.7 0 14 6.3 14 14v10c0 .6.4 1 1 1s1-.4 1-1v2c0 3.9-3.1 7-7 7H24c-.6 0-1 .4-1 1s.4 1 1 1h13c5 0 9-4 9-9v-5c0-.6-.4-1-1-1"
                    />
                    <g fill="#37474f">
                      <path d="M45 22h-1c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2h1c1.1 0 2-.9 2-2v-4c0-1.1-.9-2-2-2" />
                      <circle cx="24" cy="38" r="2" />
                    </g>
                  </svg>
                </Link>
                <span>Customer Service</span>
              </div>
            </div>
          </div>
        )}
      </div>
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
    </>
  );
};

export default LoginPage;
