import React from "react";
import { useNavigate } from "react-router-dom";

const CommonHeader = ({ heading, svg }) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="common-header">
      <div className="header-heading">{heading}</div>

      <div className="header-back" onClick={goBack}>
        <div className="link-container">{svg}</div>
      </div>
    </div>
  );
};

export default CommonHeader;
