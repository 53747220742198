import React from "react";
import { Link } from "react-router-dom";
import "../../assets/css/footer.css";
import bottomNavBg from "../../assets/img/bottom_nav_bg.png";
import { useLocation } from "react-router-dom";

const BottomNav = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  return (
    <div
      className="main-footer"
      style={{ backgroundImage: `url(${bottomNavBg})` }}
    >
      <Link to="/">
        <div className={`nav-box ${splitLocation[1] === "" ? "active" : ""}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27px"
            height="30px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#768096"
              d="M10.55 2.532a2.25 2.25 0 0 1 2.9 0l6.75 5.692c.507.428.8 1.057.8 1.72v9.31a1.75 1.75 0 0 1-1.75 1.75h-3.5a1.75 1.75 0 0 1-1.75-1.75v-5.007a.25.25 0 0 0-.25-.25h-3.5a.25.25 0 0 0-.25.25v5.007a1.75 1.75 0 0 1-1.75 1.75h-3.5A1.75 1.75 0 0 1 3 19.254v-9.31c0-.663.293-1.292.8-1.72zm1.933 1.147a.75.75 0 0 0-.966 0L4.767 9.37a.75.75 0 0 0-.267.573v9.31c0 .138.112.25.25.25h3.5a.25.25 0 0 0 .25-.25v-5.007c0-.967.784-1.75 1.75-1.75h3.5c.966 0 1.75.783 1.75 1.75v5.007c0 .138.112.25.25.25h3.5a.25.25 0 0 0 .25-.25v-9.31a.75.75 0 0 0-.267-.573z"
            />
          </svg>
          <span>Home</span>
        </div>
      </Link>
      <Link to="/activity">
        <div
          className={`nav-box ${
            splitLocation[1] === "activity" ? "active" : ""
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35px"
            height="30px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#768096"
              fillRule="evenodd"
              d="M8.921 7.293a.75.75 0 0 1 .957.457a2.251 2.251 0 0 0 4.245 0a.75.75 0 1 1 1.414.5a3.751 3.751 0 0 1-7.073 0a.75.75 0 0 1 .457-.957m3.44-1.793h-.722c-1.625 0-2.707.003-3.527.104c-.778.096-1.137.263-1.389.472c-.252.21-.482.531-.72 1.278c-.25.787-.452 1.85-.752 3.448c-.42 2.24-.702 3.761-.752 4.907c-.048 1.105.137 1.575.397 1.888s.687.581 1.782.737c1.135.162 2.683.166 4.962.166h.72c2.28 0 3.827-.004 4.962-.166c1.095-.156 1.522-.424 1.782-.737c.26-.314.445-.783.397-1.888c-.05-1.146-.332-2.667-.752-4.907c-.3-1.598-.501-2.66-.752-3.448c-.238-.747-.468-1.069-.72-1.278s-.61-.376-1.389-.472c-.82-.101-1.902-.104-3.527-.104m-6.596-.578c-1.11.922-1.403 2.483-1.988 5.604c-.823 4.389-1.235 6.583-.035 8.029S7.174 20 11.64 20h.72c4.466 0 6.699 0 7.899-1.445s.788-3.64-.035-8.03c-.585-3.12-.878-4.681-1.989-5.603C17.125 4 15.536 4 12.361 4h-.722c-3.175 0-4.763 0-5.874.922"
              clipRule="evenodd"
            ></path>
          </svg>
          <span>Activity</span>
        </div>
      </Link>
      <Link to="/promotion">
        <div
          className={`nav-box x ${
            splitLocation[1] === "promotion" ? "active" : ""
          }`}
        >
          <div className="promotion-box">
            <svg
              data-v-67fe8f9c=""
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="none"
              viewBox="0 0 57 49"
              className=""
              style={{ width: "30px", height: "30px" }}
            >
              <path
                fill="#fff"
                fillRule="evenodd"
                d="M8.939 1.501A4 4 0 0 1 12.062 0h32.155a4 4 0 0 1 3.124 1.501l7.738 9.673c.427.533.749 1.12.968 1.735H.233a5.99 5.99 0 0 1 .967-1.735L8.94 1.501ZM0 16.091h56.28a5.985 5.985 0 0 1-1.277 2.673l-23.79 28.549a4 4 0 0 1-6.146 0l-23.79-28.55A5.984 5.984 0 0 1 0 16.092Zm20.556 5.936 7.195 10.102a.5.5 0 0 0 .816-.002l7.118-10.093a2.44 2.44 0 0 1 4.435 1.406v.2h-.223c-.326 0-.782.248-1.304.93-.506.663-6.466 8.724-9.651 13.035a.975.975 0 0 1-1.563.007L17.32 24.26s-.394-.62-1.06-.62h-.14v-.195a2.445 2.445 0 0 1 4.436-1.418Z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <span>Promotion</span>
        </div>
      </Link>
      <Link to="/wallet">
        <div
          className={`nav-box ${splitLocation[1] === "wallet" ? "active" : ""}`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35px"
            height="30px"
            viewBox="0 0 24 24"
          >
            <path fill="#768096" d="M19 12a1 1 0 1 1-2 0a1 1 0 0 1 2 0"></path>
            <path
              fill="#768096"
              fillRule="evenodd"
              d="M9.944 3.25h3.112c1.838 0 3.294 0 4.433.153c1.172.158 2.121.49 2.87 1.238c.924.925 1.219 2.163 1.326 3.77c.577.253 1.013.79 1.06 1.47c.005.061.005.126.005.186v3.866c0 .06 0 .125-.004.185c-.048.68-.484 1.218-1.061 1.472c-.107 1.606-.402 2.844-1.326 3.769c-.749.748-1.698 1.08-2.87 1.238c-1.14.153-2.595.153-4.433.153H9.944c-1.838 0-3.294 0-4.433-.153c-1.172-.158-2.121-.49-2.87-1.238c-.748-.749-1.08-1.698-1.238-2.87c-.153-1.14-.153-2.595-.153-4.433v-.112c0-1.838 0-3.294.153-4.433c.158-1.172.49-2.121 1.238-2.87c.749-.748 1.698-1.08 2.87-1.238c1.14-.153 2.595-.153 4.433-.153m10.224 12.5H18.23c-2.145 0-3.981-1.628-3.981-3.75s1.836-3.75 3.98-3.75h1.938c-.114-1.341-.371-2.05-.87-2.548c-.423-.423-1.003-.677-2.009-.812c-1.027-.138-2.382-.14-4.289-.14h-3c-1.907 0-3.261.002-4.29.14c-1.005.135-1.585.389-2.008.812S3.025 6.705 2.89 7.71c-.138 1.028-.14 2.382-.14 4.289s.002 3.262.14 4.29c.135 1.005.389 1.585.812 2.008s1.003.677 2.009.812c1.028.138 2.382.14 4.289.14h3c1.907 0 3.262-.002 4.29-.14c1.005-.135 1.585-.389 2.008-.812c.499-.498.756-1.206.87-2.548M5.25 8A.75.75 0 0 1 6 7.25h4a.75.75 0 0 1 0 1.5H6A.75.75 0 0 1 5.25 8m15.674 1.75H18.23c-1.424 0-2.481 1.059-2.481 2.25s1.057 2.25 2.48 2.25h2.718c.206-.013.295-.152.302-.236V9.986c-.007-.084-.096-.223-.302-.235z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span>Wallet</span>
        </div>
      </Link>
      <Link to="/account">
        <div
          className={`nav-box ${
            splitLocation[1] === "account" ? "active" : ""
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35px"
            height="30px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#768096"
              d="M6.196 17.485q1.275-.918 2.706-1.451Q10.332 15.5 12 15.5t3.098.534t2.706 1.45q.99-1.025 1.593-2.42Q20 13.667 20 12q0-3.325-2.337-5.663T12 4T6.337 6.338T4 12q0 1.667.603 3.064q.603 1.396 1.593 2.42m5.805-4.984q-1.264 0-2.133-.868T9 9.501t.868-2.133T12 6.5t2.132.868T15 9.5t-.868 2.132t-2.131.868M12 21q-1.883 0-3.525-.701t-2.858-1.916t-1.916-2.858T3 12t.701-3.525t1.916-2.858q1.216-1.215 2.858-1.916T12 3t3.525.701t2.858 1.916t1.916 2.858T21 12t-.701 3.525t-1.916 2.858q-1.216 1.215-2.858 1.916T12 21m0-1q1.383 0 2.721-.484q1.338-.483 2.313-1.324q-.974-.783-2.255-1.237T12 16.5t-2.789.445t-2.246 1.247q.975.84 2.314 1.324T12 20m0-8.5q.842 0 1.421-.579T14 9.5t-.579-1.421T12 7.5t-1.421.579T10 9.5t.579 1.421T12 11.5m0 6.75"
            ></path>
          </svg>
          <span>Account</span>
        </div>
      </Link>
    </div>
  );
};

export default BottomNav;
