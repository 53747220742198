import axios from "../api/axiosConfig";
import Cookies from 'js-cookie';

const getToken = () => {
  return localStorage.getItem("token");
};

export const Register = async (username, pwd,cpass, invitecode) => {
  try {
    const response = await axios.post("/api/webapi/register", {
 
      username, pwd,cpass, invitecode,
      device: `${JSON.stringify(navigator.platform)} ${JSON.stringify(
        navigator.userAgent
      )}`,
    });
    return response;
  } catch (error) {
    console.error("Login error:", error);
  }
};

export const Login = async (username, pwd) => {
  try {
    const response = await axios.post("/api/webapi/login", {
      username,
      pwd,
      device: `${JSON.stringify(navigator.platform)} ${JSON.stringify(
        navigator.userAgent
      )}`,
    });


    return response;
  } catch (error) {
    console.error("Login error:", error);
  }
};

export const Logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("uid");
  return true;
};

export const getUser = async () => {
  const ddd=Date.now()

  try {
    const response = await axios.get(`/api/webapi/GetUserInfo?with=${ddd}`,{withCredentials:true});

    return response;
  } catch (error) {
    console.error("Login error:", error);
  }
};

export const getUserBetHistory = async (page, pageto, type) => {
  try {
    const response = await axios.post("/api/webapi/GetMyEmerdList", {
         pageno: page,
        pageto: pageto,
        typeid: type,
    },
      {withCredentials:true}
    );

    return response;
  } catch (error) {
    console.error("Login error:", error);
  }
};

export const getK3BetHistory = async (page, pageto, type) => {
  try {
    const response = await axios.post("/api/webapi/k3/GetMyEmerdList", {
         pageno: page,
        pageto: pageto,
        gameJoin: type,
    },
      {withCredentials:true}
    );

    return response;
  } catch (error) {
    console.error("Login error:", error);
  }
};
export const get5dBetHistory = async (page, pageto, type) => {
  try {
    const response = await axios.post("/api/webapi/5d/GetMyEmerdList", {
         pageno: page,
        pageto: pageto,
        gameJoin: type,
    },
      {withCredentials:true}
    );

    return response;
  } catch (error) {
    console.error("Login error:", error);
  }
};

export const getUserWinStatus = async (game, type) => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.get("/api/user/getUserWinStatus", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        uid: uid,
        game: game,
        type: type,
      },
    });

    return response;
  } catch (error) {
    console.error("Login error:", error);
  }
};

export const addUserBank = async (bankData) => {
 
  try {
    const response = await axios.post('/api/webapi/addBank',bankData, {withCredentials:true});
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getUserBank = async () => {

  try {
    const response = await axios.get('/api/webapi/check/Info', {withCredentials:true});
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const changeUserBank = async (bankid) => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.get("/api/user/changeUserBank", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        uid: uid,
        bankid: bankid,
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};
export const changeUserAvatar = async (avid) => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.get("/api/user/changeUserAvatar", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        uid: uid,
        avid: avid,
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};
export const changeUserName = async (name) => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.get("/api/user/changeUserName", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        uid: uid,
        name: name,
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getLoginList = async () => {
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");

  const response = await axios.get("/api/user/getLoginList", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    params: {
      uid: uid,
    },
  });
  return response;
};

export const getDepositList = async () => {
  const response = await axios.get("/api/webapi/recharge/list", {
  withCredentials:true
  });
  return response;
};

export const getWithdrawalList = async (type, status, date) => {
  
  const response = await axios.get('/api/webapi/withdraw/list', {withCredentials:true});
  return response;
};

export const getOtp = async (number) => {
  const token = localStorage.getItem("token");
  const uid = localStorage.getItem("uid");

  const response = await axios.get("/api/user/getOtp", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    params: {
      uid: uid,
      number: number,
    },
  });
  return response;
};

export const redeemGiftCode = async (gift_code) => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.post(
      "/api/user/redeemGiftCode",
      {
        uid: uid,
        gift_code: gift_code,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getRedeemHistory = async () => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  const response = await axios.get("/api/user/getRedeemHistory", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    params: {
      uid: uid,
    },
  });
  return response;
};

export const makeAttendance = async () => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.post(
      "/api/user/makeAttendance",
      {
        uid: uid,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getPromotion = async () => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.get("/api/user/getPromotion", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        uid: uid,
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};
export const getTransactionHistory = async () => {
  try {
    const response = await axios.get('/api/webapi/transactionhistory', {withCredentials:true});
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};
export const getInvitationList = async () => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.get("/api/user/getInvitationList", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        uid: uid,
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getInviteBonus = async (people, amount) => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.get("/api/user/getInviteBonus", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        uid: uid,
        people: people,
        amount: amount,
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};
export const getInviteBonusList = async () => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.get("/api/user/getInviteBonusList", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        uid: uid,
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};
export const getIssues = async () => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.get("/api/user/getIssues", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        uid: uid,
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getNews = async () => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.get("/api/user/getNews", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        uid: uid,
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const claimFD = async () => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.get("/api/user/claimFD", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      params: {
        uid: uid,
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};
export const getBetHistory = async () => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  try {
    const response = await axios.post('/api/webapi/GetMyEmerdList',{typeid:typeid1,pageno:pageno,pageto:pageto}, {withCredentials:true});
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const changePassword = async (data) => {
  const token = getToken();
  const uid = localStorage.getItem("uid");
  data.uid = uid;
  try {
    const response = await axios.post("/api/user/changePassword", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const submitIssue = async (data) => {
  const token = getToken();
  try {
    const response = await axios.post("/api/user/issues", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};
