import React, { useEffect, useState } from "react";
import CustomHeader from "../../common/CustomHeader";
import { noData } from "../../../assets/media";
import { makeAttendance } from "../../../utils/UserController";
import { coin } from "../../../assets/media";
const AttendanceHistory = () => {
  const [attData, setAttData] = useState([])


  useEffect(() => {
    const getUserData = async () => {
      try {
        const res = await makeAttendance();
        setAttData(res.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getUserData();

  }, []);
  return (
    <>
      <CustomHeader
        bg={"var(--themeBlack)"}
        color={"var(--mainText)"}
        heading={"Attendance History"}
        path={"/activity/Attendance"}
      />
      <div className="card-tabs flex-col">
        {Array.isArray(attData) && attData?.map((item, i) => (
          <div className='nav-bg rounded-md mt-3 p-1' style={{ width: "100%" }}>
            <div className='flex justify-between items-center p-2 '>
              <div>
                <p className='text-14 gray-50 font-medium'>Continuous attendance day</p>
                <p className='fs-sm gray-100'>{item.date}</p>
              </div>
              <div className='flex align-center rounded-2xl color-yellow-bg-200 p-1' style={{ width: "40%" }}>
                <img src={coin} alt="" className='' style={{ width: "20px" }} />
                <p className=' text-14  text-center flex m-auto font-medium text-white'>{item.amount}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      {attData?.length === 0 && (
        <>

          <div className="empty mt-1">
            <img src={noData} alt="" className="noData" />
            <span>No Data</span>
          </div>
        </>
      )}
    </>
  );
};

export default AttendanceHistory;
