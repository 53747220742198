import React, { useEffect, useState, useRef} from "react";
import $, { isEmptyObject } from "jquery";
import loss from "../../../assets/img/loss.png";
import win from "../../../assets/img/win.png";
import { formatMoney } from "../../../utils/Utils";

const WinStatus = ({ datax }) => {
  const [data, setData] = useState("");
  const removerPop = useRef(null);

  useEffect(() => {
    if (removerPop.current) {
      clearTimeout(removerPop.current);
    }

    console.log("data",data)

    if (datax && !isEmptyObject(datax)) {
      localStorage.setItem("bcStatus", JSON.stringify(datax));
      setData(datax);
      $(".container").css("overflow", "hidden");
    } else {
      const status = JSON.parse(localStorage.getItem("bcStatus"));
      setData(status || "");
    }

    removerPop.current = setTimeout(() => {
      closeModel();
    }, 1000 * 10);

    return () => {
      if (removerPop.current) {
        clearTimeout(removerPop.current);
      }
    };
  }, [datax]);

  const closeModel = () => {
    localStorage.removeItem("bcStatus");
    setData("");
    $(".container").css("overflow", "");
  };


  console.log("data",data)
  return (
    <>
      <div
        className={`model ${data?.status === 1 ? "show" : ""}`}
        id="modelwin"
      >
        <div className="result-container">
          <img src={win} alt="" />
          <div className="win-heading">Congratulations</div>
          <div
            className="lottery-result d-flex align-center"
            style={{ gap: "10px" }}
          >
            <span>Lottery Results</span>
            <div className="d-flex d-flex align-center" style={{ gap: "8px" }}>
              <div
                className={`bs bg-${
                  data?.color === "red,violet"
                    ? "rv"
                    : data?.color === "green,violet"
                    ? "gv"
                    : data?.color
                } border-sm`}
              >
                {/* {data?.color ?? "--"} */}
              

                {(data.number) === 0 ? "Red Voilet" :(data.number
) === 5 ? " Green Violet":  (data.number) % 2 == 0 ? "Red" : "Green"}

              </div>
              <div
                className={`num-bg bg-${
                  data?.color === "red,violet"
                    ? "rv"
                    : data?.color === "green,violet"
                    ? "gv"
                    : data?.color
                } border-sm`}
                style={{ width: "25px", height: "25px" }}
              >
                {data?.number ?? "--"}
              </div>
              <div
                className={`bs bg-${
                  data?.color === "red,violet"
                    ? "rv"
                    : data?.color === "green,violet"
                    ? "gv"
                    : data?.color
                } border-sm`}
              >
                {Number(data.number) > 4 ? "big" : "small"}
              </div>
            </div>
          </div>
          <div className="win-detail">
            <span className="win-bonus">Bonus</span>
            <span className="win-amt">{formatMoney(data?.win_amount)}</span>
            <span className="win-period">
              Period : {data?.game} {data?.type ?? "--"} {data?.period_id ?? "--"}
            </span>
          </div>
          <div className="tsc">
            <span className="circle"></span>
            <span className="txt">3 seconds auto close</span>
          </div>
          <span className="win-x" onClick={() => closeModel("modelwin")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40px"
              height="40px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#fff"
                d="m8.4 16.308l3.6-3.6l3.6 3.6l.708-.708l-3.6-3.6l3.6-3.6l-.708-.708l-3.6 3.6l-3.6-3.6l-.708.708l3.6 3.6l-3.6 3.6zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709M12 20q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8"
              />
            </svg>
          </span>
        </div>
      </div>
      <div
        className={`model ${data?.status === 2 ? "show" : ""}`}
        id="modelloss"
      >
        <div className="result-container">
          <img src={loss} alt="" />
          <div className="win-heading" style={{ color: "#8597b0" }}>
            Sorry
          </div>
          <div
            className="lottery-result d-flex align-center"
            style={{ gap: "10px" }}
          >
            <span style={{ color: "#9195a3" }}>Lottery Results</span>
            <div className="d-flex d-flex align-center" style={{ gap: "8px" }}>
              <div
                className={`bs bg-${
                  data?.color === "red,violet"
                    ? "rv"
                    : data?.color === "green,violet"
                    ? "gv"
                    : data?.color
                } border-sm`}
              >
                {/* {data?.color ?? "--"} */}
                {(data.number) === 0 ? "Red Voilet" :(data.number
) === 5 ? " Green Violet":  (data.number) % 2 == 0 ? "Red" : "Green"}
              </div>
              <div
                className={`num-bg bg-${
                  data?.color === "red,violet"
                    ? "rv"
                    : data?.color === "green,violet"
                    ? "gv"
                    : data?.color
                } border-sm`}
                style={{ width: "25px", height: "25px" }}
              >
                {data?.number ?? "--"}
              </div>
              <div
                className={`bs bg-${
                  data?.color === "red,violet"
                    ? "rv"
                    : data?.color === "green,violet"
                    ? "gv"
                    : data?.color
                } border-sm`}
              >
                {Number(data.number) > 4 ? "big" : "small"}
              </div>
            </div>
          </div>
          <div className="win-detail">
            <span
              className="win-bonus"
              style={{
                color: "#8597b0",
                fontSize: "1.6rem",
                marginTop: "20px",
              }}
            >
              Loss
            </span>
            <span className="win-period">
              Period : {data?.game} {data?.type ?? "--"} {data?.period_id ?? "--"}
            </span>
          </div>
          <div className="tsc">
            <span className="circle"></span>
            <span className="txt">3 seconds auto close</span>
          </div>
          <span className="win-x" onClick={() => closeModel("modelloss")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40px"
              height="40px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#fff"
                d="m8.4 16.308l3.6-3.6l3.6 3.6l.708-.708l-3.6-3.6l3.6-3.6l-.708-.708l-3.6 3.6l-3.6-3.6l-.708.708l3.6 3.6l-3.6 3.6zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709M12 20q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8"
              />
            </svg>
          </span>
        </div>
      </div>
    </>
  );
};

export default WinStatus;
