import React, { useEffect, useState } from "react";
import CommonHeader from "../../common/CommonHeader";
import "../../../assets/css/msg.css";
import { getLoginList } from "../../../utils/UserController";
import { formatDate, formatMoney } from "../../../utils/Utils";
import { useNavigate } from "react-router-dom";
import { noData } from "../../../assets/media";

const Msg = () => {
  const [arr, setArr] = useState([]);
  const [deleteId, setDeleteId] = useState(0);

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  useEffect(() => {
    const list = async () => {
      try {
        const res = await getLoginList();
        setArr(res.data.data);
      } catch (error) {
        console.log(error.message);
      }
    };
    list();
  }, []);

  const openDelete = (id) => {
    setDeleteId(id);
    document.querySelector(".overlay").classList.remove("d-none");
    document.getElementById("deleteModel").classList.remove("d-none");
  };

  const closeDelete = () => {
    setDeleteId(null);
    document.querySelector(".overlay").classList.add("d-none");
    document.getElementById("deleteModel").classList.add("d-none");
  };

  const confirmDelete = () => {
    if (deleteId !== null && deleteId >= 0 && deleteId < arr.length) {
      const newArr = arr.filter((_, index) => index !== deleteId);
      setArr(newArr);
    }
    closeDelete();
  };

  return (
    <>
      <CommonHeader
        path="/"
        heading="Notification"
        svg={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
          >
            <path
              fill="var(--lightText)"
              d="m3.55 12l7.35 7.35q.375.375.363.875t-.388.875t-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675T.825 12t.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388t.375.875t-.375.875z"
            />
          </svg>
        }
      />
      <div className="msg-content">
        {arr.length ? (
          arr.map((item, index) => (
            <div className="msg-container" key={index}>
              <div className="d-flex justify-between align-center">
                <div className="d-flex align-center" style={{ gap: "5px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    height="20px"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#768096"
                      fillRule="evenodd"
                      d="M4 4.25A2.75 2.75 0 0 0 1.25 7v10A2.75 2.75 0 0 0 4 19.75h16A2.75 2.75 0 0 0 22.75 17V7A2.75 2.75 0 0 0 20 4.25zm3.43 4.136a.75.75 0 0 0-.86 1.228l5 3.5a.75.75 0 0 0 .86 0l5-3.5a.75.75 0 1 0-.86-1.228L12 11.585z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span
                    className="msg-heading"
                    style={{ textTransform: "uppercase" }}
                  >
                    {item?.action} NOTIFICATION
                  </span>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27px"
                  height="27px"
                  viewBox="0 0 24 24"
                  onClick={() => openDelete(index)}
                >
                  <path
                    fill="#6485ff"
                    d="M7.616 20q-.672 0-1.144-.472T6 18.385V6H5V5h4v-.77h6V5h4v1h-1v12.385q0 .69-.462 1.153T16.384 20zM17 6H7v12.385q0 .269.173.442t.443.173h8.769q.23 0 .423-.192t.192-.424zM9.808 17h1V8h-1zm3.384 0h1V8h-1zM7 6v13z"
                  />
                </svg>
              </div>

              {item?.action === "Login" ? (
                <span className="login-time">
                  {formatDate("Y-m-d h:M:S A", item.created_at)}
                </span>
              ) : (
                <span className="login-time text-green">
                  {formatMoney(item?.amount)}
                </span>
              )}

              <span className="login-text-time">
                {item?.action === "Login" ? "Your account has just been logged in at  " : "" }
                {formatDate("Y-m-d h:M:S A", item.created_at)}
              </span>
            </div>
          ))
        ) : (
          <div className="empty">
            <img src={noData} alt="" className="noData" />
            <span>No Data</span>
          </div>
        )}
      </div>
      <div className="overlay d-none" onClick={closeDelete}></div>
      <div className="delete-model-content d-none" id="deleteModel">
        <div className="text d-flex flex-col align-center">
          <span className="heading">Warning</span>
          <span className="msg">Are you sure to delete this message?</span>
        </div>
        <div className="btn d-flex align-center justify-center">
          <div className="cancel-btn" onClick={closeDelete}>
            Cancel
          </div>
          <div className="confirm-btn" onClick={confirmDelete}>
            Confirm
          </div>
        </div>
        <input type="hidden" id="idInput" value={deleteId} />
      </div>
    </>
  );
};

export default Msg;
