// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.main-footer {
    width: 400px;
    padding: 0px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    background-repeat: no-repeat;
    height: 70px;
    background-size: 100% 100%;
    padding: 0px 25px;
    filter: invert(1) hue-rotate(153deg);
}

.main-footer a {
    text-decoration: none;
}

.nav-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    gap: 3px;
}
.nav-box.active svg{
    stroke: #23367d;
}
.nav-box.active span{
    color: #23367d;
}

.nav-box svg {
    /* width: 30px;
    height: 30px; */
}

.nav-box span {
    font-size: .78rem;
    color: #768096;
}

.nav-box.x {
    margin-top: -23px;
    gap: 7px;
    margin-left: 10px;
}

.promotion-box {
    background-image: var(--main_gradient-color2);
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    border: 2px solid white;
}

.promotion-box svg {
    width: 35px;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/footer.css"],"names":[],"mappings":";;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;IACf,SAAS;IACT,4BAA4B;IAC5B,YAAY;IACZ,0BAA0B;IAC1B,iBAAiB;IACjB,oCAAoC;AACxC;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;IAChB,QAAQ;AACZ;AACA;IACI,eAAe;AACnB;AACA;IACI,cAAc;AAClB;;AAEA;IACI;mBACe;AACnB;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,QAAQ;IACR,iBAAiB;AACrB;;AAEA;IACI,6CAA6C;IAC7C,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;AACf","sourcesContent":["\n\n.main-footer {\n    width: 400px;\n    padding: 0px 10px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    position: fixed;\n    bottom: 0;\n    background-repeat: no-repeat;\n    height: 70px;\n    background-size: 100% 100%;\n    padding: 0px 25px;\n    filter: invert(1) hue-rotate(153deg);\n}\n\n.main-footer a {\n    text-decoration: none;\n}\n\n.nav-box {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-top: 10px;\n    gap: 3px;\n}\n.nav-box.active svg{\n    stroke: #23367d;\n}\n.nav-box.active span{\n    color: #23367d;\n}\n\n.nav-box svg {\n    /* width: 30px;\n    height: 30px; */\n}\n\n.nav-box span {\n    font-size: .78rem;\n    color: #768096;\n}\n\n.nav-box.x {\n    margin-top: -23px;\n    gap: 7px;\n    margin-left: 10px;\n}\n\n.promotion-box {\n    background-image: var(--main_gradient-color2);\n    width: 60px;\n    height: 60px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 30px;\n    border: 2px solid white;\n}\n\n.promotion-box svg {\n    width: 35px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
