import React from "react";
import CustomHeader from "../../common/CustomHeader";
import { noData } from "../../../assets/media";

const AttendanceHistory = () => {
  return (
    <>
      <CustomHeader
        bg={"var(--themeBlack)"}
        color={"var(--mainText)"}
        heading={"Attendance History"}
        path={"/activity/Attendance"}
      />
      <div className="empty mt-1">
        <img src={noData} alt="" className="noData" />
        <span>No Data</span>
      </div>
    </>
  );
};

export default AttendanceHistory;
