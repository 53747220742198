import React, { useEffect, useState } from "react";
import CustomHeader from "../../common/CustomHeader";
import DatePicker from "../DatePicker";
import ListPicker from "../ListPicker";
import { getPromotion } from "../../../utils/UserController";
import { formatMoney, showLoader } from "../../../utils/Utils";

const SubordinateData = () => {
  const now = new Date();
  const currentdate = `${now.getFullYear()}-${
    now.getMonth() + 1
  }-${now.getDate()}`;
  const [selectedDate, setSelectedDate] = useState(currentdate);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showItemPicker, setShowItemPicker] = useState(false);
  const [selectedItem, setSelectedItem] = useState("All");
  const [promotion, setPromotion] = useState([]);

  const handleOpenDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleReturnDate = (date) => {
    setSelectedDate((val) => {
      return date;
    });
  };

  const handleOpenListPicker = () => {
    setShowItemPicker(!showItemPicker);
  };

  const handleReturnList = (item) => {
    setSelectedItem((val) => {
      return item;
    });
  };

  useEffect(() => {
    const getPromorationData = async () => {
      showLoader(true);
      try {
        const res = await getPromotion();
        setPromotion(res.data.data);
        showLoader(false);
      } catch (error) {
        console.log(error);
      }
    };
    getPromorationData();
  }, []);

  const filterList = [
    "All",
    "Tier 1",
    "Tier 2",
    "Tier 3",
    "Tier 4",
    "Tier 5",
    "Tier 6",
  ];

  return (
    <>
      <CustomHeader
        path={"/promotion"}
        bg={"var(--themeBlack)"}
        color={"var(--mainText)"}
        heading={"Subordinate data"}
      />
      <div className="search-container">
        <input type="text" placeholder="Search subordinate UID" />
        <div className="search-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.5"
              d="m21 21l-4.343-4.343m0 0A8 8 0 1 0 5.343 5.343a8 8 0 0 0 11.314 11.314"
            />
          </svg>
        </div>
      </div>
      <div className="select-container">
        <div className="select" onClick={handleOpenListPicker}>
          <span>{selectedItem}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m7 10l5 5l5-5"
            />
          </svg>
        </div>
        <div className="select" onClick={handleOpenDatePicker}>
          <span>{selectedDate}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m7 10l5 5l5-5"
            />
          </svg>
        </div>
      </div>
      <DatePicker openDate={showDatePicker} returnDate={handleReturnDate} />
      <ListPicker
        openList={showItemPicker}
        returnItem={handleReturnList}
        list={filterList}
        heading={"an item"}
      />
      <div className="dash-container">
        <div className="dash-box">
          <span className="amt">{promotion.deposits}</span>
          <span className="txt">Deposit number</span>
        </div>
        <div className="dash-box">
          <span className="amt">{formatMoney(promotion.total_deposits)}</span>
          <span className="txt">Deposit amount</span>
        </div>
        <div className="dash-box">
          <span className="amt">0</span>
          <span className="txt">Number of bettors</span>
        </div>
        <div className="dash-box">
          <span className="amt">0</span>
          <span className="txt">Total bet</span>
        </div>
        <div className="dash-box">
          <span className="amt">0</span>
          <span className="txt">Number of people making first deposit</span>
        </div>
        <div className="dash-box">
          <span className="amt">0</span>
          <span className="txt">First deposit amount</span>
        </div>
      </div>
    </>
  );
};

export default SubordinateData;
