import React, { useState } from "react";
import { attendanceBanner, coin, giftBox } from "../../../assets/media";
import CustomHeader from "../../common/CustomHeader";
import GradientButton from "../GradientButton";
import { Link } from "react-router-dom";
import { makeAttendance } from "../../../utils/UserController";
import Toast from "../Toast";

const Attendance = () => {
  const coins = [
    {
      amt: 5.0,
      day: 1,
    },
    {
      amt: 18.0,
      day: 2,
    },
    {
      amt: 100.0,
      day: 3,
    },
    {
      amt: 200.0,
      day: 4,
    },
    {
      amt: 400.0,
      day: 5,
    },
    {
      amt: 3000.0,
      day: 6,
    },
  ];

  const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });
  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 3000); // Hide after 3 seconds
  };

  const makeAttendanceData = async () => {
    try {
      const res = await makeAttendance();
      if (res.data.status) {
        showToast(
          `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M5 14.5s1.5 0 3.5 3.5c0 0 5.559-9.167 10.5-11" color="#fff"></path></svg>${res.data.message}`
        );
      } else {
        showToast(
          `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>${res.data.message}`
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <CustomHeader
        path={"/activity"}
        bg={"var(--themeBlack)"}
        color={"var(--mainText)"}
        heading={"Attendance"}
      />
      <div
        className="hero-header"
        style={{
          backgroundImage: `url(${attendanceBanner}), linear-gradient(94deg, #f54545 2.72%, #f54545 43.54%, #f54545 98.54%)`,
          height: "270px",
          backgroundSize: "100% 100%",
        }}
      >
        <div className="hero-div1" style={{ padding: "15px 10px", gap: "0" }}>
          <div className="hero-div1-heading" style={{ margin: "0" }}>
            Attendance bonus
          </div>
          <span className="hero-div1-text">
            Get rewards based on consecutive login days
          </span>
        </div>
        <div className="ribbon">
          Attended consecutively <br />
          <span>0</span>
          Day
        </div>
        <div className="ac">
          <span className="accumulated-txt">Accumulated</span>
          <span className="accumulated-amt">₹0.00</span>
        </div>
        <div className="att-btns">
          <Link to="/activity/GameRules">
            <div className="att-btn">Game Rules</div>
          </Link>
          <Link to="/activity/AttendanceHistory">
            <div className="att-btn">Attendance history</div>
          </Link>
        </div>
      </div>
      <div className="coin-container">
        {coins.map((v, i) => (
          <div className="coin" key={i}>
            <span className="amt">₹{v.amt.toLocaleString()}.00</span>
            <img src={coin} alt="" />
            <span className="day">{v.day} day</span>
          </div>
        ))}
        <div className="coin-bottom">
          <img src={giftBox} alt="" />
          <div
            className="coin"
            style={{ justifyContent: "center", width: "100%" }}
          >
            <span className="amt">₹{(7000).toLocaleString()}.00</span>
            <span className="day">7 day</span>
          </div>
        </div>
      </div>
      <div className="make-att-btn" onClick={makeAttendanceData}>
        <GradientButton
          bg={"var(--main_gradient-color)"}
          text={"Attendance"}
          color={"#fff"}
          fw={"bold"}
        />
      </div>
      <div className="size-box-100"></div>
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
    </>
  );
};

export default Attendance;
