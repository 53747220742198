import React from "react";
import { Link } from "react-router-dom";

const GradientButton = ({
  text,
  path,
  bg,
  color,
  textsize,
  width,
  fw,
  padding,
}) => {
  return (
    <>
      {path ? (
        <Link to={path}>
          <div
            className="link-btn"
            style={{
              background: bg,
              color: color,
              fontSize: textsize,
              width: width,
              fontWeight: fw,
              padding: padding,
            }}
          >
            {text}
          </div>
        </Link>
      ) : (
        <div
          className="link-btn"
          style={{
            background: bg,
            color: color,
            fontSize: textsize,
            width: width,
            fontWeight: fw,
            padding: padding,
          }}
        >
          {text}
        </div>
      )}
    </>
  );
};

export default GradientButton;
