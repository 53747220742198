// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-header {
    background-color: var(--themeBlack);
    padding: 0px 12px;
    width: 400px;
    position: fixed;
    top: 0;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
}

.home-logo {
    width: 85px;
}

.header-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-2 {
    width: 50%;
    display: flex;
    justify-content: end;
    gap: 10px;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/header.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,iBAAiB;IACjB,YAAY;IACZ,eAAe;IACf,MAAM;IACN,YAAY;IACZ,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,oBAAoB;IACpB,SAAS;IACT,mBAAmB;AACvB","sourcesContent":[".main-header {\n    background-color: var(--themeBlack);\n    padding: 0px 12px;\n    width: 400px;\n    position: fixed;\n    top: 0;\n    height: 50px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    z-index: 100;\n}\n\n.home-logo {\n    width: 85px;\n}\n\n.header-1 {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.header-2 {\n    width: 50%;\n    display: flex;\n    justify-content: end;\n    gap: 10px;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
