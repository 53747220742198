import React from "react";
import CustomHeader from "../../common/CustomHeader";

const AddUsdt = () => {
  return (
    <>
      <CustomHeader
        path={"/wallet/Withdraw"}
        bg={"var(--themeBlack)"}
        color={"var(--mainText)"}
        heading={"Add USDT address"}
      />
    </>
  );
};

export default AddUsdt;
