import React, { useEffect, useRef, useState } from "react";
import CustomHeader from "../../common/CustomHeader";
import {
  allTab,
  casino,
  lotteryLogo,
  noData,
  slots,
  sports,
} from "../../../assets/media";
import { FaRegDotCircle } from "react-icons/fa";
import { AiOutlineSmallDash } from "react-icons/ai";
import { getRebates } from "../../../utils/UserController";

const RebetHistory = () => {
  const tabs = [
    {
      image: allTab,
      label: "All",
      component: <All />,
    },
    {
      image: lotteryLogo,
      label: "Lottery",
      component: <Lottery />,
    },
    {
      image: casino,
      label: "Casino",
      component: <Casino />,
    },
    {
      image: sports,
      label: "Rummy",
      component: <Rummy />,
    },
    {
      image: slots,
      label: "Slots",
      component: <Slots />,
    },
  ];

  const [activeTab, setActiveTab] = useState(0);
  const tabsRef = useRef(null);

  useEffect(() => {
    if (tabsRef.current) {
      const activeTabElement = tabsRef.current.children[activeTab];
      const tabContainerWidth = tabsRef.current.offsetWidth;
      const tabElementWidth = activeTabElement.offsetWidth;
      const scrollPosition =
        activeTabElement.offsetLeft -
        (tabContainerWidth / 2 - tabElementWidth / 2);
      tabsRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [activeTab]);
  return (
    <>
      <CustomHeader
        bg={"var(--themeBlack)"}
        color={"var(--mainText)"}
        heading={"Rebate history"}
        path={"/activity/BettingRebet"}
      />
      <div className="games-tabs-container" ref={tabsRef}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`games-tab ${index === activeTab ? "active" : ""}`}
            onClick={() => setActiveTab(index)}
          >
            <img src={tab.image} alt="" />
            {tab.label}
          </div>
        ))}
      </div>
      <div className="games-tab-content">{tabs[activeTab].component}</div>
    </>
  );
};

export default RebetHistory;

const All = () => {
  const [isloading, setIsloading] = useState(true);
  const [rebetData, setRebetData] = useState([])
  setTimeout(() => {
    setIsloading(false);
  }, 1000);


  useEffect(() => {
    const getUserData = async () => {

      const rebetData = await getRebates();
      setRebetData(rebetData?.data?.data)

    };
    getUserData();
  }, []);

  return (
    <>
      {isloading ? (
        <div className="server-loader">




        </div>
      ) : (
        <div>

          {Array.isArray(rebetData) && rebetData?.map((data, i) => (
            <div className='nav-bg rounded-xl  mt-3 pb-4 p-1' key={i}>
              <div className='flex justify-between items-center px-3'>
                <h3 className="heading-h3 font-semibold gray-50">{data.type}</h3>

                <p className={`text-base   ${data.status === 0 ? "color-yellow-200" : data.status === 1 ? "color-green" : "color-red-200"}`}>{data.status === 0 ? "Pending" : data.status === 1 ? "Completed" : "Failed"}</p>
              </div>
              <p className='fs-sm p-1 gray-100 border-b border-color-slat leading-5'>{data.today}</p>
              <ul className='px-2 mt-2'>
                <li className='flex  justify-between'>
                  <div className='flex '>
                    <div className='flex  flex-col items-center mt-[3px] mr-1'>
                      <FaRegDotCircle className='blue-color-300 bg-white rounded-full fs-sm mr-1 border-b' />
                      {i < 5 ? (
                        <AiOutlineSmallDash className='rotate-90 mt-[2px] mr-1 blue-color-300 fs-sm' />
                      ) : ""}
                    </div>
                    <p className='gray-100 text-sm '>Betting rebate </p>
                  </div>
                  <span className=' text-sm gray-100'>{data.amount}</span>
                </li>
                <li className='flex  justify-between'>
                  <div className='flex '>
                    <div className='flex  flex-col items-center mt-[3px] mr-1'>
                      <FaRegDotCircle className='blue-color-300 bg-white rounded-full fs-sm mr-1 border-b' />
                      {i < 5 ? (
                        <AiOutlineSmallDash className='rotate-90 mt-[2px] mr-1 blue-color-300 fs-sm' />

                      ) : ""}

                    </div>
                    <p className='gray-100 text-sm '>Rebate rate </p>
                  </div>
                  <span className=' text-sm  color-red-200'>{data.rate}%</span>
                </li>
                <li className='flex  justify-between'>
                  <div className='flex '>
                    <div className='flex  flex-col items-center mt-[3px] mr-1'>
                      <FaRegDotCircle className='blue-color-300 bg-white rounded-full fs-sm mr-1 border-b' />


                    </div>
                    <p className='gray-100 text-sm '>Rebate amount</p>
                  </div>
                  <span className=' text-sm  color-yellow-200'>{data.commission}</span>
                </li>


              </ul>
            </div>
          ))}

{rebetData?.length !==0 &&(
          <div className="empty">
            <img src={noData} alt="" className="noData" />
            <span>No Data</span>
          </div>
)}
        </div>
      )}
    </>
  );
};
const Lottery = () => {
  const [isloading, setIsloading] = useState(true);
  const [rebetData, setRebetData] = useState([])
  setTimeout(() => {
    setIsloading(false);
  }, 1000);


  useEffect(() => {
    const getUserData = async () => {

      const rebetData = await getRebates();
      setRebetData(rebetData?.data?.data)

    };
    getUserData();
  }, []);
  return (
    <>
      {isloading ? (
        <div className="server-loader"></div>
      ) : (
     <div>

{Array.isArray(rebetData) && rebetData?.map((data, i) => (
            <div className='nav-bg rounded-xl  mt-3 pb-4 p-1' key={i}>
              <div className='flex justify-between items-center px-3'>
                <h3 className="heading-h3 font-semibold gray-50">{data.type}</h3>

                <p className={`text-base   ${data.status === 0 ? "color-yellow-200" : data.status === 1 ? "color-green" : "color-red-200"}`}>{data.status === 0 ? "Pending" : data.status === 1 ? "Completed" : "Failed"}</p>
              </div>
              <p className='fs-sm p-1 gray-100 border-b border-color-slat leading-5'>{data.today}</p>
              <ul className='px-2 mt-2'>
                <li className='flex  justify-between'>
                  <div className='flex '>
                    <div className='flex  flex-col items-center mt-[3px] mr-1'>
                      <FaRegDotCircle className='blue-color-300 bg-white rounded-full fs-sm mr-1 border-b' />
                      {i < 5 ? (
                        <AiOutlineSmallDash className='rotate-90 mt-[2px] mr-1 blue-color-300 fs-sm' />
                      ) : ""}
                    </div>
                    <p className='gray-100 text-sm '>Betting rebate </p>
                  </div>
                  <span className=' text-sm gray-100'>{data.amount}</span>
                </li>
                <li className='flex  justify-between'>
                  <div className='flex '>
                    <div className='flex  flex-col items-center mt-[3px] mr-1'>
                      <FaRegDotCircle className='blue-color-300 bg-white rounded-full fs-sm mr-1 border-b' />
                      {i < 5 ? (
                        <AiOutlineSmallDash className='rotate-90 mt-[2px] mr-1 blue-color-300 fs-sm' />

                      ) : ""}

                    </div>
                    <p className='gray-100 text-sm '>Rebate rate </p>
                  </div>
                  <span className=' text-sm  color-red-200'>{data.rate}%</span>
                </li>
                <li className='flex  justify-between'>
                  <div className='flex '>
                    <div className='flex  flex-col items-center mt-[3px] mr-1'>
                      <FaRegDotCircle className='blue-color-300 bg-white rounded-full fs-sm mr-1 border-b' />


                    </div>
                    <p className='gray-100 text-sm '>Rebate amount</p>
                  </div>
                  <span className=' text-sm  color-yellow-200'>{data.commission}</span>
                </li>


              </ul>
            </div>
          ))}
{rebetData?.length !==0 &&(
          <div className="empty">
            <img src={noData} alt="" className="noData" />
            <span>No Data</span>
          </div>
)}
     </div>
      )}
    </>
  );
};
const Casino = () => {
  const [isloading, setIsloading] = useState(true);
  setTimeout(() => {
    setIsloading(false);
  }, 1000);
  return (
    <>
      {isloading ? (
        <div className="server-loader"></div>
      ) : (
        <div className="empty">
          <img src={noData} alt="" className="noData" />
          <span>No Data</span>
        </div>
      )}
    </>
  );
};
const Rummy = () => {
  const [isloading, setIsloading] = useState(true);
  setTimeout(() => {
    setIsloading(false);
  }, 1000);
  return (
    <>
      {isloading ? (
        <div className="server-loader"></div>
      ) : (
        <div className="empty">
          <img src={noData} alt="" className="noData" />
          <span>No Data</span>
        </div>
      )}
    </>
  );
};
const Slots = () => {
  const [isloading, setIsloading] = useState(true);
  setTimeout(() => {
    setIsloading(false);
  }, 1000);
  return (
    <>
      {isloading ? (
        <div className="server-loader"></div>
      ) : (
        <div className="empty">
          <img src={noData} alt="" className="noData" />
          <span>No Data</span>
        </div>
      )}
    </>
  );
};
