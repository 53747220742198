import React from "react";
import $ from "jquery";
import loss from "../../../assets/img/loss.png";
import win from "../../../assets/img/win.png";

const K3WinStatus = ({ data }) => {
  setTimeout(() => {
    $("#modelloss").attr("class", "model");
    $("#modelwin").attr("class", "model");
  }, 3000);
    
    console.log(data);
    
    
  const closeModel = (id) => {
    $("#" + id).addClass("hide");
    setTimeout(() => {
      $("#" + id).removeClass("show");
    }, 2000);
  };

  console.log("dada",data)
  return (
    <>
      <div
        className={`model ${data.status === 1 ? "show" : ""}`}
        id="modelwin"
      >
        <div className="result-container">
          <img src={win} alt="" />
          <div className="win-heading">Congratulations</div>
          <div
            className="lottery-result d-flex align-center"
            style={{ gap: "20px" }}
          >
            <span>Lottery Results</span>
            <div className="d-flex d-flex align-center" style={{ gap: "10px" }}>
              <div className="bs bg-red">  {Number(data.number) > 10 ? "big" : "small"}</div>
              <div
                className="num-bg bg-red"
                style={{ width: "25px", height: "25px" }}
              >
                {data.number ?? "--"}
              </div>
              <div className="bs bg-big">{Number(data.number) %2 ? "Even" : "Odd"}</div>
            </div>
          </div>
          <div className="win-detail">
            <span className="win-bonus">Bonus</span>
            <span className="win-amt">
              ₹{Number(data.win_amount).toFixed(2) ?? "--"}
            </span>
            <span className="win-period">
              Period : {data.type ?? "--"}minute {data.period_id ?? "--"}
            </span>
          </div>
          <div className="tsc">
            <span className="circle"></span>
            <span className="txt">3 seconds auto close</span>
          </div>
          <span className="win-x" onClick={() => closeModel("modelwin")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50px"
              height="50px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#fff"
                d="m8.4 16.308l3.6-3.6l3.6 3.6l.708-.708l-3.6-3.6l3.6-3.6l-.708-.708l-3.6 3.6l-3.6-3.6l-.708.708l3.6 3.6l-3.6 3.6zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709M12 20q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8"
              />
            </svg>
          </span>
        </div>
      </div>
      <div
        className={`model ${data.status === 2 ? "show" : ""}`}
        id="modelloss"
      >
        <div className="result-container">
          <img src={loss} alt="" />
          <div className="win-heading">Loss</div>
          <div
            className="lottery-result d-flex align-center"
            style={{ gap: "20px" }}
          >
            <span>Lottery Results</span>
            <div className="d-flex d-flex align-center" style={{ gap: "10px" }}>
              <div className="bs bg-red">{Number(data.number) > 10 ? "big" : "small"}</div>
              <div
                className="num-bg bg-red"
                style={{ width: "25px", height: "25px" }}
              >
                {data.number ?? "--"}
              </div>
              <div className="bs bg-big">{Number(data.number) %2 ? "Even" : "Odd"}</div>
            </div>
          </div>
          <div className="win-detail">
            <span className="win-bonus">Bonus</span>
            <span className="win-amt">
              -₹{Number(data.amount).toFixed(2) ?? "--"}
            </span>
            <span className="win-period">
              Period : {data.type ?? "--"}minute {data.period_id ?? "--"}
            </span>
          </div>
          <div className="tsc">
            <span className="circle"></span>
            <span className="txt">3 seconds auto close</span>
          </div>
          <span className="win-x" onClick={() => closeModel("modelloss")}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50px"
              height="50px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#fff"
                d="m8.4 16.308l3.6-3.6l3.6 3.6l.708-.708l-3.6-3.6l3.6-3.6l-.708-.708l-3.6 3.6l-3.6-3.6l-.708.708l3.6 3.6l-3.6 3.6zM12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709M12 20q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8"
              />
            </svg>
          </span>
        </div>
      </div>
    </>
  );
};

export default K3WinStatus;
