import React from "react";
import CustomHeader from "../../common/CustomHeader";

const GameRule = () => {
  const days = [
    {
      bonus: 5.0,
      day: 1,
      amount: 200.0,
    },
    {
      bonus: 18.0,
      day: 2,
      amount: 1000.0,
    },
    {
      bonus: 100.0,
      day: 3,
      amount: 3000.0,
    },
    {
      bonus: 200.0,
      day: 4,
      amount: 10000.0,
    },
    {
      bonus: 400.0,
      day: 5,
      amount: 20000.0,
    },
    {
      bonus: 3000.0,
      day: 6,
      amount: 100000.0,
    },
    {
      bonus: 7000.0,
      day: 7,
      amount: 2000000.0,
    },
  ];
  const rules = [
    "The higher the number of consecutive login days, the more rewards you get, up to 7 consecutive days",
    "During the activity, please check once a day",
    "Players with no deposit history cannot claim the bonus",
    "Deposit requirements must be met from day one",
    "The platform reserves the right to final interpretation of this activity",
    "When you encounter problems, please contact customer service",
  ];

  return (
    <>
      <CustomHeader
        bg={"var(--themeBlack)"}
        color={"var(--mainText)"}
        heading={"Game Rule"}
        path={"/activity/Attendance"}
      />
      <div className="table-container game-rule-table">
        <div className="table">
          <div className="table-header">
            <div className="table-row">
              <div className="table-col">Continuous attendance</div>
              <div className="table-col">Accumulated amount</div>
              <div className="table-col">Attendance bonus</div>
            </div>
          </div>
          <div className="table-body">
            {days.map((v, i) => (
              <div
                className={`table-row ${i % 2 === 1 ? "odd" : "even"}`}
                key={i}
              >
                <div className="table-col">{v.day}</div>
                <div className="table-col">₹{v.amount.toLocaleString()}.0</div>
                <div className="table-col">₹{v.bonus.toLocaleString()}.0</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="rule-container">
        {rules.map((v, i) => (
          <div className="list" key={i}>
            <span>{v}</span>
          </div>
        ))}
      </div>
    </>
  );
};

export default GameRule;
