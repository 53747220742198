import axios from "../api/axiosConfig";

export const getWingoList = async (type,page,pageto) => {
  const token = localStorage.getItem("token");

  const response = await axios.post("/api/webapi/GetNoaverageEmerdList", {
           typeid: type,
           pageno: page ? page : 1,
      pageto:pageto?pageto:10,
   
  },{withCredentials:true});
  return response;
};

export const getK3List = async (type,page,pageto) => {
  const response = await axios.post("/api/webapi/k3/GetNoaverageEmerdList",  
    {gameJoin:type,pageno:page,pageto:pageto},{withCredentials:true});
  return response;
};

export const getD5List = async (type,page,pageto) => {
 

  const response = await axios.post('/api/webapi/5d/GetNoaverageEmerdList', {gameJoin:type,pageno:page,pageto:pageto},{withCredentials:true});
  return response;
};

export const getTrxWinList = async (type,page,pageto) => {

  const response = await axios.post("/api/webapi/GetNoaverageEmerdList", {
           typeid: type,
           pageno: page ? page : 1,
      pageto:pageto?pageto:10,   
  },{withCredentials:true});
  return response;
};
export const getBanks = async () => {
  const token = localStorage.getItem("token");

  const response = await axios.get("/api/user/banks", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  return response;
};
