import React, { useEffect, useState } from "react";
import $ from "jquery";
import CommonHeader from "../../common/CommonHeader";
import {
  acNotify,
  av0,
  av1,
  av2,
  av3,
  av4,
  av5,
  av6,
  av7,
  av8,
  av9,
  av10,
  av11,
  av12,
  av13,
  av14,
  av15,
  av16,
  av17,
  av18,
  av19,
  av20,
  lock,
} from "../../../assets/media";
import CustomHeader from "../../common/CustomHeader";
import { Link } from "react-router-dom";
import { changeUserName, getUser } from "../../../utils/UserController";
import { showLoader } from "../../../utils/Utils";
import Toast from "../Toast";

const Setting = () => {
  const [user, setUser] = useState({});
  const [trigger, setTrigger] = useState(false);

  const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });
  const [av, setAv] = useState(3);
  const [name, setName] = useState("");
  const avatars = [
    av1,
    av2,
    av3,
    av4,
    av5,
    av6,
    av7,
    av8,
    av9,
    av10,
    av11,
    av12,
    av13,
    av14,
    av15,
    av16,
    av17,
    av18,
    av19,
    av20,
  ];
  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 3000); // Hide after 3 seconds
  };
  const handleCopy = (txt) => {
    navigator.clipboard
      .writeText(txt)
      .then(() => {
        showToast(
          `<svg width="25px" height="25px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#fff" stroke="#fff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M56 2L18.8 42.909L8 34.729H2L18.8 62L62 2z" fill="#fff"></path></g></svg>Copy successful`
        );
      })
      .catch((err) => {
        showToast("Failed to copy");
      });
  };

  const handelChanegModel = () => {
    $("#changeName").removeClass("hide");
    $("#changeName").addClass("show");
  };

  const handleConfirm = async () => {
    showLoader(true);
    if (name === "") {
      showToast(`<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="var(--themeBlack)" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Enter a nickname`);
    }
    if (name.trim().length < 3) {
      showToast(`<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="var(--themeBlack)" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Enter a valid nickname`);
    }

    try {
      const res = await changeUserName(name);
      if (res.data.status) {
        showToast(`<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M5 14.5s1.5 0 3.5 3.5c0 0 5.559-9.167 10.5-11" color="#fff"></path></svg>${res.data.message}`);
        $("#changeName").removeClass("show");
        $("#changeName").addClass("hide");
        showLoader(false);
        setTrigger((prev) => !prev);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const getUserData = async () => {
      showLoader(true);
      try {
        const res = await getUser();
        setUser(res.data.data);
        setAv(res.data.data.avatar);
        setName(res.data.data.name);
        showLoader(false);
      } catch (error) {
        console.log(error);
      }
    };
    getUserData();
  }, [trigger]);
  return (
    <>
      <CustomHeader
        path={"/account"}
        bg={"var(--main_gradient-color)"}
        color={"var(--mainText)"}
        heading={"Setting Center"}
      />
      <div className="ac-banner" style={{ height: "150px" }}></div>
      <div
        className="ac-balance-card"
        style={{
          marginTop: "-150px",
          marginBottom: "20px",
          padding: "1px 5px",
        }}
      >
        <div
          className="ac-link"
          style={{
            marginBottom: "10px",
            background: "var(--themeBlack)",
            border: "none",
            padding: "10px 10px",
          }}
        >
          <div className="d-flex align-center">
            <img src={avatars[av]} alt="" className="avatar-img" />
          </div>
          <div className="d-flex align-center" style={{ gap: "5px" }}>
            <Link to={"/account/ChangeAvatar"}>
              <span className="" style={{ color: "var(--lightText)" }}>
                Change avatar
              </span>
            </Link>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15px"
              height="15px"
              viewBox="0 0 24 24"
            >
              <path
                fill="var(--lightText)"
                d="m8.006 21.308l-1.064-1.064L15.187 12L6.942 3.756l1.064-1.064L17.314 12z"
              />
            </svg>
          </div>
        </div>
        <div
          className="ac-link"
          style={{ marginBottom: "10px", background: "var(--themeBlack)", border: "none" }}
          onClick={handelChanegModel}
        >
          <div className="d-flex align-center">
            <span>Nickname</span>
          </div>
          <div className="d-flex align-center" style={{ gap: "5px" }}>
            <span className="" style={{ color: "var(--lightText)" }}>
              {user?.name}
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15px"
              height="15px"
              viewBox="0 0 24 24"
            >
              <path
                fill="var(--lightText)"
                d="m8.006 21.308l-1.064-1.064L15.187 12L6.942 3.756l1.064-1.064L17.314 12z"
              />
            </svg>
          </div>
        </div>
        <div
          className="ac-link"
          style={{ marginBottom: "10px", background: "var(--themeBlack)", border: "none" }}
        >
          <div className="d-flex align-center">
            <span>UID</span>
          </div>
          <div
            className="d-flex align-center"
            style={{ gap: "5px" }}
            onClick={() => handleCopy(user?.uid)}
          >
            <span className="" style={{ color: "var(--lightText)" }}>
              {user?.uid}
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15px"
              height="15px"
              viewBox="0 0 256 256"
            >
              <path
                fill="#6485ff"
                d="M216 32H88a8 8 0 0 0-8 8v40H40a8 8 0 0 0-8 8v128a8 8 0 0 0 8 8h128a8 8 0 0 0 8-8v-40h40a8 8 0 0 0 8-8V40a8 8 0 0 0-8-8m-56 176H48V96h112Zm48-48h-32V88a8 8 0 0 0-8-8H96V48h112Z"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div class="s-i  mb-1">
        <span style={{color: "var(--mainText)"}}>Security information</span>
      </div>
      <div className="ac-link-container" style={{ background: "none" }}>
        <Link to={"/ChangePassword"}>
          <div
            className="ac-link "
            style={{ marginBottom: "10px", background: "var(--themeBlack)" }}
          >
            <div className="d-flex align-center">
              <img src={lock} alt="" style={{ width: "25px" , filter: "hue-rotate(237deg)"}} />
              <span>Login password</span>
            </div>
            <div className="d-flex align-center" style={{ gap: "5px" }}>
              <span className="" style={{ color: "var(--lightText)" }}>
                Edit
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15px"
                height="15px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="var(--lightText)"
                  d="m8.006 21.308l-1.064-1.064L15.187 12L6.942 3.756l1.064-1.064L17.314 12z"
                />
              </svg>
            </div>
          </div>
        </Link>
        <div
          className="ac-link"
          style={{ marginBottom: "10px", background: "var(--themeBlack)" }}
        >
          <div className="d-flex align-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#6485ff"
                d="M12 2c5.523 0 10 4.477 10 10a10 10 0 0 1-19.995.324L2 12l.004-.28C2.152 6.327 6.57 2 12 2m0 9h-1l-.117.007a1 1 0 0 0 0 1.986L11 13v3l.007.117a1 1 0 0 0 .876.876L12 17h1l.117-.007a1 1 0 0 0 .876-.876L14 16l-.007-.117a1 1 0 0 0-.764-.857l-.112-.02L13 15v-3l-.007-.117a1 1 0 0 0-.876-.876zm.01-3l-.127.007a1 1 0 0 0 0 1.986L12 10l.127-.007a1 1 0 0 0 0-1.986z"
              ></path>
            </svg>
            <span>Updated version</span>
          </div>
          <div className="d-flex align-center" style={{ gap: "5px" }}>
            <span className="" style={{ color: "var(--lightText)" }}>
              1.0.1
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15px"
              height="15px"
              viewBox="0 0 24 24"
            >
              <path
                fill="var(--lightText)"
                d="m8.006 21.308l-1.064-1.064L15.187 12L6.942 3.756l1.064-1.064L17.314 12z"
              />
            </svg>
          </div>
        </div>
      </div>
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
      <div className="model" id="changeName">
        <div
          className="model-container"
          style={{ height: "auto", position: "relative" }}
        >
          <div className="model-policy-header">
            <span>Change Nickname</span>
          </div>
          <div className="model-policy-content">
            <div className="input-container">
              <div className="label">
                <span>Nickname</span>
              </div>
              <div className="input-group">
                <input
                  type="text"
                  className="d-input"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter a nickname"
                />
              </div>
            </div>
          </div>
          <div className="model-policy-footer">
            <div className="i-know-btn" onClick={handleConfirm}>
              Confirm
            </div>
          </div>
          <div
            className="close"
            onClick={() => {
              $("#changeName").addClass("hide");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="45px"
              height="45px"
              viewBox="0 0 21 21"
            >
              <g
                fill="none"
                fillRule="evenodd"
                stroke="var(--themeBlack)"
                strokeLinecap="round"
                strokeLinejoin="round"
                transform="translate(2 2)"
              >
                <circle cx={8.5} cy={8.5} r={8}></circle>
                <path d="m5.5 5.5l6 6m0-6l-6 6"></path>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting;
