// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.games-tabs-container {
    width: 93%;
    display: flex;
    overflow-x: auto;
    scrollbar-width: none;
    position: sticky;
    top: 50px;
    margin: auto;
    padding: 10px 0px 5px 0px;
    background-color: var(--bgColor);
    z-index: 1;
}

.games-tabs-container::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
}

.games-tab {
    width: 103px;
    height: 55px;
    padding: 10px 15px;
    cursor: pointer;
    flex-shrink: 0;
    transition: transform 0.3s;
    color: #9195a3;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: .78rem;
    background-color: var(--themeBlack);
    margin: 0px 5px;
}


.games-tab img {
    width: 35px;
    height: 35px;
}

.games-tab.active {
    color: white;
    background-image: var(--main_gradient-color);
}

.games-tab-content {
    width: 93%;
    margin: auto;
    border-radius: 4px;
    margin-top: 20px;
}
.games-tab-lebel{
    display: block;
    border-left: 4px solid #23367d;
    margin: 30px 0px 10px 0px;
    padding-left: 5px;
    font-weight: bold;
    color: var(--mainText);
}`, "",{"version":3,"sources":["webpack://./src/assets/css/games.css"],"names":[],"mappings":";;AAEA;IACI,UAAU;IACV,aAAa;IACb,gBAAgB;IAChB,qBAAqB;IACrB,gBAAgB;IAChB,SAAS;IACT,YAAY;IACZ,yBAAyB;IACzB,gCAAgC;IAChC,UAAU;AACd;;AAEA;IACI,WAAW;IACX,QAAQ;IACR,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,0BAA0B;IAC1B,cAAc;IACd,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,mCAAmC;IACnC,eAAe;AACnB;;;AAGA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,4CAA4C;AAChD;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,8BAA8B;IAC9B,yBAAyB;IACzB,iBAAiB;IACjB,iBAAiB;IACjB,sBAAsB;AAC1B","sourcesContent":["\n\n.games-tabs-container {\n    width: 93%;\n    display: flex;\n    overflow-x: auto;\n    scrollbar-width: none;\n    position: sticky;\n    top: 50px;\n    margin: auto;\n    padding: 10px 0px 5px 0px;\n    background-color: var(--bgColor);\n    z-index: 1;\n}\n\n.games-tabs-container::-webkit-scrollbar {\n    /* WebKit */\n    width: 0;\n    height: 0;\n}\n\n.games-tab {\n    width: 103px;\n    height: 55px;\n    padding: 10px 15px;\n    cursor: pointer;\n    flex-shrink: 0;\n    transition: transform 0.3s;\n    color: #9195a3;\n    border-radius: 5px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    font-size: .78rem;\n    background-color: var(--themeBlack);\n    margin: 0px 5px;\n}\n\n\n.games-tab img {\n    width: 35px;\n    height: 35px;\n}\n\n.games-tab.active {\n    color: white;\n    background-image: var(--main_gradient-color);\n}\n\n.games-tab-content {\n    width: 93%;\n    margin: auto;\n    border-radius: 4px;\n    margin-top: 20px;\n}\n.games-tab-lebel{\n    display: block;\n    border-left: 4px solid #23367d;\n    margin: 30px 0px 10px 0px;\n    padding-left: 5px;\n    font-weight: bold;\n    color: var(--mainText);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
