import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../../src/assets/css/header.css";
import brandLogo from "../../assets/logo/brand_logo.png";

const CustomHeader = ({
  bg,
  color,
  heading,
  logo,
  path,
  click,
  fw,
  others,
  otherpath,
}) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="common-header" style={{ background: bg }}>
      {heading ? (
        <div
          className="header-heading"
          style={{ color: color, fontWeight: fw }}
        >
          {heading}
        </div>
      ) : (
        ""
      )}
      {logo ? (
        <div className="game-header-heading">
          <img src={brandLogo} alt="" />
        </div>
      ) : (
        ""
      )}
      {path ? (
        <div className="header-back" onClick={goBack}>
          {/* <Link to={path}> */}
          <div className="link-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18px"
              height="18px"
              viewBox="0 0 24 24"
            >
              <path
                fill={color}
                d="m3.55 12l7.35 7.35q.375.375.363.875t-.388.875t-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675T.825 12t.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388t.375.875t-.375.875z"
              />
            </svg>
          </div>
          {/* </Link> */}
        </div>
      ) : (
        ""
      )}
      {click ? (
        <div className="header-back">
          <div className="link-container" onClick={click}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
            >
              <path
                fill={color}
                d="m3.55 12l7.35 7.35q.375.375.363.875t-.388.875t-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675T.825 12t.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388t.375.875t-.375.875z"
              />
            </svg>
          </div>
        </div>
      ) : (
        ""
      )}
      {others ? (
        <div className="others">
          <Link to={otherpath}>
            <span dangerouslySetInnerHTML={{ __html: others }}></span>
          </Link>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default CustomHeader;
