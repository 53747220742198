import { io } from "socket.io-client";
// http://localhost:4066
// Change to your server URL if different
const URL = "https://frontend.chennaiclub.org"; // Change to your server URL if different
const socket = io(URL);
// Live stream
export const webhost = URL
export default socket;


