import React, { useState } from "react";
import CustomHeader from "../../common/CustomHeader";
import DatePicker from "../DatePicker";

const CommissionDetails = () => {
  const now = new Date();
  const currentdate = `${now.getFullYear()}-${
    now.getMonth() + 1
  }-${now.getDate()}`;
  const [selectedDate, setSelectedDate] = useState(currentdate);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const handleOpenDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleReturnDate = (date) => {
    setSelectedDate((val) => {
      return date;
    });
  };
  return (
    <>
      <CustomHeader
        path={"/promotion"}
        bg={"var(--themeBlack)"}
        color={"var(--mainText)"}
        heading={"Commission details"}
      />
      <div className="select-container">
        <div className="select" onClick={handleOpenDatePicker}>
          <span>{selectedDate}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m7 10l5 5l5-5"
            />
          </svg>
        </div>
      </div>
      <DatePicker openDate={showDatePicker} returnDate={handleReturnDate} />
    </>
  );
};

export default CommissionDetails;
