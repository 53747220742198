import React from "react";
import { Link } from "react-router-dom";
import minigame1 from "../../../assets/img/mini_game.jpeg";

const MiniGame = ({ viewall, callGame }) => (
  <div className="d-flex flex-col align-center">
    <div className="minigame-box" style={!viewall ? cstyle : {}}>
      <img
        src={minigame1}
        alt=""
        style={!viewall ? imgstyle : {}}
        onClick={callGame}
      />
      <img
        src={minigame1}
        alt=""
        style={!viewall ? imgstyle : {}}
        onClick={callGame}
      />
      <img
        src={minigame1}
        alt=""
        style={!viewall ? imgstyle : {}}
        onClick={callGame}
      />
      <img
        src={minigame1}
        alt=""
        style={!viewall ? imgstyle : {}}
        onClick={callGame}
      />
      <img
        src={minigame1}
        alt=""
        style={!viewall ? imgstyle : {}}
        onClick={callGame}
      />
    </div>
    {viewall ? (
      <Link to="/home/AllGames">
        <div className="viewallbtn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#6485ff"
              d="M10.995 4.68v3.88A2.44 2.44 0 0 1 8.545 11h-3.86a2.38 2.38 0 0 1-1.72-.72a2.41 2.41 0 0 1-.71-1.72V4.69a2.44 2.44 0 0 1 2.43-2.44h3.87a2.42 2.42 0 0 1 1.72.72a2.39 2.39 0 0 1 .72 1.71m10.75.01v3.87a2.46 2.46 0 0 1-2.43 2.44h-3.88a2.5 2.5 0 0 1-1.73-.71a2.44 2.44 0 0 1-.71-1.73V4.69a2.39 2.39 0 0 1 .72-1.72a2.42 2.42 0 0 1 1.72-.72h3.87a2.46 2.46 0 0 1 2.44 2.44m0 10.75v3.87a2.46 2.46 0 0 1-2.43 2.44h-3.88a2.5 2.5 0 0 1-1.75-.69a2.42 2.42 0 0 1-.71-1.73v-3.87a2.391 2.391 0 0 1 .72-1.72a2.421 2.421 0 0 1 1.72-.72h3.87a2.46 2.46 0 0 1 2.44 2.44zm-10.75.01v3.87a2.46 2.46 0 0 1-2.45 2.43h-3.86a2.42 2.42 0 0 1-2.43-2.43v-3.87A2.46 2.46 0 0 1 4.685 13h3.87a2.49 2.49 0 0 1 1.73.72a2.45 2.45 0 0 1 .71 1.73"
            />
          </svg>

          <span>View All</span>
        </div>
      </Link>
    ) : (
      ""
    )}
  </div>
);

export default MiniGame;

const imgstyle = {
  height: "105px",
};
const cstyle = {
  gridTemplateColumns: "repeat(3, 1fr)",
};
