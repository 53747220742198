import React from "react";
import Comming from "../pages/Comming";
import CommonHeader from "../common/CommonHeader";

const Xgame = () => {
  return (
    <>
      <CommonHeader
        path="/"
        heading="Comming Soon"
        svg={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
          >
            <path
              fill="var(--lightText)"
              d="m3.55 12l7.35 7.35q.375.375.363.875t-.388.875t-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675T.825 12t.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388t.375.875t-.375.875z"
            />
          </svg>
        }
      />
      <Comming />
    </>
  );
};

export default Xgame;
