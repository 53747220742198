import React, { useState ,useEffect} from 'react'
import { Checkout, Deposit } from '../../../utils/PaymentController';
import { formatMoney } from '../../../utils/Utils';
import Toast from '../Toast';
import { useNavigate } from 'react-router-dom';

const PaymentPage = () => {
const [upi,setUpi]=useState("")
const [money,setMoney]=useState("")
const [utr,setUtr]=useState("")
const [orderId,setOrderId]=useState("")
const navigate=useNavigate()

useEffect(() => {
 const getCheck=async()=>{
    const response=await Checkout()
    setUpi(response.infoBank[0].stk)
    setMoney(response.datas.money)
    setOrderId(response.datas.id_order)
 }
 getCheck()
}, []);


const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });
  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 3000); // Hide after 3 seconds
  };
  const copyToClipCode = () => {
    navigator.clipboard.writeText(upi).then(() => {
      showToast(
        `<svg width="25px" height="25px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#fff" stroke="#fff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M56 2L18.8 42.909L8 34.729H2L18.8 62L62 2z" fill="#fff"></path></g></svg>Copy successfully`
      );

    }).catch(err => {
        console.error('Failed to copy the text: ', err);
    });
}


const handleSubmit = async () => {
    const type = "submit";

  
      const formData = new FormData();
      formData.append("utr", utr);
      formData.append("typeid", orderId);
      formData.append("type", type);

      if (utr.length > 10) {
       const response=await Deposit(formData)
       if(response){
        showToast(
            `<svg width="25px" height="25px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#fff" stroke="#fff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M56 2L18.8 42.909L8 34.729H2L18.8 62L62 2z" fill="#fff"></path></g></svg>${response.message}`
          );
          if(response.status){
            navigate("/wallet/RechargeHistory")
          }
       }
      }else{
        alert("Please enter 12 digit utr number")
      }
      }



    return (
        <>

<Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
            <div>
                <div className="card-p">
                    <h2>Use Mobile Scan code to pay</h2>
                    <p>Or take a screenshot and save then open payment app to scan</p>
                    <h3 className=''>{formatMoney(money)}</h3>
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/800px-QR_code_for_mobile_English_Wikipedia.svg.png" alt="QR Code" />
                    <p className="warning">Do not use the same QR code to pay multiple times</p>
                    <button>Save QR</button>
                </div>
                <div className="container-p">
                    <div className="input-section">
                        <h5>Copy Beneficiary UPI.</h5>
                        <div className="upi-input">
                            <input type="text" id="upi" defaultValue={upi} readOnly />
                            <button className="copy-btn" onClick={copyToClipCode}>Copy</button>
                        </div>
                        <p className="note">Note: DO NOT save the UPI since it will be updated sometimes. Refresh and copy the new UPI before you pay.</p>
                    </div>
                    <div className="input-section">
                        <h5>Input the UTR to continue.</h5>
                        <div className="utr-input">
                            <input type="text" placeholder="Please enter the utr./Re" onClick={(e)=>setUtr(e.target.value)}/>
                            <button className="copy-btn" onClick={handleSubmit}>submit</button>
                        </div>
                        <p className="note">Note: Input the 12-digit UTR no. of this transaction.</p>
                    </div>
                </div>
            </div>


        </>
    )
}

export default PaymentPage
