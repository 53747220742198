import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import brandLogo from "../../assets/logo/brand_logo.png";

const GameHeader = ({ path, heading, svg }) => {
   // Initialize state from localStorage
  const [isOn, setIsOn] = useState(() => {
    const soundEnabled = localStorage.getItem("soundEnabled");
    return soundEnabled ? soundEnabled === "true" : true;
  });

  const handleSoundToggle = () => {
    const newSoundEnabled = !isOn;
    localStorage.setItem("soundEnabled", newSoundEnabled ? "true" : "false");
    setIsOn(newSoundEnabled);
  };

  useEffect(() => {
    // Synchronize the state with localStorage when the component mounts
    const soundEnabled = localStorage.getItem("soundEnabled");
    setIsOn(soundEnabled ? soundEnabled === "true" : true);
  }, []);

  return (
    <div className="game-header">
      <div className="game-header-heading">
        <img src={brandLogo} alt="" />
      </div>

      <div className="game-header-back">
        <Link to={path}>
          <div className="link-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#fff"
                d="m3.55 12l7.35 7.35q.375.375.363.875t-.388.875t-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675T.825 12t.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388t.375.875t-.375.875z"
              />
            </svg>
          </div>
        </Link>
      </div>
      <div
        className="d-flex align-center"
        style={{ position: "absolute", right: "5%", gap: "5px" }}
      >
        <Link to={"/promotion/CustomerSupport"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30px"
            height="30px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#fff"
              d="M11.77 20v-1h6.615q.25 0 .432-.154q.183-.154.183-.404v-6.888q0-2.81-2.066-4.693Q14.867 4.977 12 4.977T7.066 6.861Q5 8.744 5 11.554v5.677h-.5q-.613 0-1.057-.415Q3 16.402 3 15.79v-1.885q0-.448.291-.795q.292-.348.709-.565l.017-1.229q.046-1.565.709-2.9T6.47 6.092t2.514-1.54T12 4t3.014.552q1.428.552 2.513 1.538q1.085.985 1.744 2.32q.66 1.334.712 2.9L20 12.513q.398.187.699.503q.301.315.301.757v2.166q0 .442-.301.757t-.699.502v1.244q0 .652-.472 1.105T18.385 20zm-2.385-6.461q-.31 0-.54-.211q-.23-.21-.23-.52t.23-.53t.54-.22t.539.22t.23.53t-.23.52t-.54.21m5.232 0q-.31 0-.54-.21t-.23-.52t.23-.53t.54-.22t.539.22t.23.53t-.23.52t-.54.21M6.718 11.95q-.136-2.246 1.447-3.829q1.582-1.583 3.886-1.583q1.936 0 3.432 1.163t1.826 3.055q-1.987-.025-3.688-1.014t-2.61-2.75q-.362 1.731-1.505 3.034q-1.144 1.303-2.788 1.924"
            ></path>
          </svg>
        </Link>
        {isOn ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23px"
            height="23px"
            viewBox="0 0 26 26"
            className="pointer"
            onClick={handleSoundToggle}
          >
            <g fill="#fff">
              <path
                fillRule="evenodd"
                d="M13.5 26C20.404 26 26 20.404 26 13.5S20.404 1 13.5 1S1 6.596 1 13.5S6.596 26 13.5 26m0-2C19.299 24 24 19.299 24 13.5S19.299 3 13.5 3S3 7.701 3 13.5S7.701 24 13.5 24"
                clipRule="evenodd"
                opacity={0.2}
              ></path>
              <path
                fillRule="evenodd"
                d="M14.531 5.336C18.854 8.274 21 10.401 21 11.943c0 1.492-.82 2.7-2.396 3.583c-.436.245-.922-.232-.685-.672c.407-.759.273-1.607-.461-2.617a5.9 5.9 0 0 0-1.708-1.57v7.972l-.001.05l.001.075c0 1.482-1.48 2.625-3.25 2.625s-3.25-1.143-3.25-2.625s1.48-2.625 3.25-2.625c.44 0 .864.07 1.25.2V5.75a.5.5 0 0 1 .781-.414"
                clipRule="evenodd"
                opacity={0.2}
              ></path>
              <path
                fillRule="evenodd"
                d="M19.75 10.804c0-1.542-2.146-3.669-6.469-6.606a.5.5 0 0 0-.781.413v3.684a.5.5 0 0 0 .343.475c1.474.487 2.591 1.263 3.365 2.328c.734 1.01.868 1.858.46 2.617c-.236.44.25.917.686.672c1.575-.884 2.396-2.09 2.396-3.583M13.5 7.941V5.564c3.492 2.45 5.25 4.259 5.25 5.24c0 .746-.278 1.396-.855 1.96c-.02-.724-.316-1.48-.878-2.254C16.177 9.355 15 8.498 13.5 7.94"
                clipRule="evenodd"
              ></path>
              <path d="M12.5 7.5a.5.5 0 0 1 1 0V18a.5.5 0 0 1-1 0z"></path>
              <path
                fillRule="evenodd"
                d="M7.5 18c0 1.408 1.365 2.5 3 2.5s3-1.092 3-2.5s-1.365-2.5-3-2.5s-3 1.092-3 2.5m5 0c0 .8-.874 1.5-2 1.5s-2-.7-2-1.5s.874-1.5 2-1.5s2 .7 2 1.5"
                clipRule="evenodd"
              ></path>
              <path
                fillRule="evenodd"
                d="M13 24.5c6.351 0 11.5-5.149 11.5-11.5S19.351 1.5 13 1.5S1.5 6.649 1.5 13S6.649 24.5 13 24.5m0 1c6.904 0 12.5-5.596 12.5-12.5S19.904.5 13 .5S.5 6.096.5 13S6.096 25.5 13 25.5"
                clipRule="evenodd"
              ></path>
            </g>
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23px"
            height="23px"
            viewBox="0 0 26 26"
            className="pointer"
            onClick={handleSoundToggle}
          >
            <g fill="#fff">
              <path
                fillRule="evenodd"
                d="M13.5 26C20.404 26 26 20.404 26 13.5S20.404 1 13.5 1S1 6.596 1 13.5S6.596 26 13.5 26m0-2C19.299 24 24 19.299 24 13.5S19.299 3 13.5 3S3 7.701 3 13.5S7.701 24 13.5 24"
                clipRule="evenodd"
                opacity={0.2}
              ></path>
              <path
                fillRule="evenodd"
                d="M14.531 5.336C18.854 8.274 21 10.401 21 11.943c0 1.492-.82 2.7-2.396 3.583c-.436.245-.922-.232-.685-.672c.407-.759.273-1.607-.461-2.617a5.9 5.9 0 0 0-1.708-1.57v7.972l-.001.05l.001.075c0 1.482-1.48 2.625-3.25 2.625s-3.25-1.143-3.25-2.625s1.48-2.625 3.25-2.625c.44 0 .864.07 1.25.2V5.75a.5.5 0 0 1 .781-.414"
                clipRule="evenodd"
                opacity={0.2}
              ></path>
              <path
                fillRule="evenodd"
                d="M19.75 10.804c0-1.542-2.146-3.669-6.469-6.606a.5.5 0 0 0-.781.413v3.684a.5.5 0 0 0 .343.475c1.474.487 2.591 1.263 3.365 2.328c.734 1.01.868 1.858.46 2.617c-.236.44.25.917.686.672c1.575-.884 2.396-2.09 2.396-3.583M13.5 7.941V5.564c3.492 2.45 5.25 4.259 5.25 5.24c0 .746-.278 1.396-.855 1.96c-.02-.724-.316-1.48-.878-2.254C16.177 9.355 15 8.498 13.5 7.94"
                clipRule="evenodd"
              ></path>
              <path d="M12.5 7.5a.5.5 0 0 1 1 0V18a.5.5 0 0 1-1 0z"></path>
              <path
                fillRule="evenodd"
                d="M7.5 18c0 1.408 1.365 2.5 3 2.5s3-1.092 3-2.5s-1.365-2.5-3-2.5s-3 1.092-3 2.5m5 0c0 .8-.874 1.5-2 1.5s-2-.7-2-1.5s.874-1.5 2-1.5s2 .7 2 1.5"
                clipRule="evenodd"
              ></path>
              <path d="M4.15 4.878a.514.514 0 0 1 .728-.727l16.971 16.971a.514.514 0 0 1-.727.727z"></path>
              <path
                fillRule="evenodd"
                d="M13 24.5c6.351 0 11.5-5.149 11.5-11.5S19.351 1.5 13 1.5S1.5 6.649 1.5 13S6.649 24.5 13 24.5m0 1c6.904 0 12.5-5.596 12.5-12.5S19.904.5 13 .5S.5 6.096.5 13S6.096 25.5 13 25.5"
                clipRule="evenodd"
              ></path>
            </g>
          </svg>
        )}
      </div>
    </div>
  );
};

export default GameHeader;
