import React, { useEffect, useState } from "react";
import CustomHeader from "../../common/CustomHeader";
import {
  balanceIcon,
  inputClearIcon,
  leftArrow,
  noData,
  paytmIcon,
  refreshIcon,
  upiIcon,
} from "../../../assets/media";
import { useNavigate } from "react-router-dom";
import { getDepositList, getUser } from "../../../utils/UserController";
import {
  formatAlfaNumber,
  formatDate,
  formatMoney,
} from "../../../utils/Utils";
import GradientButton from "../GradientButton";
import Toast from "../Toast";
import { Deposit } from "../../../utils/PaymentController";

const Recharge = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [activeOption, setActiveOption] = useState("UPI");
  const [activeChannel, setActiveChannel] = useState("ceco");
  const [activeAmount, setActiveAmount] = useState("");
  const [depositHistory, setDepositHistory] = useState([]);

  const [refresh, setRefresh] = useState(false);
  const moneyRefresh = async () => {
    const userData = await getUser();
      if (userData.status === 200) {
        if (userData.data.status === "expired") {
          navigate("/login");
        }
        setUser(userData.data.data);
        
        showToast(
          `<svg width="25px" height="25px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#fff" stroke="#fff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M56 2L18.8 42.909L8 34.729H2L18.8 62L62 2z" fill="#fff"></path></g></svg>Refresh successfully`
        );
      } else {
        navigate("/login");
      }
  };

  const rechargeOptions = [
    {
      title: "UPI",
      icon: paytmIcon,
    },
    {
      title: "UPI-transfer",
      icon: upiIcon,
    },
    {
      title: "UPI-PAY",
      icon: paytmIcon,
    },
    {
      title: "PAYTM",
      icon: upiIcon,
    },
  ];

  const channels = [
    {
      option: "UPI",
      title: "ceco",
      balance: "300 - 10K",
    },
    {
      option: "UPI",
      title: "ICE",
      balance: "500 - 50K",
    },
    {
      option: "UPI-transfer",
      title: "Happy",
      balance: "500 - 50K",
    },
    {
      option: "UPI-transfer",
      title: "Royel",
      balance: "1k - 100K",
    },
    {
      option: "UPI-PAY",
      title: "XU",
      balance: "500 - 50K",
    },
    {
      option: "PAYTM",
      title: "AP",
      balance: "500 - 50K",
    },
  ];
  useEffect(() => {
    const getUserData = async () => {
      const userData = await getUser();
      if (userData.status === 200) {
        if (userData.data.status === "expired") {
          navigate("/login");
        }
        setUser(userData.data.data);
      } else {
        navigate("/login");
      }
    };
    getUserData();
  }, []);

  const amounts = [
    {
      channel: "ceco",
      amount: [300, 500, 1000, 1500, 2000, 3000, 4000, 5000, 7000, 9000, 10000],
    },
    {
      channel: "ICE",
      amount: [500, 1000, 2000, 4000, 8000, 16000, 20000, 30000, 40000, 50000],
    },
    {
      channel: "Happy",
      amount: [
        500, 1000, 3000, 5000, 7000, 15000, 25000, 35000, 40000, 45000, 50000,
      ],
    },
    {
      channel: "Royel",
      amount: [
        1000, 5000, 10000, 15000, 20000, 30000, 40000, 50000, 60000, 70000,
        80000, 90000, 100000,
      ],
    },
    {
      channel: "XU",
      amount: [500, 1000, 2000, 4000, 8000, 16000, 20000, 30000, 40000, 50000],
    },
    {
      channel: "AP",
      amount: [500, 1000, 2000, 4000, 8000, 16000, 20000, 30000, 40000, 50000],
    },
  ];

  const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });

  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 3000); // Hide after 3 seconds
  };

  const handleOptionChange = (option) => {
    setActiveAmount("");
    setActiveOption(option);
    const filteredChannels = channels.filter(
      (channel) => channel.option === option
    );
    if (filteredChannels.length > 0) {
      setActiveChannel(filteredChannels[0].title);
    } else {
      setActiveChannel("");
    }
  };
  const handleChannelChange = (channel) => {
    setActiveChannel(channel);
    setActiveAmount("");
  };

  const handleInputChange = (event) => {
    setActiveAmount(event.target.value);
  };

  const handleRecharge = async () => {
    if (isNaN(activeAmount)) {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Enter a valid amount`
      );
      return;
    }

    if (!activeAmount > 0) {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Enter an amount`
      );
      return;
    }
    const type = "UPI"
    const formData = new FormData()
    formData.append("amount", activeAmount)
    formData.append("type", type)
    const response=await Deposit(formData)


    // window.location.href = `https://in3win.site/api/user/checkout?uid=${user?.uid}&amount=${activeAmount}`;
    window.location.href = `/wallet/Recharge/pay`;
  };

  useEffect(() => {
    const getUserData = async () => {
      const userData = await getUser();
      if (userData.status === 200) {
        if (userData.data.status === "expired") {
          navigate("/login");
        }
        setUser(userData.data.data);
      } else {
        navigate("/login");
      }
    };
    const getDepositListData = async () => {
      const date = new Date();
      try {
        const res = await getDepositList();

        setDepositHistory(res.data.datas);
      } catch (error) {
        console.log(error);
      }
    };

    getUserData();
    getDepositListData();
  }, []);

  return (
    <>
      <CustomHeader
        path={"/wallet"}
        bg={"var(--themeBlack)"}
        color={"var(--mainText)"}
        heading={"Deposit"}
        others={`Deposit history`}
        otherpath={"/wallet/RechargeHistory"}
      />
      <div className="deposit-container">
        <div className="d-flex align-center">
          <img className="b-icon" src={balanceIcon} alt="" />
          <span className="txt">Balance</span>
        </div>
        <div className="money-container">
          <span className="user-money">{formatMoney(user?.money_user)}</span>
          <img
            className="refresh-icon"
            src={refreshIcon}
            alt=""
                    onClick={()=> moneyRefresh()}
          />
        </div>
      </div>
      <div className="payment-option-container deposit-wala">
        {rechargeOptions.map((option, i) => (
          <div
            key={i}
            className={`payment-option ${
              option.title === activeOption ? "active" : ""
            }`}
            onClick={() => handleOptionChange(option.title)}
          >
            <img src={option.icon} alt="" />
            <span>{option.title}</span>
          </div>
        ))}
      </div>
      <div className="chanel-container">
        <div className="chanel-header">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="none"
              stroke="#6485ff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="m3.346 16.198l12.829-12.83m2.457 7.688l-2.2 2.2m-1.877 1.854l-.979.979m-10.401.052a4.01 4.01 0 0 1 0-5.672l7.294-7.294a4.01 4.01 0 0 1 5.672 0l4.684 4.684a4.01 4.01 0 0 1 0 5.672l-7.294 7.294a4.01 4.01 0 0 1-5.672 0zM4 22h16"
              color="#23367d"
            />
          </svg>
          <span>Select channel</span>
        </div>
        <div className="chanels">
          {channels
            .filter((channel) => channel.option === activeOption)
            .map((channel, i) => (
              <div
                className={`chanel ${
                  activeChannel === channel.title ? "active" : ""
                }`}
                key={i}
                onClick={() => handleChannelChange(channel.title)}
              >
                <span>
                  {activeOption} - {channel.title}
                </span>
                <span>Balance - {channel.balance}</span>
              </div>
            ))}
        </div>
      </div>
      <div className="amount-container">
        <div className="amount-header">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="none"
              stroke="#6485ff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="m3.346 16.198l12.829-12.83m2.457 7.688l-2.2 2.2m-1.877 1.854l-.979.979m-10.401.052a4.01 4.01 0 0 1 0-5.672l7.294-7.294a4.01 4.01 0 0 1 5.672 0l4.684 4.684a4.01 4.01 0 0 1 0 5.672l-7.294 7.294a4.01 4.01 0 0 1-5.672 0zM4 22h16"
              color="#23367d"
            />
          </svg>
          <span>Deposit amount</span>
        </div>
        <div className="amounts">
          {amounts
            .filter((amount) => amount.channel === activeChannel)
            .map((amount, i) => (
              <>
                {amount.amount.map((val, i) => (
                  <div
                    className={`amount ${val === activeAmount ? "active" : ""}`}
                    key={i}
                    onClick={() => setActiveAmount(val)}
                  >
                    <span className="cs">₹</span>
                    <span>{formatAlfaNumber(val)}</span>{" "}
                  </div>
                ))}
              </>
            ))}
        </div>
        <div className="deposite-input-container">
          <div className="symbol">₹</div>
          <input
            type="text"
            value={activeAmount}
            placeholder="Please enter the amount"
            onChange={handleInputChange}
          />
          <img
            src={inputClearIcon}
            alt=""
            onClick={() => setActiveAmount("")}
          />
        </div>
        <div
          className={`recharge-btn ${
            activeAmount > 299.9999999999 ? "active" : ""
          }`}
          onClick={() => {
            if (activeAmount > 299.9999999999) {
              handleRecharge();
            }
          }}
        >
          Deposit
        </div>
      </div>
      <div className="deposit-history-header">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25px"
          height="25px"
          viewBox="0 0 24 24"
        >
          <path
            fill="none"
            stroke="#6485ff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="m3.346 16.198l12.829-12.83m2.457 7.688l-2.2 2.2m-1.877 1.854l-.979.979m-10.401.052a4.01 4.01 0 0 1 0-5.672l7.294-7.294a4.01 4.01 0 0 1 5.672 0l4.684 4.684a4.01 4.01 0 0 1 0 5.672l-7.294 7.294a4.01 4.01 0 0 1-5.672 0zM4 22h16"
            color="#23367d"
          />
        </svg>
        <span>Deposit history</span>
      </div>
      {depositHistory?.length > 0 ? (
        <>
          <div className="deposit-history">
            {depositHistory.slice(0, 5).map((v, i) => (
                <div className="deposit-history-item" key={i}>
                <div className="history-item-header">
                  <div className="dtb" style={{background: "#00ae50"}}>Deposit</div>
                  <div className="d-flex align-center left-arrow">
                    <span
                      style={{
                        color:
                          v.status === 1
                            ? "#00ae50"
                            : v.status ===2
                            ? "#f95959"
                            : "#ff9832",
                      }}
                    >
                      {v.status === 1
                        ? "Complete"
                        : v.status === 2
                        ? "Rejected"
                        : "To be paid"}
                    </span>
                    <img src={leftArrow} alt={""} />
                  </div>
                </div>
                <div className="history-item-content">
                  <div className="d-flex justify-between align-center">
                    <span>Balance</span>
                    <span className="balance">
                      {formatMoney(v.money)}
                    </span>
                  </div>
                  <div className="d-flex justify-between align-center">
                    <span>Type</span>
                    <span>{v.type}</span>
                  </div>
                  <div className="d-flex justify-between align-center">
                    <span>Time</span>
                    <span>{ v.today}</span>
                  </div>
                  <div className="d-flex justify-between align-center">
                    <span>Order number</span>
                    <div className="d-flex">
                      <span>{v.id_order}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15px"
                        height="15px"
                        viewBox="0 0 256 256"
                        // onClick={()=>copyToClipCode(v.id_order)}
                      >
                        <path
                          fill="#768096"
                          d="M216 32H88a8 8 0 0 0-8 8v40H40a8 8 0 0 0-8 8v128a8 8 0 0 0 8 8h128a8 8 0 0 0 8-8v-40h40a8 8 0 0 0 8-8V40a8 8 0 0 0-8-8m-56 176H48V96h112Zm48-48h-32V88a8 8 0 0 0-8-8H96V48h112Z"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <div className="d-flex justify-between align-center">
                    <span>Remark</span>
                    <span>{v.remark}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="btn-container">
            <GradientButton
              text={"All history"}
              bg={"var(--main_gradient-color)"}
              color={"#fff"}
              path={"/wallet/RechargeHistory"}
            />
          </div>
        </>
      ) : (
        <div className="empty">
          <img src={noData} alt="" className="noData" />
          <span>No Data</span>
        </div>
      )}
      <div className="size-box-100"></div>
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
    </>
  );
};

export default Recharge;
