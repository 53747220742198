import React, { useEffect, useRef, useState } from "react";
import CustomHeader from "../../common/CustomHeader";
import { allTab, casino, lotteryLogo, slots, sports } from "../../../assets/media";
import "../../../assets/css/promotion.css";

const RebateRatio = () => {
  const [activeTab, setActiveTab] = useState(0);
  const tabsRef = useRef(null);
  const tabs = [
    {
      image: allTab,
      label: "All",
      component: <All />,
    },
    {
      image: lotteryLogo,
      label: "Lottery",
      component: <Lottery />,
    },
    {
      image: casino,
      label: "Casino",
      component: <Casino />,
    },
    {
      image: sports,
      label: "Rummy",
      component: <Rummy />,
    },
    {
      image: slots,
      label: "Slots",
      component: <Slots />,
    },
  ];

  useEffect(() => {
    if (tabsRef.current) {
      const activeTabElement = tabsRef.current.children[activeTab];
      const tabContainerWidth = tabsRef.current.offsetWidth;
      const tabElementWidth = activeTabElement.offsetWidth;
      const scrollPosition =
        activeTabElement.offsetLeft -
        (tabContainerWidth / 2 - tabElementWidth / 2);
      tabsRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [activeTab]);
  return (
    <>
      <CustomHeader
        path={"/promotion"}
        bg={"var(--themeBlack)"}
        color={"var(--mainText)"}
        heading={"Rebate Ratio"}
      />
      <div className="games-tabs-container" ref={tabsRef}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`games-tab ${index === activeTab ? "active" : ""}`}
            onClick={() => setActiveTab(index)}
            style={{ borderRadius: "8px" }}
          >
            <img src={tab.image} alt="" />
            {tab.label}
          </div>
        ))}
      </div>
      <div className="games-tab-content">{tabs[activeTab].component}</div>
      <div className="size-box-50"></div>
    </>
  );
};

export default RebateRatio;

const All = () => {
  const [isloading, setIsloading] = useState(true);

  const generateSubLevels = () => {
    return [
      {
        lebel: "1 level lower level commission rebate",
        percent: generateRandomPercent(0.6, 0.001),
      },
      {
        lebel: "2 level lower level commission rebate",
        percent: generateRandomPercent(0.18, 0.001),
      },
      {
        lebel: "3 level lower level commission rebate",
        percent: generateRandomPercent(0.054, 0.001),
      },
      {
        lebel: "4 level lower level commission rebate",
        percent: generateRandomPercent(0.016, 0.001),
      },
      {
        lebel: "5 level lower level commission rebate",
        percent: generateRandomPercent(0.0048, 0.0001),
      },
      {
        lebel: "6 level lower level commission rebate",
        percent: generateRandomPercent(0.0014, 0.0001),
      },
    ];
  };

  const generateRandomPercent = (base, variation) => {
    // Base percent +/- random variation
    const min = base - variation;
    const max = base + variation;
    const randomPercent = (Math.random() * (max - min) + min).toFixed(4) + "%";
    return randomPercent;
  };

  const levels = [
    {
      level: "L0",
      subLevel: generateSubLevels(),
    },
    {
      level: "L1",
      subLevel: generateSubLevels(),
    },
    {
      level: "L2",
      subLevel: generateSubLevels(),
    },
    {
      level: "L3",
      subLevel: generateSubLevels(),
    },
    {
      level: "L4",
      subLevel: generateSubLevels(),
    },
    {
      level: "L5",
      subLevel: generateSubLevels(),
    },
    {
      level: "L6",
      subLevel: generateSubLevels(),
    },
    {
      level: "L7",
      subLevel: generateSubLevels(),
    },
    {
      level: "L8",
      subLevel: generateSubLevels(),
    },
    {
      level: "L9",
      subLevel: generateSubLevels(),
    },
    {
      level: "L10",
      subLevel: generateSubLevels(),
    },
  ];

  setTimeout(() => {
    setIsloading(false);
  }, 1000);
  return (
    <>
      {isloading ? (
        <div className="server-loader"></div>
      ) : (
        <div className="rebate-tab-content">
          {levels.map((level, index) => (
            <div className="level-container" key={index}>
              <div className="level-heading">
                <div className="level-heading1">Rebate level</div>
                <div className="level-heading2">{level.level}</div>
              </div>
              {level.subLevel.map((sub_level, index) => (
                <div className="level-list" key={index}>
                  <div className="d-flex align-center">
                    <div
                      className={`ring ${
                        index === level.subLevel.length - 1 ? "" : "xring"
                      }`}
                    ></div>
                    <span className="list-txt">{sub_level.lebel}</span>
                  </div>
                  <span className="list-percent">{sub_level.percent}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const Lottery = () => {
  const [isloading, setIsloading] = useState(true);

  const generateSubLevels = () => {
    return [
      {
        lebel: "1 level lower level commission rebate",
        percent: generateRandomPercent(0.6, 0.001),
      },
      {
        lebel: "2 level lower level commission rebate",
        percent: generateRandomPercent(0.18, 0.001),
      },
      {
        lebel: "3 level lower level commission rebate",
        percent: generateRandomPercent(0.054, 0.001),
      },
      {
        lebel: "4 level lower level commission rebate",
        percent: generateRandomPercent(0.016, 0.001),
      },
      {
        lebel: "5 level lower level commission rebate",
        percent: generateRandomPercent(0.0048, 0.0001),
      },
      {
        lebel: "6 level lower level commission rebate",
        percent: generateRandomPercent(0.0014, 0.0001),
      },
    ];
  };

  const generateRandomPercent = (base, variation) => {
    // Base percent +/- random variation
    const min = base - variation;
    const max = base + variation;
    const randomPercent = (Math.random() * (max - min) + min).toFixed(4) + "%";
    return randomPercent;
  };

  const levels = [
    {
      level: "L0",
      subLevel: generateSubLevels(),
    },
    {
      level: "L1",
      subLevel: generateSubLevels(),
    },
    {
      level: "L2",
      subLevel: generateSubLevels(),
    },
    {
      level: "L3",
      subLevel: generateSubLevels(),
    },
    {
      level: "L4",
      subLevel: generateSubLevels(),
    },
    {
      level: "L5",
      subLevel: generateSubLevels(),
    },
    {
      level: "L6",
      subLevel: generateSubLevels(),
    },
    {
      level: "L7",
      subLevel: generateSubLevels(),
    },
    {
      level: "L8",
      subLevel: generateSubLevels(),
    },
    {
      level: "L9",
      subLevel: generateSubLevels(),
    },
    {
      level: "L10",
      subLevel: generateSubLevels(),
    },
  ];

  setTimeout(() => {
    setIsloading(false);
  }, 1000);
  return (
    <>
      {isloading ? (
        <div className="server-loader"></div>
      ) : (
        <div className="rebate-tab-content">
          {levels.map((level, index) => (
            <div className="level-container" key={index}>
              <div className="level-heading">
                <div className="level-heading1">Rebate level</div>
                <div className="level-heading2">{level.level}</div>
              </div>
              {level.subLevel.map((sub_level, index) => (
                <div className="level-list" key={index}>
                  <div className="d-flex align-center">
                    <div
                      className={`ring ${
                        index === level.subLevel.length - 1 ? "" : "xring"
                      }`}
                    ></div>
                    <span className="list-txt">{sub_level.lebel}</span>
                  </div>
                  <span className="list-percent">{sub_level.percent}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};
const Casino = () => {
  const [isloading, setIsloading] = useState(true);

  const generateSubLevels = () => {
    return [
      {
        lebel: "1 level lower level commission rebate",
        percent: generateRandomPercent(0.6, 0.001),
      },
      {
        lebel: "2 level lower level commission rebate",
        percent: generateRandomPercent(0.18, 0.001),
      },
      {
        lebel: "3 level lower level commission rebate",
        percent: generateRandomPercent(0.054, 0.001),
      },
      {
        lebel: "4 level lower level commission rebate",
        percent: generateRandomPercent(0.016, 0.001),
      },
      {
        lebel: "5 level lower level commission rebate",
        percent: generateRandomPercent(0.0048, 0.0001),
      },
      {
        lebel: "6 level lower level commission rebate",
        percent: generateRandomPercent(0.0014, 0.0001),
      },
    ];
  };

  const generateRandomPercent = (base, variation) => {
    // Base percent +/- random variation
    const min = base - variation;
    const max = base + variation;
    const randomPercent = (Math.random() * (max - min) + min).toFixed(4) + "%";
    return randomPercent;
  };

  const levels = [
    {
      level: "L0",
      subLevel: generateSubLevels(),
    },
    {
      level: "L1",
      subLevel: generateSubLevels(),
    },
    {
      level: "L2",
      subLevel: generateSubLevels(),
    },
    {
      level: "L3",
      subLevel: generateSubLevels(),
    },
    {
      level: "L4",
      subLevel: generateSubLevels(),
    },
    {
      level: "L5",
      subLevel: generateSubLevels(),
    },
    {
      level: "L6",
      subLevel: generateSubLevels(),
    },
    {
      level: "L7",
      subLevel: generateSubLevels(),
    },
    {
      level: "L8",
      subLevel: generateSubLevels(),
    },
    {
      level: "L9",
      subLevel: generateSubLevels(),
    },
    {
      level: "L10",
      subLevel: generateSubLevels(),
    },
  ];

  setTimeout(() => {
    setIsloading(false);
  }, 1000);
  return (
    <>
      {isloading ? (
        <div className="server-loader"></div>
      ) : (
        <div className="rebate-tab-content">
          {levels.map((level, index) => (
            <div className="level-container" key={index}>
              <div className="level-heading">
                <div className="level-heading1">Rebate level</div>
                <div className="level-heading2">{level.level}</div>
              </div>
              {level.subLevel.map((sub_level, index) => (
                <div className="level-list" key={index}>
                  <div className="d-flex align-center">
                    <div
                      className={`ring ${
                        index === level.subLevel.length - 1 ? "" : "xring"
                      }`}
                    ></div>
                    <span className="list-txt">{sub_level.lebel}</span>
                  </div>
                  <span className="list-percent">{sub_level.percent}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};
const Rummy = () => {
  const [isloading, setIsloading] = useState(true);

  const generateSubLevels = () => {
    return [
      {
        lebel: "1 level lower level commission rebate",
        percent: generateRandomPercent(0.6, 0.001),
      },
      {
        lebel: "2 level lower level commission rebate",
        percent: generateRandomPercent(0.18, 0.001),
      },
      {
        lebel: "3 level lower level commission rebate",
        percent: generateRandomPercent(0.054, 0.001),
      },
      {
        lebel: "4 level lower level commission rebate",
        percent: generateRandomPercent(0.016, 0.001),
      },
      {
        lebel: "5 level lower level commission rebate",
        percent: generateRandomPercent(0.0048, 0.0001),
      },
      {
        lebel: "6 level lower level commission rebate",
        percent: generateRandomPercent(0.0014, 0.0001),
      },
    ];
  };

  const generateRandomPercent = (base, variation) => {
    // Base percent +/- random variation
    const min = base - variation;
    const max = base + variation;
    const randomPercent = (Math.random() * (max - min) + min).toFixed(4) + "%";
    return randomPercent;
  };

  const levels = [
    {
      level: "L0",
      subLevel: generateSubLevels(),
    },
    {
      level: "L1",
      subLevel: generateSubLevels(),
    },
    {
      level: "L2",
      subLevel: generateSubLevels(),
    },
    {
      level: "L3",
      subLevel: generateSubLevels(),
    },
    {
      level: "L4",
      subLevel: generateSubLevels(),
    },
    {
      level: "L5",
      subLevel: generateSubLevels(),
    },
    {
      level: "L6",
      subLevel: generateSubLevels(),
    },
    {
      level: "L7",
      subLevel: generateSubLevels(),
    },
    {
      level: "L8",
      subLevel: generateSubLevels(),
    },
    {
      level: "L9",
      subLevel: generateSubLevels(),
    },
    {
      level: "L10",
      subLevel: generateSubLevels(),
    },
  ];

  setTimeout(() => {
    setIsloading(false);
  }, 1000);
  return (
    <>
      {isloading ? (
        <div className="server-loader"></div>
      ) : (
        <div className="rebate-tab-content">
          {levels.map((level, index) => (
            <div className="level-container" key={index}>
              <div className="level-heading">
                <div className="level-heading1">Rebate level</div>
                <div className="level-heading2">{level.level}</div>
              </div>
              {level.subLevel.map((sub_level, index) => (
                <div className="level-list" key={index}>
                  <div className="d-flex align-center">
                    <div
                      className={`ring ${
                        index === level.subLevel.length - 1 ? "" : "xring"
                      }`}
                    ></div>
                    <span className="list-txt">{sub_level.lebel}</span>
                  </div>
                  <span className="list-percent">{sub_level.percent}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};
const Slots = () => {
  const [isloading, setIsloading] = useState(true);

  const generateSubLevels = () => {
    return [
      {
        lebel: "1 level lower level commission rebate",
        percent: generateRandomPercent(0.6, 0.001),
      },
      {
        lebel: "2 level lower level commission rebate",
        percent: generateRandomPercent(0.18, 0.001),
      },
      {
        lebel: "3 level lower level commission rebate",
        percent: generateRandomPercent(0.054, 0.001),
      },
      {
        lebel: "4 level lower level commission rebate",
        percent: generateRandomPercent(0.016, 0.001),
      },
      {
        lebel: "5 level lower level commission rebate",
        percent: generateRandomPercent(0.0048, 0.0001),
      },
      {
        lebel: "6 level lower level commission rebate",
        percent: generateRandomPercent(0.0014, 0.0001),
      },
    ];
  };

  const generateRandomPercent = (base, variation) => {
    // Base percent +/- random variation
    const min = base - variation;
    const max = base + variation;
    const randomPercent = (Math.random() * (max - min) + min).toFixed(4) + "%";
    return randomPercent;
  };

  const levels = [
    {
      level: "L0",
      subLevel: generateSubLevels(),
    },
    {
      level: "L1",
      subLevel: generateSubLevels(),
    },
    {
      level: "L2",
      subLevel: generateSubLevels(),
    },
    {
      level: "L3",
      subLevel: generateSubLevels(),
    },
    {
      level: "L4",
      subLevel: generateSubLevels(),
    },
    {
      level: "L5",
      subLevel: generateSubLevels(),
    },
    {
      level: "L6",
      subLevel: generateSubLevels(),
    },
    {
      level: "L7",
      subLevel: generateSubLevels(),
    },
    {
      level: "L8",
      subLevel: generateSubLevels(),
    },
    {
      level: "L9",
      subLevel: generateSubLevels(),
    },
    {
      level: "L10",
      subLevel: generateSubLevels(),
    },
  ];

  setTimeout(() => {
    setIsloading(false);
  }, 1000);
  return (
    <>
      {isloading ? (
        <div className="server-loader"></div>
      ) : (
        <div className="rebate-tab-content">
          {levels.map((level, index) => (
            <div className="level-container" key={index}>
              <div className="level-heading">
                <div className="level-heading1">Rebate level</div>
                <div className="level-heading2">{level.level}</div>
              </div>
              {level.subLevel.map((sub_level, index) => (
                <div className="level-list" key={index}>
                  <div className="d-flex align-center">
                    <div
                      className={`ring ${
                        index === level.subLevel.length - 1 ? "" : "xring"
                      }`}
                    ></div>
                    <span className="list-txt">{sub_level.lebel}</span>
                  </div>
                  <span className="list-percent">{sub_level.percent}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};
