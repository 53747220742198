import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import "../../assets/css/main.css";
import {
  getYearsUpToNow,
  getDaysInMonthUpToNow,
  getMonthsUpToNow,
} from "../../utils/Utils";

const DatePicker = ({ openDate, returnDate }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [years, setYears] = useState(getYearsUpToNow(2022));
  const [months, setMonths] = useState(getMonthsUpToNow(2022));
  const [days, setDays] = useState(getDaysInMonthUpToNow(2022, 12));
  const [activeYear, setActiveYear] = useState(0);
  const [activeMonth, setActiveMonth] = useState(0);
  const [activeDay, setActiveDay] = useState(0);
  const [date, setDate] = useState("");

  const yearsRef = useRef(null);
  const monthsRef = useRef(null);
  const daysRef = useRef(null);

  useEffect(() => {
    const now = new Date();
    const currentYearIndex = years.indexOf(now.getFullYear());
    const currentMonthIndex = getMonthsUpToNow(now.getFullYear()).indexOf(
      (now.getMonth() + 1).toString().padStart(2, "0")
    );
    const currentDayIndex = getDaysInMonthUpToNow(
      now.getFullYear(),
      now.getMonth() + 1
    ).indexOf(now.getDate());
    if (currentYearIndex !== -1) {
      setActiveYear(currentYearIndex);
    }
    if (currentMonthIndex !== -1) {
      setActiveMonth(currentMonthIndex);
    }
    if (currentDayIndex !== -1) {
      setActiveDay(currentDayIndex);
    }

    scrollToActive(yearsRef, currentYearIndex);
    scrollToActive(monthsRef, currentMonthIndex);
    scrollToActive(daysRef, currentDayIndex);

    setMonths(getMonthsUpToNow(years[currentYearIndex]));
    setDays(
      getDaysInMonthUpToNow(years[currentYearIndex], months[currentMonthIndex])
    );
    makeDate(
      years[currentYearIndex],
      months[currentMonthIndex],
      days[currentDayIndex]
    );
  }, []);

  useEffect(() => {
    scrollToActive(yearsRef, activeYear);
    setMonths(getMonthsUpToNow(years[activeYear]));
    makeDate(years[activeYear], Number(months[activeMonth]), days[activeDay]);
  }, [activeYear]);

  useEffect(() => {
    scrollToActive(monthsRef, activeMonth);
    setDays(
      getDaysInMonthUpToNow(years[activeYear], Number(months[activeMonth]))
    );
    makeDate(years[activeYear], Number(months[activeMonth]), days[activeDay]);
  }, [activeMonth]);

  useEffect(() => {
    scrollToActive(daysRef, activeDay);
    makeDate(years[activeYear], Number(months[activeMonth]), days[activeDay]);
  }, [activeDay]);

  const scrollToActive = (ref, index) => {
    if (ref.current && ref.current.children[index]) {
      const activeElement = ref.current.children[index];
      const containerHeight = ref.current.offsetHeight;
      const elementHeight = activeElement.offsetHeight;
      const scrollPosition =
        activeElement.offsetTop - (containerHeight / 2 - elementHeight / 2);
      ref.current.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  };

  const makeDate = (yrs, month, day) => {
    const date = `${yrs}-${month}-${day}`;
    setDate(date);
  };

  const handleSelectedDate = () => {
    if (returnDate) {
      returnDate(date);
    }
    toggleOffCanvas();
  };

  const toggleOffCanvas = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      $(".overlay").removeClass("d-none");
      $(".container").css("overflow", "hidden");
    } else {
      $(".overlay").addClass("d-none");
      $(".container").css("overflow", "");
      $(".wingo-model-content1").prop("class", "wingo-model-content1");
    }
  };

  useEffect(() => {
    toggleOffCanvas();
  }, [openDate]);

  return (
    <>
      <div className={`off-canvas ${isOpen ? "open" : ""}`}>
        <div className="date-container">
          <div className="date-header">
            <span
              style={{ color: "#969799", fontSize: ".9rem" }}
              onClick={toggleOffCanvas}
            >
              Cancel
            </span>
            <span style={{ fontWeight: "700" }}>Choose a date</span>
            <span
              style={{ color: "#23367d", fontSize: ".9rem" }}
              onClick={handleSelectedDate}
            >
              Confirm
            </span>
          </div>
          <div className="date-content">
            <div className="years">
              <div className="year-boxes" ref={yearsRef}>
                {years.map((year, index) => (
                  <div
                    className={`year ${index === activeYear ? "active" : ""}`}
                    key={index}
                    onClick={() => setActiveYear(index)}
                  >
                    {year}
                  </div>
                ))}
              </div>
            </div>
            <div className="months">
              <div className="month-boxes" ref={monthsRef}>
                {months.map((month, index) => (
                  <div
                    className={`month ${index === activeMonth ? "active" : ""}`}
                    key={index}
                    onClick={() => setActiveMonth(index)}
                  >
                    {month}
                  </div>
                ))}
              </div>
            </div>
            <div className="days">
              <div className="day-boxes" ref={daysRef}>
                {days.map((day, index) => (
                  <div
                    className={`day ${index === activeDay ? "active" : ""}`}
                    key={index}
                    onClick={() => setActiveDay(index)}
                  >
                    {day}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DatePicker;
