import React from "react";

const Comming = () => {
  return (
    <div className="comming-soon-container">
      <h4>Commin Soon!</h4>
    </div>
  );
};

export default Comming;
