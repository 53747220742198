import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomHeader from "../../common/CustomHeader";
import "../../../assets/css/activity.css";
import { invitationBonusBanner } from "../../../assets/media";
import GradientButton from "../GradientButton";
import {
  getInvitationList,
  getInviteBonus,
} from "../../../utils/UserController";
import Toast from "../Toast";

const InvitationBonus = () => {
  const [people, setPeople] = useState(0);

  const invites = [
    {
      price: 55.0,
      invites: 1,
      rpp: 300.0,
    },
    {
      price: 155.0,
      invites: 3,
      rpp: 300.0,
    },
    {
      price: 555.0,
      invites: 10,
      rpp: 500.0,
    },
    {
      price: 1555.0,
      invites: 30,
      rpp: 800.0,
    },
    {
      price: 2775.0,
      invites: 50,
      rpp: 1200.0,
    },
    {
      price: 4165.0,
      invites: 75,
      rpp: 1200.0,
    },
    {
      price: 5555.0,
      invites: 100,
      rpp: 1200.0,
    },
    {
      price: 11111.0,
      invites: 200,
      rpp: 1200.0,
    },
    {
      price: 27777.0,
      invites: 500,
      rpp: 1200.0,
    },
    {
      price: 55555.0,
      invites: 1000,
      rpp: 1200.0,
    },
    {
      price: 111111.0,
      invites: 2000,
      rpp: 1200.0,
    },
    {
      price: 277777.0,
      invites: 5000,
      rpp: 1200.0,
    },
  ];
  const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });
  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 3000); // Hide after 3 seconds
  };

  const handleInvitationBonus = async (people, amt) => {
    try {
      const res = await getInviteBonus(people, amt);
      showToast(res.data.message);
    } catch (error) {}
  };

  useEffect(() => {
    const getInvitationListData = async () => {
      try {
        const res = await getInvitationList();

        setPeople(res.data.validPeople);
      } catch (error) {}
    };
    getInvitationListData();
  }, []);

  return (
    <>
      <CustomHeader
        bg={"var(--themeBlack)"}
        color={"var(--mainText)"}
        heading={"Invitation bonus"}
        path={"/activity"}
      />
      <div
        className="hero-header"
        style={{
          backgroundImage: `url(${invitationBonusBanner}), linear-gradient(94deg, #f99937 2.72%, #ff6922 43.54%, #ff8039 98.54%)`,
        }}
      >
        <div className="hero-div1">
          <div className="hero-div1-heading">Invite friends and deposit</div>
          <span className="hero-div1-text">
            Both parties can receive rewards
          </span>
          <span className="hero-div1-text">
            Invite friends to register and recharge to receive rewards
          </span>
          <span className="hero-div1-text">activity date</span>
        </div>
        <div className="hero-div2">
          <div className="activity-tabs" style={{ width: "100%" }}>
            <div className="activity-tab" style={{ width: "50%", gap: "0" }}>
              <Link to={"/activity/InvitationRules"}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 112 112"
                  fill="none"
                >
                  <g filter="url(#filter0_di_4398_2020)">
                    <path
                      d="M16 28C16 16.9543 24.9543 8 36 8H76C87.0457 8 96 16.9543 96 28V68C96 79.0457 87.0457 88 76 88H36C24.9543 88 16 79.0457 16 68V28Z"
                      fill="url(#paint0_linear_4398_2020)"
                    />
                  </g>
                  <g filter="url(#filter1_d_4398_2020)">
                    <path
                      d="M69.75 30.5V23H36C35.3096 23 34.75 23.5596 34.75 24.25V65.5H42.25"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      shapeRendering="crispEdges"
                    />
                  </g>
                  <g filter="url(#filter2_d_4398_2020)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M73.25 30.5H46.25C44.0409 30.5 42.25 32.2909 42.25 34.5V69C42.25 71.2091 44.0409 73 46.25 73H73.25C75.4591 73 77.25 71.2091 77.25 69V34.5C77.25 32.2909 75.4591 30.5 73.25 30.5ZM51.25 45.5C51.25 44.9477 51.6977 44.5 52.25 44.5H67.25C67.8023 44.5 68.25 44.9477 68.25 45.5C68.25 46.0523 67.8023 46.5 67.25 46.5H52.25C51.6977 46.5 51.25 46.0523 51.25 45.5ZM52.25 54.5C51.6977 54.5 51.25 54.9477 51.25 55.5C51.25 56.0523 51.6977 56.5 52.25 56.5H67.25C67.8023 56.5 68.25 56.0523 68.25 55.5C68.25 54.9477 67.8023 54.5 67.25 54.5H52.25Z"
                      fill="#D0F7FF"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_di_4398_2020"
                      x="0"
                      y="0"
                      width="112"
                      height="112"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="8" />
                      <feGaussianBlur stdDeviation="8" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.815686 0 0 0 0 0.815686 0 0 0 0 0.929412 0 0 0 0.36 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_4398_2020"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_4398_2020"
                        result="shape"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="-4" />
                      <feGaussianBlur stdDeviation="6" />
                      <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                      />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect2_innerShadow_4398_2020"
                      />
                    </filter>
                    <filter
                      id="filter1_d_4398_2020"
                      x="31.75"
                      y="21"
                      width="41"
                      height="48.5"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="1" />
                      <feGaussianBlur stdDeviation="1" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.396078 0 0 0 0 0.67451 0 0 0 0 1 0 0 0 0.5 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_4398_2020"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_4398_2020"
                        result="shape"
                      />
                    </filter>
                    <filter
                      id="filter2_d_4398_2020"
                      x="40.25"
                      y="29.5"
                      width="39"
                      height="46.5"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="1" />
                      <feGaussianBlur stdDeviation="1" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.396078 0 0 0 0 0.67451 0 0 0 0 1 0 0 0 0.5 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_4398_2020"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_4398_2020"
                        result="shape"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_4398_2020"
                      x1="56"
                      y1="8"
                      x2="56"
                      y2="88"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#5CA6FF" />
                      <stop offset="1" stopColor="#A9E5FF" />
                    </linearGradient>
                  </defs>
                </svg>
              </Link>
              <span>Invitation reward rules</span>
            </div>
            <div className="activity-tab" style={{ width: "50%", gap: "0" }}>
              <Link to={"/activity/InvitationRecord"}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  viewBox="0 0 112 112"
                  fill="none"
                >
                  <g filter="url(#filter0_di_4407_4378)">
                    <path
                      d="M16 28C16 16.9543 24.9543 8 36 8H76C87.0457 8 96 16.9543 96 28V68C96 79.0457 87.0457 88 76 88H36C24.9543 88 16 79.0457 16 68V28Z"
                      fill="url(#paint0_linear_4407_4378)"
                    />
                  </g>
                  <g filter="url(#filter1_d_4407_4378)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M38.5 23H73.5C74.8807 23 76 24.1193 76 25.5V70.5C76 71.8807 74.8807 73 73.5 73H38.5C37.1193 73 36 71.8807 36 70.5V25.5C36 24.1193 37.1193 23 38.5 23ZM46 52C45.4477 52 45 52.4477 45 53C45 53.5523 45.4477 54 46 54H58.5C59.0523 54 59.5 53.5523 59.5 53C59.5 52.4477 59.0523 52 58.5 52H46ZM46 59.5C45.4477 59.5 45 59.9477 45 60.5C45 61.0523 45.4477 61.5 46 61.5H66C66.5523 61.5 67 61.0523 67 60.5C67 59.9477 66.5523 59.5 66 59.5H46Z"
                      fill="#DDFFED"
                    />
                  </g>
                  <path
                    d="M46 23H57.25V43L51.625 38L46 43V23Z"
                    fill="#FFCA64"
                    stroke="#FFCA64"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <defs>
                    <filter
                      id="filter0_di_4407_4378"
                      x="0"
                      y="0"
                      width="112"
                      height="112"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="8" />
                      <feGaussianBlur stdDeviation="8" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.815686 0 0 0 0 0.815686 0 0 0 0 0.929412 0 0 0 0.36 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_4407_4378"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_4407_4378"
                        result="shape"
                      />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="-4" />
                      <feGaussianBlur stdDeviation="6" />
                      <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                      />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect2_innerShadow_4407_4378"
                      />
                    </filter>
                    <filter
                      id="filter1_d_4407_4378"
                      x="34"
                      y="22"
                      width="44"
                      height="54"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="1" />
                      <feGaussianBlur stdDeviation="1" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.184314 0 0 0 0 0.835294 0 0 0 0 0.678431 0 0 0 0.5 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_4407_4378"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_4407_4378"
                        result="shape"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_4407_4378"
                      x1="56"
                      y1="8"
                      x2="56"
                      y2="88"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#15CEA2" />
                      <stop offset="1" stopColor="#B6FFE0" />
                    </linearGradient>
                  </defs>
                </svg>
              </Link>
              <span>Invitation record</span>
            </div>
          </div>
        </div>
      </div>

      <div className="invite-container">
        {invites.map((v, i) => (
          <div className="invite-item" key={i}>
            <div className="invite-item-header">
              <div className="item-header1">
                <div className="d-flex align-center" style={{ gap: "5px" }}>
                  <span style={{ color: "#bdbdbd", fontSize: ".7rem" }}>
                    Bonus
                  </span>
                  <span className="invite-item-num">{i + 1}</span>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 48 48"
                  fill="none"
                >
                  <g clipPath="url(#clip0_4400_2169)">
                    <path
                      d="M23.9004 43.8999C34.9461 43.8999 43.9004 34.9456 43.9004 23.8999C43.9004 12.8542 34.9461 3.8999 23.9004 3.8999C12.8547 3.8999 3.90039 12.8542 3.90039 23.8999C3.90039 34.9456 12.8547 43.8999 23.9004 43.8999Z"
                      fill="white"
                    />
                    <path
                      d="M34 14L14 34"
                      stroke="#BABFE0"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14 14L34 34"
                      stroke="#BABFE0"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4400_2169">
                      <rect width="48" height="48" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="item-header2">
                <span>₹{v.price.toLocaleString()}.00</span>
              </div>
            </div>
            <div className="noi">
              <span className="txt">Number of invites</span>
              <span style={{ textAlign: "center" }}>{v.invites}</span>
            </div>
            <div className="noi">
              <span className="txt">Recharge per people</span>
              <span style={{ textAlign: "center", color: "#ff3e3e" }}>
                ₹{v.rpp.toLocaleString()}.00
              </span>
            </div>
            <div className="invite-item-saparator"></div>
            <div className="complete-container">
              <div className="complete-box">
                <span className="num" style={{ color: "#ff9832" }}>
                  {people}/{v.invites}
                </span>
                <span className="txt">Number of invites</span>
              </div>
              <div className="complete-box">
                <span className="num" style={{ color: "#ff3e3e" }}>
                  {people}/{v.invites}
                </span>
                <span className="txt">Deposit numbers</span>
              </div>
            </div>
            <div
              className="unfinished-btn"
              onClick={
                people >= v.invites
                  ? () => handleInvitationBonus(v.invites, v.price)
                  : null
              }
            >
              <GradientButton
                bg={`${
                  people >= v.invites
                    ? "linear-gradient(90deg, #23367d 0%, #383cbc 100%)"
                    : "linear-gradient(90deg, #CCCEDC 15.38%, #CDCFDD 98.73%)"
                }`}
                text={`${people >= v.invites ? "Finished" : "Unfinished"}`}
                color={"#fff"}
                fw={"bold"}
              />
            </div>
          </div>
        ))}
      </div>
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
    </>
  );
};

export default InvitationBonus;
