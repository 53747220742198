import ballNum0 from "./logo/ballnum0.png";
import ballNum1 from "./logo/ballnum1.png";
import ballNum2 from "./logo/ballnum2.png";
import ballNum3 from "./logo/ballnum3.png";
import ballNum4 from "./logo/ballnum4.png";
import ballNum5 from "./logo/ballnum5.png";
import ballNum6 from "./logo/ballnum6.png";
import ballNum7 from "./logo/ballnum7.png";
import ballNum8 from "./logo/ballnum8.png";
import ballNum9 from "./logo/ballnum9.png";
import ballNuma from "./logo/a-num.png";
import ballNumb from "./logo/b-num.png";
import ballNumc from "./logo/c-num.png";
import ballNumd from "./logo/d-num.png";
import ballNume from "./logo/e-num.png";
import ballNumf from "./logo/f-num.png";


import checkImg from "./logo/check.png";
import roundLogo from "./logo/round_logo.png";
import checkCircle from "./logo/checkcircle.png";
import notFoundImg from "./img/notfound.png";
import lowAudio from "./sounds/low.mp3";
import highAudio from "./sounds/high.mp3";
import ludo1 from "./logo/ludo1.png";
import ludo2 from "./logo/ludo2.png";
import ludo3 from "./logo/ludo3.png";
import ludo4 from "./logo/ludo4.png";
import ludo5 from "./logo/ludo5.png";
import ludo6 from "./logo/ludo6.png";
import xludo1 from "./logo/xludo1.png";
import xludo2 from "./logo/xludo2.png";
import xludo3 from "./logo/xludo3.png";
import xludo4 from "./logo/xludo4.png";
import xludo5 from "./logo/xludo5.png";
import xludo6 from "./logo/xludo6.png";
import redBallBg from "./logo/redballbg.png";
import greenBallBg from "./logo/greenballbg.png";
import trxTimerBg from "./logo/trxtimerbg.png";
import eyeClose from "./logo/eye.png";
import eyeOpen from "./logo/eye_open.png";
import invitationBonus from "./logo/invitationBonus.png";
import bettingRebate from "./logo/bettingRebate.png";
import superJackpot from "./logo/superJackpot.png";
import signInBanner from "./img/signInBanner.png";
import giftRedeem from "./img/giftRedeem.png";
import invitationBonusBanner from "./img/invitationBonus.png";
import superJackpotBanner from "./img/superJackpot.png";
import lotteryLogo from "./logo/lottery.png";
import giftBanner from "./img/giftBanner.png";
import attendanceBanner from "./img/attendanceBanner.png";
import coin from "./logo/coin.png";
import rebateBg from "./logo/rebatebg.png";
import supportCall from "./logo/supportCall.png";
import giftBox from "./img/giftBox.png";
import ruleHeadingBg from "./img/ruleheadingbg.png";
import noData from "./img/nodata.png";
import supportbanner from "./img/supportbanner.png";
import walletBg from "./icons/walletbg.png";
import rechargeHistory from "./icons/rechargeHistory.png";
import rechargeIcon from "./icons/rechargeIcon.png";
import widthdrawBlue from "./icons/widthdrawBlue.png";
import withdrawHistory from "./icons/withdrawHistory.png";
import balanceIcon from "./icons/balance.png";
import upiIcon from "./icons/upi.png";
import allTab from "./icons/alltab.png";
import paytmIcon from "./icons/paytm.png";
import refreshIcon from "./icons/refresh.png";
import inputClearIcon from "./icons/inputclear.png";
import leftArrow from "./icons/left_arrow.png";
import bankAddIcon from "./icons/bankadd.png";
import usdtIcon from "./icons/usdticon.png";
import noticeIcon from "./icons/notice.png";
import bankIcon from "./icons/bankIcon.png";
import bankCard from "./icons/bankCard.png";
import banner1 from "./banner/banner1.jpg";
import banner2 from "./banner/banner2.jpg";
import banner3 from "./banner/banner3.jpg";
import banner4 from "./banner/banner4.jpg";
import banner5 from "./banner/banner5.jpg";
import banner6 from "./banner/banner6.jpg";
import banner7 from "./banner/banner7.jpg";
import banner8 from "./banner/banner8.jpg";
import banner9 from "./banner/banner9.jpg";
import banner10 from "./banner/banner10.jpg";
import banner11 from "./banner/banner11.jpg";
import diceLogo from "./logo/dice.png";
import mainLogo from "./logo/logo.png";
import av0 from "./avatars/0.png";
import av1 from "./avatars/1.png";
import av2 from "./avatars/2.png";
import av3 from "./avatars/3.png";
import av4 from "./avatars/4.png";
import av5 from "./avatars/5.png";
import av6 from "./avatars/6.png";
import av7 from "./avatars/7.png";
import av8 from "./avatars/8.png";
import av9 from "./avatars/9.png";
import av10 from "./avatars/10.png";
import av11 from "./avatars/11.png";
import av12 from "./avatars/12.png";
import av13 from "./avatars/13.png";
import av14 from "./avatars/14.png";
import av15 from "./avatars/15.png";
import av16 from "./avatars/16.png";
import av17 from "./avatars/17.png";
import av18 from "./avatars/18.png";
import av19 from "./avatars/19.png";
import av20 from "./avatars/20.png";
import depositIcon from "./icons/deposit.png";
import depositHistoryIcon from "./icons/deposithistory.png";
import gameIcon from "./icons/gamehistory.png";
import tnxHistoryIcon from "./icons/transactionhistory.png";
import vipIcon from "./icons/vip.png";
import walletIcon from "./icons/wallet.png";
import withdraw from "./icons/withdraw.png";
import wdlHistoryIcon from "./icons/withdrawhistory2.png";
import vipbg1 from "./vip/bg1.png";
import vipbg2 from "./vip/bg2.png";
import vipbg3 from "./vip/bg3.png";
import vipbg4 from "./vip/bg4.png";
import vipbg5 from "./vip/bg5.png";
import vipbg6 from "./vip/bg6.png";
import vipbg7 from "./vip/bg7.png";
import vipbg8 from "./vip/bg8.png";
import vipbg9 from "./vip/bg9.png";
import vipbg10 from "./vip/bg10.png";
import vipbatch1 from "./vip/batch1.png";
import vipbatch2 from "./vip/batch2.png";
import vipbatch3 from "./vip/batch3.png";
import vipbatch4 from "./vip/batch4.png";
import vipbatch5 from "./vip/batch5.png";
import vipbatch6 from "./vip/batch6.png";
import vipbatch7 from "./vip/batch7.png";
import vipbatch8 from "./vip/batch8.png";
import vipbatch9 from "./vip/batch9.png";
import vipbatch10 from "./vip/batch10.png";
import acNotify from "./icons/ac-notification.png";
import acLang from "./icons/ac-lang.png";
import acStatistics from "./icons/ac-statistics.png";
import acGift from "./icons/ac-gift.png";
import tipLogo from "./logo/tip.png";
import vipDiamond from "./icons/vipDiamond.png";
import book from "./icons/book.png";
import bulb from "./icons/bulb.png";
import Cube from "./icons/Cube.png";
import list from "./icons/list.png";
import setting from "./icons/setting.png";
import support from "./icons/support.png";
import vipGift from "./icons/vipGift.png";
import vipCoin from "./icons/vipCoin.png";
import vipCoins from "./icons/vipCoins.png";
import vipcrown from "./vip/vipcrown.png";
import vipcrowngold from "./vip/vipcrowngold.png";
import unlocked from "./vip/unlocked.png";
import lock from "./icons/lock.png";
import xgameLogo from "./icons/xgame-trade.png";
import slots from "./img/slots.png";
import fishing from "./img/fishing.png";
import casino from "./img/casino.png";
import sports from "./img/sports.png";
import pvc from "./img/pvc.png";
import slots1 from "./slots/slots1.png";
import slots2 from "./slots/slots2.png";
import slots3 from "./slots/slots3.png";
import slots4 from "./slots/slots4.png";
import slots5 from "./slots/slots5.png";
import slots6 from "./slots/slots6.png";
import slots7 from "./slots/slots7.png";
import slots8 from "./slots/slots8.png";
import slots9 from "./slots/slots9.png";
import slots10 from "./slots/slots10.png";
import slots11 from "./slots/slots11.png";
import fishing1 from "./fishing/fishing1.png";
import fishing2 from "./fishing/fishing2.png";
import fishing3 from "./fishing/fishing3.png";
import fishing4 from "./fishing/fishing4.png";
import fishing5 from "./fishing/fishing5.png";
import fishing6 from "./fishing/fishing6.png";
import fishing7 from "./fishing/fishing7.png";
import popular1 from "./popular/popular1.png";
import popular2 from "./popular/popular2.png";
import popular3 from "./popular/popular3.png";
import popular4 from "./popular/popular4.png";
import popular5 from "./popular/popular5.png";
import popular6 from "./popular/popular6.png";
import popular7 from "./popular/popular7.png";
import popular8 from "./popular/popular8.png";
import main_icon from "./logo/brand_logo.png";
import feedback from "./img/feedback.png";
import about from "./img/about.png";
import ring from "./icons/ring-icon.png";
import vip1 from "./vip/vip1.png";
import vip2 from "./vip/vip2.png";
import vip3 from "./vip/vip3.png";
import vip4 from "./vip/vip4.png";
import vip5 from "./vip/vip5.png";
import vip6 from "./vip/vip6.png";
import vip7 from "./vip/vip7.png";
import vip8 from "./vip/vip8.png";
import animlogo from "./logo/main_logo.png";
import splashlogo from "./logo/splashlogo.png";

export {
  ballNuma,
  ballNumb,
  ballNumc,
  ballNumd,
  ballNume,
  ballNumf,
  animlogo,
  splashlogo,
  vip1,
  vip2,
  vip3,
  vip4,
  vip5,
  vip6,
  vip7,
  vip8,
  ring,
  about,
  feedback,
  main_icon,
  popular1,
  popular2,
  popular3,
  popular4,
  popular5,
  popular6,
  popular7,
  popular8,
  fishing1,
  fishing2,
  fishing3,
  fishing4,
  fishing5,
  fishing6,
  fishing7,
  fishing,
  casino,
  sports,
  roundLogo,
  pvc,
  slots,
  slots1,
  slots2,
  slots3,
  slots4,
  slots5,
  slots6,
  slots7,
  slots8,
  slots9,
  slots10,
  slots11,
  xgameLogo,
  lock,
  book,
  bulb,
  Cube,
  list,
  setting,
  support,
  unlocked,
  vipcrown,
  vipcrowngold,
  vipbatch1,
  vipbatch2,
  vipbatch3,
  vipbatch4,
  vipbatch5,
  vipbatch6,
  vipbatch7,
  vipbatch8,
  vipbatch9,
  vipbatch10,
  vipCoin,
  vipCoins,
  vipGift,
  vipDiamond,
  tipLogo,
  acNotify,
  acLang,
  acStatistics,
  acGift,
  vipbg1,
  vipbg2,
  vipbg3,
  vipbg4,
  vipbg5,
  vipbg6,
  vipbg7,
  vipbg8,
  vipbg9,
  vipbg10,
  av0,
  av1,
  av2,
  av3,
  av4,
  av5,
  av6,
  av7,
  av8,
  av9,
  av10,
  av11,
  av12,
  av13,
  av14,
  av15,
  av16,
  av17,
  av18,
  av19,
  av20,
  depositIcon,
  depositHistoryIcon,
  gameIcon,
  tnxHistoryIcon,
  vipIcon,
  walletIcon,
  withdraw,
  wdlHistoryIcon,
  diceLogo,
  mainLogo,
  banner1,
  banner2,
  banner3,
  banner4,
  banner5,
  banner6,
  banner7,
  banner8,
  banner9,
  banner10,
  banner11,
  bankCard,
  bankIcon,
  noticeIcon,
  usdtIcon,
  bankAddIcon,
  allTab,
  leftArrow,
  inputClearIcon,
  balanceIcon,
  refreshIcon,
  upiIcon,
  paytmIcon,
  rechargeHistory,
  rechargeIcon,
  widthdrawBlue,
  withdrawHistory,
  walletBg,
  ballNum0,
  ballNum1,
  ballNum2,
  ballNum3,
  ballNum4,
  ballNum5,
  ballNum6,
  ballNum7,
  ballNum8,
  ballNum9,
  notFoundImg,
  checkImg,
  checkCircle,
  lowAudio,
  highAudio,
  ludo1,
  ludo2,
  ludo3,
  ludo4,
  ludo5,
  ludo6,
  xludo1,
  xludo2,
  xludo3,
  xludo4,
  xludo5,
  xludo6,
  redBallBg,
  greenBallBg,
  trxTimerBg,
  eyeClose,
  eyeOpen,
  invitationBonus,
  bettingRebate,
  superJackpot,
  signInBanner,
  giftRedeem,
  invitationBonusBanner,
  lotteryLogo,
  superJackpotBanner,
  giftBanner,
  attendanceBanner,
  coin,
  giftBox,
  ruleHeadingBg,
  noData,
  rebateBg,
  supportbanner,
  supportCall,
};
