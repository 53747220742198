import React, { useEffect, useRef, useState } from "react";
import CustomHeader from "../../common/CustomHeader";
import {
  allTab,
  casino,
  lotteryLogo,
  noData,
  slots,
  sports,
} from "../../../assets/media";
import { Link, useNavigate } from "react-router-dom";
import { getRebates, getUser, rebateCreate } from "../../../utils/UserController";
import { formatMoney } from "../../../utils/Utils";
import Toast from "../Toast";
import { FaRegDotCircle } from 'react-icons/fa'
import { AiOutlineSmallDash } from 'react-icons/ai'

const BettingRebet = () => {
  const [activeTab, setActiveTab] = useState(0);
  const tabsRef = useRef(null);

  const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });

  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 3000); // Hide after 3 seconds
  };

  const showAlert = () => {
    showToast(
      `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Play more bet.`
    );
  };

  const tabs = [
    {
      image: allTab,
      label: "All",
      component: <All callback={showAlert} />,
    },
    {
      image: lotteryLogo,
      label: "Lottery",
      component: <Lottery callback={showAlert} />,
    },
    {
      image: casino,
      label: "Casino",
      component: <Casino callback={showAlert} />,
    },
    {
      image: sports,
      label: "Rummy",
      component: <Rummy callback={showAlert} />,
    },
    {
      image: slots,
      label: "Slots",
      component: <Slots callback={showAlert} />,
    },
  ];

  useEffect(() => {
    if (tabsRef.current) {
      const activeTabElement = tabsRef.current.children[activeTab];
      const tabContainerWidth = tabsRef.current.offsetWidth;
      const tabElementWidth = activeTabElement.offsetWidth;
      const scrollPosition =
        activeTabElement.offsetLeft -
        (tabContainerWidth / 2 - tabElementWidth / 2);
      tabsRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [activeTab]);
  return (
    <>
      <CustomHeader
        bg={"var(--themeBlack)"}
        color={"var(--mainText)"}
        heading={"Rebate"}
        path={"/activity"}
      />
      <div className="games-tabs-container" ref={tabsRef}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`games-tab ${index === activeTab ? "active" : ""}`}
            onClick={() => setActiveTab(index)}
          >
            <img src={tab.image} alt="" />
            {tab.label}
          </div>
        ))}
      </div>
      <div className="games-tab-content">{tabs[activeTab].component}</div>
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
    </>
  );
};

export default BettingRebet;

const All = ({ callback }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState();
const [rebetData,setRebetData]=useState([])

  useEffect(() => {
    const getUserData = async () => {
      const userData = await getUser();
      const rebetData = await getRebates();
      setRebetData(rebetData?.data?.data)
      if (userData.status === 200) {
        if (userData.data.status === "expired") {
          navigate("/login");
        }
        setUser(userData.data.data);
      }
    };
    getUserData();
  }, []);


const submitData=async(amount)=>{
  await rebateCreate(amount)
}



let totalCommission=0
if(rebetData !==null || rebetData !==undefined){
  totalCommission = Array.isArray(rebetData) && rebetData?.reduce((acc, item) => {
    return acc + parseFloat(item.commission);
  }, 0);
}

  // Function to get today's commission
  const todayDate = new Date().toISOString().split('T')[0]; // Get current date in 'YYYY-MM-DD' format
  let todayCommission=0

  if(rebetData !==null || rebetData !==undefined){
    todayCommission  = Array.isArray(rebetData) 
    ? rebetData
        .filter((item) => item?.today?.startsWith(todayDate))
        .reduce((acc, item) => acc + parseFloat(item.commission || 0), 0)
    : 0;
  }
  return (
    <>
      <div className="rebet-tab-content">
        <span className="tab-des">All-Total betting rebate</span>
        <div className="real-time-count">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18px"
            height="18px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#6485ff"
              d="m12 1l8.217 1.826a1 1 0 0 1 .783.976v9.987a6 6 0 0 1-2.672 4.992L12 23l-6.328-4.219A6 6 0 0 1 3 13.79V3.802a1 1 0 0 1 .783-.976zm4.452 7.222l-4.95 4.949l-2.828-2.828l-1.414 1.414L11.503 16l6.364-6.364z"
            />
          </svg>
          Real-time count
        </div>
        <div className="money">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30px"
            height="30px"
            viewBox="0 0 24 24"
          >
            <g fill="none">
              <path
                stroke="#6485ff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M6 8h4"
              />
              <path
                stroke="#6485ff"
                strokeLinecap="round"
                strokeWidth="1.5"
                d="M22 10.5c0-.077 0-.533-.002-.565c-.036-.501-.465-.9-1.005-.933C20.959 9 20.918 9 20.834 9h-2.602C16.446 9 15 10.343 15 12s1.447 3 3.23 3h2.603c.084 0 .125 0 .16-.002c.54-.033.97-.432 1.005-.933c.002-.032.002-.488.002-.565"
              />
              <circle cx="18" cy="12" r="1" fill="#6485ff" />
              <path
                stroke="#6485ff"
                strokeLinecap="round"
                strokeWidth="1.5"
                d="M13 4c3.771 0 5.657 0 6.828 1.172c.809.808 1.06 1.956 1.137 3.828M10 20h3c3.771 0 5.657 0 6.828-1.172c.809-.808 1.06-1.956 1.137-3.828M9 4c-3.114.01-4.765.108-5.828 1.172C2 6.343 2 8.229 2 12c0 3.771 0 5.657 1.172 6.828c.653.654 1.528.943 2.828 1.07"
              />
            </g>
          </svg>
          <span className="money-val">{formatMoney(user?.rebate, "")}</span>
        </div>
        <div className="text">Upgrade VIP level to increase rebate rate</div>
        <div className="total-boxes">
          <div className="total-rebate">
            <span>Total rebate</span>
            <span className="percent">0.1</span>
          </div>
          <div className="total-rebate">
            <span>Total rebate</span>
            <span className="percent">0</span>
          </div>
        </div>
        <div className="xtext">
          Automatic code washing at 01:00:00 every morning
        </div>
        <button
          className={`one-click-rebate ${
            Number(user?.rebate) > 0 ? "btn-active" : ""
          }`}
          disabled={user?.rebate>0?false:true}
          onClick={()=>submitData(user?.rebate)}
        >
          One-Click Rebate
        </button>
      </div>

    
      <span
        className="games-tab-lebel"
        style={{ fontSize: "1.2rem", marginTop: "10px" }}
      >
        Rebate History
      </span>

      {Array.isArray(rebetData) && rebetData?.map((data, i) => (
          <div className='nav-bg rounded-xl  mt-3 pb-4 p-1' key={i}>
            <div className='flex justify-between items-center px-3'>
              <h3 className="heading-h3 font-semibold gray-50">{data.type}</h3>

              <p className={`text-base   ${data.status === 0 ? "color-yellow-200" : data.status === 1 ? "color-green" : "color-red-200"}`}>{data.status === 0 ? "Pending" : data.status === 1 ? "Completed" : "Failed"}</p>
            </div>
            <p className='fs-sm p-1 gray-100 border-b border-color-slat leading-5'>{data.today}</p>
            <ul className='px-2 mt-2'>
              <li className='flex  justify-between'>
                <div className='flex '>
                  <div className='flex  flex-col items-center mt-[3px] mr-1'>
                    <FaRegDotCircle className='blue-color-300 bg-white rounded-full fs-sm mr-1 border-b' />
                    {i < 5 ? (
                      <AiOutlineSmallDash className='rotate-90 mt-[2px] mr-1 blue-color-300 fs-sm' />
                    ) : ""}
                  </div>
                  <p className='gray-100 text-sm '>Betting rebate </p>
                </div>
                <span className=' text-sm gray-100'>{data.amount}</span>
              </li>
              <li className='flex  justify-between'>
                <div className='flex '>
                  <div className='flex  flex-col items-center mt-[3px] mr-1'>
                    <FaRegDotCircle className='blue-color-300 bg-white rounded-full fs-sm mr-1 border-b' />
                    {i < 5 ? (
                      <AiOutlineSmallDash className='rotate-90 mt-[2px] mr-1 blue-color-300 fs-sm' />

                    ) : ""}

                  </div>
                  <p className='gray-100 text-sm '>Rebate rate </p>
                </div>
                <span className=' text-sm  color-red-200'>{data.rate}%</span>
              </li>
              <li className='flex  justify-between'>
                <div className='flex '>
                  <div className='flex  flex-col items-center mt-[3px] mr-1'>
                    <FaRegDotCircle className='blue-color-300 bg-white rounded-full fs-sm mr-1 border-b' />


                  </div>
                  <p className='gray-100 text-sm '>Rebate amount</p>
                </div>
                <span className=' text-sm  color-yellow-200'>{data.commission}</span>
              </li>


            </ul>
          </div>
        ))}


      {rebetData?.length !==0 ? (
        <div className="all-history mt-3">
          <Link to={"/activity/BettingRebet/AllHistory "}>All history</Link>
        </div>
      ) : (
        <div className="empty">
          <img src={noData} alt="" className="noData" />
          <span>No Data</span>
        </div>
      )}
    </>
  );
};

const Lottery = ({ callback }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState();

  useEffect(() => {
    const getUserData = async () => {
      const userData = await getUser();
      if (userData.status === 200) {
        if (userData.data.status === "expired") {
          navigate("/login");
        }
        setUser(userData.data.data);
      }
    };
    getUserData();
  }, []);
  return (
    <>
      <div className="rebet-tab-content">
        <span className="tab-des">Lottery-Total betting rebate</span>
        <div className="real-time-count">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18px"
            height="18px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#6485ff"
              d="m12 1l8.217 1.826a1 1 0 0 1 .783.976v9.987a6 6 0 0 1-2.672 4.992L12 23l-6.328-4.219A6 6 0 0 1 3 13.79V3.802a1 1 0 0 1 .783-.976zm4.452 7.222l-4.95 4.949l-2.828-2.828l-1.414 1.414L11.503 16l6.364-6.364z"
            />
          </svg>
          Real-time count
        </div>
        <div className="money">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30px"
            height="30px"
            viewBox="0 0 24 24"
          >
            <g fill="none">
              <path
                stroke="#6485ff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M6 8h4"
              />
              <path
                stroke="#6485ff"
                strokeLinecap="round"
                strokeWidth="1.5"
                d="M22 10.5c0-.077 0-.533-.002-.565c-.036-.501-.465-.9-1.005-.933C20.959 9 20.918 9 20.834 9h-2.602C16.446 9 15 10.343 15 12s1.447 3 3.23 3h2.603c.084 0 .125 0 .16-.002c.54-.033.97-.432 1.005-.933c.002-.032.002-.488.002-.565"
              />
              <circle cx="18" cy="12" r="1" fill="#6485ff" />
              <path
                stroke="#6485ff"
                strokeLinecap="round"
                strokeWidth="1.5"
                d="M13 4c3.771 0 5.657 0 6.828 1.172c.809.808 1.06 1.956 1.137 3.828M10 20h3c3.771 0 5.657 0 6.828-1.172c.809-.808 1.06-1.956 1.137-3.828M9 4c-3.114.01-4.765.108-5.828 1.172C2 6.343 2 8.229 2 12c0 3.771 0 5.657 1.172 6.828c.653.654 1.528.943 2.828 1.07"
              />
            </g>
          </svg>
          <span className="money-val">{formatMoney(user?.exp)}</span>
        </div>
        <div className="text">Upgrade VIP level to increase rebate rate</div>
        <div className="total-boxes">
          <div className="rebate-rate">
            <span>Rebate rate</span>
            <span className="percent">0.001%</span>
          </div>
          <div className="total-rebate">
            <span>Total rebate</span>
            <span className="percent">0</span>
          </div>
        </div>
        <div className="xtext">
          Automatic code washing at 01:00:00 every morning
        </div>
        <div
          className={`one-click-rebate ${
            Number(user?.money) >= 100 ? "btn-active" : ""
          }`}
          onClick={callback}
        >
          One-Click Rebate
        </div>
      </div>
      <span
        className="games-tab-lebel"
        style={{ fontSize: "1.2rem", marginTop: "10px" }}
      >
        Rebate History
      </span>
      {false ? (
        <div className="all-history">
          <Link to={"/activity/BettingRebet/AllHistory"}>All history</Link>
        </div>
      ) : (
        <div className="empty">
          <img src={noData} alt="" className="noData" />
          <span>No Data</span>
        </div>
      )}
    </>
  );
};
const Casino = () => {
  return (
    <>
      <div className="rebet-tab-content">
        <span className="tab-des">Casino-Total betting rebate</span>
        <div className="real-time-count">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18px"
            height="18px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#6485ff"
              d="m12 1l8.217 1.826a1 1 0 0 1 .783.976v9.987a6 6 0 0 1-2.672 4.992L12 23l-6.328-4.219A6 6 0 0 1 3 13.79V3.802a1 1 0 0 1 .783-.976zm4.452 7.222l-4.95 4.949l-2.828-2.828l-1.414 1.414L11.503 16l6.364-6.364z"
            />
          </svg>
          Real-time count
        </div>
        <div className="money">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30px"
            height="30px"
            viewBox="0 0 24 24"
          >
            <g fill="none">
              <path
                stroke="#6485ff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M6 8h4"
              />
              <path
                stroke="#6485ff"
                strokeLinecap="round"
                strokeWidth="1.5"
                d="M22 10.5c0-.077 0-.533-.002-.565c-.036-.501-.465-.9-1.005-.933C20.959 9 20.918 9 20.834 9h-2.602C16.446 9 15 10.343 15 12s1.447 3 3.23 3h2.603c.084 0 .125 0 .16-.002c.54-.033.97-.432 1.005-.933c.002-.032.002-.488.002-.565"
              />
              <circle cx="18" cy="12" r="1" fill="#6485ff" />
              <path
                stroke="#6485ff"
                strokeLinecap="round"
                strokeWidth="1.5"
                d="M13 4c3.771 0 5.657 0 6.828 1.172c.809.808 1.06 1.956 1.137 3.828M10 20h3c3.771 0 5.657 0 6.828-1.172c.809-.808 1.06-1.956 1.137-3.828M9 4c-3.114.01-4.765.108-5.828 1.172C2 6.343 2 8.229 2 12c0 3.771 0 5.657 1.172 6.828c.653.654 1.528.943 2.828 1.07"
              />
            </g>
          </svg>
          <span className="money-val">0.00</span>
        </div>
        <div className="text">Upgrade VIP level to increase rebate rate</div>
        <div className="total-boxes">
          <div className="rebate-rate">
            <span>Rebate rate</span>
            <span className="percent">0.0%</span>
          </div>
          <div className="total-rebate">
            <span>Total rebate</span>
            <span className="percent">0</span>
          </div>
        </div>
        <div className="xtext">
          Automatic code washing at 01:00:00 every morning
        </div>
        <div className="one-click-rebate">One-Click Rebate</div>
      </div>
      <span
        className="games-tab-lebel"
        style={{ fontSize: "1.2rem", marginTop: "10px" }}
      >
        Rebate History
      </span>
      {false ? (
        <div className="all-history">
          <Link to={"/activity/BettingRebet/AllHistory"}>All history</Link>
        </div>
      ) : (
        <div className="empty">
          <img src={noData} alt="" className="noData" />
          <span>No Data</span>
        </div>
      )}
    </>
  );
};
const Rummy = () => {
  return (
    <>
      <div className="rebet-tab-content">
        <span className="tab-des">Rummy-Total betting rebate</span>
        <div className="real-time-count">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18px"
            height="18px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#6485ff"
              d="m12 1l8.217 1.826a1 1 0 0 1 .783.976v9.987a6 6 0 0 1-2.672 4.992L12 23l-6.328-4.219A6 6 0 0 1 3 13.79V3.802a1 1 0 0 1 .783-.976zm4.452 7.222l-4.95 4.949l-2.828-2.828l-1.414 1.414L11.503 16l6.364-6.364z"
            />
          </svg>
          Real-time count
        </div>
        <div className="money">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30px"
            height="30px"
            viewBox="0 0 24 24"
          >
            <g fill="none">
              <path
                stroke="#6485ff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M6 8h4"
              />
              <path
                stroke="#6485ff"
                strokeLinecap="round"
                strokeWidth="1.5"
                d="M22 10.5c0-.077 0-.533-.002-.565c-.036-.501-.465-.9-1.005-.933C20.959 9 20.918 9 20.834 9h-2.602C16.446 9 15 10.343 15 12s1.447 3 3.23 3h2.603c.084 0 .125 0 .16-.002c.54-.033.97-.432 1.005-.933c.002-.032.002-.488.002-.565"
              />
              <circle cx="18" cy="12" r="1" fill="#6485ff" />
              <path
                stroke="#6485ff"
                strokeLinecap="round"
                strokeWidth="1.5"
                d="M13 4c3.771 0 5.657 0 6.828 1.172c.809.808 1.06 1.956 1.137 3.828M10 20h3c3.771 0 5.657 0 6.828-1.172c.809-.808 1.06-1.956 1.137-3.828M9 4c-3.114.01-4.765.108-5.828 1.172C2 6.343 2 8.229 2 12c0 3.771 0 5.657 1.172 6.828c.653.654 1.528.943 2.828 1.07"
              />
            </g>
          </svg>
          <span className="money-val">0.00</span>
        </div>
        <div className="text">Upgrade VIP level to increase rebate rate</div>
        <div className="total-boxes">
          <div className="rebate-rate">
            <span>Rebate rate</span>
            <span className="percent">0.0%</span>
          </div>
          <div className="total-rebate">
            <span>Total rebate</span>
            <span className="percent">0</span>
          </div>
        </div>
        <div className="xtext">
          Automatic code washing at 01:00:00 every morning
        </div>
        <div className="one-click-rebate">One-Click Rebate</div>
      </div>
      <span
        className="games-tab-lebel"
        style={{ fontSize: "1.2rem", marginTop: "10px" }}
      >
        Rebate History
      </span>
      {false ? (
        <div className="all-history">
          <Link to={"/activity/BettingRebet/AllHistory"}>All history</Link>
        </div>
      ) : (
        <div className="empty">
          <img src={noData} alt="" className="noData" />
          <span>No Data</span>
        </div>
      )}
    </>
  );
};
const Slots = () => {
  return (
    <>
      <div className="rebet-tab-content">
        <span className="tab-des">Slots-Total betting rebate</span>
        <div className="real-time-count">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18px"
            height="18px"
            viewBox="0 0 24 24"
          >
            <path
              fill="#6485ff"
              d="m12 1l8.217 1.826a1 1 0 0 1 .783.976v9.987a6 6 0 0 1-2.672 4.992L12 23l-6.328-4.219A6 6 0 0 1 3 13.79V3.802a1 1 0 0 1 .783-.976zm4.452 7.222l-4.95 4.949l-2.828-2.828l-1.414 1.414L11.503 16l6.364-6.364z"
            />
          </svg>
          Real-time count
        </div>
        <div className="money">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30px"
            height="30px"
            viewBox="0 0 24 24"
          >
            <g fill="none">
              <path
                stroke="#6485ff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M6 8h4"
              />
              <path
                stroke="#6485ff"
                strokeLinecap="round"
                strokeWidth="1.5"
                d="M22 10.5c0-.077 0-.533-.002-.565c-.036-.501-.465-.9-1.005-.933C20.959 9 20.918 9 20.834 9h-2.602C16.446 9 15 10.343 15 12s1.447 3 3.23 3h2.603c.084 0 .125 0 .16-.002c.54-.033.97-.432 1.005-.933c.002-.032.002-.488.002-.565"
              />
              <circle cx="18" cy="12" r="1" fill="#6485ff" />
              <path
                stroke="#6485ff"
                strokeLinecap="round"
                strokeWidth="1.5"
                d="M13 4c3.771 0 5.657 0 6.828 1.172c.809.808 1.06 1.956 1.137 3.828M10 20h3c3.771 0 5.657 0 6.828-1.172c.809-.808 1.06-1.956 1.137-3.828M9 4c-3.114.01-4.765.108-5.828 1.172C2 6.343 2 8.229 2 12c0 3.771 0 5.657 1.172 6.828c.653.654 1.528.943 2.828 1.07"
              />
            </g>
          </svg>
          <span className="money-val">0.00</span>
        </div>
        <div className="text">Upgrade VIP level to increase rebate rate</div>
        <div className="total-boxes">
          <div className="rebate-rate">
            <span>Rebate rate</span>
            <span className="percent">0.0%</span>
          </div>
          <div className="total-rebate">
            <span>Total rebate</span>
            <span className="percent">0</span>
          </div>
        </div>
        <div className="xtext">
          Automatic code washing at 01:00:00 every morning
        </div>
        <div className="one-click-rebate">One-Click Rebate</div>
      </div>
      <span
        className="games-tab-lebel"
        style={{ fontSize: "1.2rem", marginTop: "10px" }}
      >
        Rebate History
      </span>
      {false ? (
        <div className="all-history">
          <Link to={"/activity/BettingRebet/AllHistory"}>All history</Link>
        </div>
      ) : (
        <div className="empty">
          <img src={noData} alt="" className="noData" />
          <span>No Data</span>
        </div>
      )}
    </>
  );
};
