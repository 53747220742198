import axios from "../api/axiosConfig";


export const betWingo = async (type, bet, amount) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.post(
      "/api/webapi/action/join",
      {
        typeid:type, join:bet, x:1, money:amount
      },
      {withCredentials:true}
    );
    return response; // return response.data if you want only the data
  } catch (error) {
    console.error("BetWingo error:", error);
    throw error; // re-throw the error to handle it in the calling function
  }
};

export const betK3 = async (typeid1, selectBet, selectTab, totalbalance) => {


  try {
    const response = await axios.post(
      "/api/webapi/action/k3/join",
      { game:typeid1, listJoin:selectBet,gameJoin:selectTab , xvalue:1, money:totalbalance }, {withCredentials:true}
    );
    return response; // return response.data if you want only the data
  } catch (error) {
    console.error("BetWingo error:", error);
    throw error; // re-throw the error to handle it in the calling function
  }
};

export const betD5 = async (type, selectBet, selectTab, amount) => {
   try {
    const response = await axios.post(
      '/api/webapi/action/5d/join',{ game:type, list_join:selectBet,join:selectTab , x:1, money:amount }, {withCredentials:true});

      return response; // return response.data if you want only the data
  } catch (error) {
    console.error("BetWingo error:", error);
    throw error; // re-throw the error to handle it in the calling function
  }
};


export const betTrxWin = async (type, user_id, bet, amount, period_id) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.post(
      "/api/user/betTrxWin",
      {
        type,
        user_id,
        bet,
        amount,
        period_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response; // return response.data if you want only the data
  } catch (error) {
    console.error("BetWingo error:", error);
    throw error; // re-throw the error to handle it in the calling function
  }
};
