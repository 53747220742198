import React, { useEffect, useRef, useState } from "react";
import CustomHeader from "../../common/CustomHeader";
import {
  allTab,
  casino,
  lotteryLogo,
  noData,
  slots,
  sports,
} from "../../../assets/media";

const RebetHistory = () => {
  const tabs = [
    {
      image: allTab,
      label: "All",
      component: <All />,
    },
    {
      image: lotteryLogo,
      label: "Lottery",
      component: <Lottery />,
    },
    {
      image: casino,
      label: "Casino",
      component: <Casino />,
    },
    {
      image: sports,
      label: "Rummy",
      component: <Rummy />,
    },
    {
      image: slots,
      label: "Slots",
      component: <Slots />,
    },
  ];

  const [activeTab, setActiveTab] = useState(0);
  const tabsRef = useRef(null);

  useEffect(() => {
    if (tabsRef.current) {
      const activeTabElement = tabsRef.current.children[activeTab];
      const tabContainerWidth = tabsRef.current.offsetWidth;
      const tabElementWidth = activeTabElement.offsetWidth;
      const scrollPosition =
        activeTabElement.offsetLeft -
        (tabContainerWidth / 2 - tabElementWidth / 2);
      tabsRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [activeTab]);
  return (
    <>
      <CustomHeader
        bg={"var(--themeBlack)"}
        color={"var(--mainText)"}
        heading={"Rebate history"}
        path={"/activity/BettingRebet"}
      />
      <div className="games-tabs-container" ref={tabsRef}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`games-tab ${index === activeTab ? "active" : ""}`}
            onClick={() => setActiveTab(index)}
          >
            <img src={tab.image} alt="" />
            {tab.label}
          </div>
        ))}
      </div>
      <div className="games-tab-content">{tabs[activeTab].component}</div>
    </>
  );
};

export default RebetHistory;

const All = () => {
  const [isloading, setIsloading] = useState(true);
  setTimeout(() => {
    setIsloading(false);
  }, 1000);
  return (
    <>
      {isloading ? (
        <div className="server-loader"></div>
      ) : (
        <div className="empty">
          <img src={noData} alt="" className="noData" />
          <span>No Data</span>
        </div>
      )}
    </>
  );
};
const Lottery = () => {
  const [isloading, setIsloading] = useState(true);
  setTimeout(() => {
    setIsloading(false);
  }, 1000);
  return (
    <>
      {isloading ? (
        <div className="server-loader"></div>
      ) : (
        <div className="empty">
          <img src={noData} alt="" className="noData" />
          <span>No Data</span>
        </div>
      )}
    </>
  );
};
const Casino = () => {
  const [isloading, setIsloading] = useState(true);
  setTimeout(() => {
    setIsloading(false);
  }, 1000);
  return (
    <>
      {isloading ? (
        <div className="server-loader"></div>
      ) : (
        <div className="empty">
          <img src={noData} alt="" className="noData" />
          <span>No Data</span>
        </div>
      )}
    </>
  );
};
const Rummy = () => {
  const [isloading, setIsloading] = useState(true);
  setTimeout(() => {
    setIsloading(false);
  }, 1000);
  return (
    <>
      {isloading ? (
        <div className="server-loader"></div>
      ) : (
        <div className="empty">
          <img src={noData} alt="" className="noData" />
          <span>No Data</span>
        </div>
      )}
    </>
  );
};
const Slots = () => {
  const [isloading, setIsloading] = useState(true);
  setTimeout(() => {
    setIsloading(false);
  }, 1000);
  return (
    <>
      {isloading ? (
        <div className="server-loader"></div>
      ) : (
        <div className="empty">
          <img src={noData} alt="" className="noData" />
          <span>No Data</span>
        </div>
      )}
    </>
  );
};
