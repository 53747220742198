import React, { useState } from "react";
import CommonHeader from "../../common/CommonHeader";
import GradientButton from "../GradientButton";
import { changePassword } from "../../../utils/UserController";
import Toast from "../Toast";
import { showLoader } from "../../../utils/Utils";

const PasswordChange = () => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });

  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 3000); // Hide after 3 seconds
  };

  const handleChange = async () => {
    showLoader(true);
    try {
      const res = await changePassword({ password, newPassword });
      console.log(res.data);
      showToast(res.data.message);
      showLoader(false);
    } catch (error) {}
  };

  return (
    <>
      <CommonHeader
        path="/Setting"
        heading="Change login password"
        svg={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
          >
            <path
              fill="var(--lightText)"
              d="m3.55 12l7.35 7.35q.375.375.363.875t-.388.875t-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675T.825 12t.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388t.375.875t-.375.875z"
            />
          </svg>
        }
      />
      <div className="change-container">
        <div className="input-container">
          <div className="label">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25px"
              height="25px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#6485ff"
                fillRule="evenodd"
                d="M5.25 10.055V8a6.75 6.75 0 0 1 13.5 0v2.055c1.115.083 1.84.293 2.371.824C22 11.757 22 13.172 22 16c0 2.828 0 4.243-.879 5.121C20.243 22 18.828 22 16 22H8c-2.828 0-4.243 0-5.121-.879C2 20.243 2 18.828 2 16c0-2.828 0-4.243.879-5.121c.53-.531 1.256-.741 2.371-.824M6.75 8a5.25 5.25 0 0 1 10.5 0v2.004C16.867 10 16.451 10 16 10H8c-.452 0-.867 0-1.25.004zM14 16a2 2 0 1 1-4 0a2 2 0 0 1 4 0"
                clipRule="evenodd"
              />
            </svg>
            <span>Login password</span>
          </div>
          <div className="input-group">
            <input
              className="d-input password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Login password"
            />
          </div>
        </div>
        <div className="input-container">
          <div className="label">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25px"
              height="25px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#6485ff"
                fillRule="evenodd"
                d="M5.25 10.055V8a6.75 6.75 0 0 1 13.5 0v2.055c1.115.083 1.84.293 2.371.824C22 11.757 22 13.172 22 16c0 2.828 0 4.243-.879 5.121C20.243 22 18.828 22 16 22H8c-2.828 0-4.243 0-5.121-.879C2 20.243 2 18.828 2 16c0-2.828 0-4.243.879-5.121c.53-.531 1.256-.741 2.371-.824M6.75 8a5.25 5.25 0 0 1 10.5 0v2.004C16.867 10 16.451 10 16 10H8c-.452 0-.867 0-1.25.004zM14 16a2 2 0 1 1-4 0a2 2 0 0 1 4 0"
                clipRule="evenodd"
              />
            </svg>
            <span>New login password</span>
          </div>
          <div className="input-group">
            <input
              className="d-input password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="New login password"
            />
          </div>
        </div>
        <div className="input-container">
          <div className="label">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25px"
              height="25px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#6485ff"
                fillRule="evenodd"
                d="M5.25 10.055V8a6.75 6.75 0 0 1 13.5 0v2.055c1.115.083 1.84.293 2.371.824C22 11.757 22 13.172 22 16c0 2.828 0 4.243-.879 5.121C20.243 22 18.828 22 16 22H8c-2.828 0-4.243 0-5.121-.879C2 20.243 2 18.828 2 16c0-2.828 0-4.243.879-5.121c.53-.531 1.256-.741 2.371-.824M6.75 8a5.25 5.25 0 0 1 10.5 0v2.004C16.867 10 16.451 10 16 10H8c-.452 0-.867 0-1.25.004zM14 16a2 2 0 1 1-4 0a2 2 0 0 1 4 0"
                clipRule="evenodd"
              />
            </svg>
            <span>Confirm new password</span>
          </div>
          <div className="input-group">
            <input
              className="d-input password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm new password"
            />
          </div>
        </div>
        <div className="save-change-btn" onClick={handleChange}>
          <GradientButton
            bg={"var(--main_gradient-color)"}
            text={"Save change"}
            color={"#fff"}
            fw={"bold"}
          />
        </div>
      </div>
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
    </>
  );
};

export default PasswordChange;
