import axios from "../api/axiosConfig";

export const Deposit = async (formData) => {
   try {
    const response = await axios.post("/api/webapi/recharge", formData, {
   withCredentials:true
    });
    return response.data;
  } catch (error) {
    console.error("Deposit error:", error);
  }
};

export const Checkout = async () => { 
  try {
    const response = await axios.get("/api/webapi/recharge/check",{
     withCredentials:true
    });
    return response.data;
  } catch (error) {
    console.error("Deposit error:", error);
  }
};

export const Widthdraw = async (type,money) => {
  
  try {
    const response = await axios.post('/api/webapi/withdrawal',{type:type,money:money}, {withCredentials:true});
    return response.data;
  } catch (error) {
    console.error("Deposit error:", error);
  }
};
