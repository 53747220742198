// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.msg-content {
    width: 100%;

}

.msg-container {
    width: 93%;
    border-radius: 5px;
    margin: auto;
    margin-bottom: 10px;
    background-color: var(--themeBlack);
    padding: 10px;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.msg-heading {
    font-weight: bold;
    color: var(--mainText);
}

.login-time {
    font-size: .8rem;
    color: #B6BCC8;
}

.login-text-time {
    font-size: .8rem;
    color: #768096;
    margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/msg.css"],"names":[],"mappings":"AAAA;IACI,WAAW;;AAEf;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;IACnB,mCAAmC;IACnC,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".msg-content {\n    width: 100%;\n\n}\n\n.msg-container {\n    width: 93%;\n    border-radius: 5px;\n    margin: auto;\n    margin-bottom: 10px;\n    background-color: var(--themeBlack);\n    padding: 10px;\n    display: flex;\n    flex-direction: column;\n    margin-top: 15px;\n}\n\n.msg-heading {\n    font-weight: bold;\n    color: var(--mainText);\n}\n\n.login-time {\n    font-size: .8rem;\n    color: #B6BCC8;\n}\n\n.login-text-time {\n    font-size: .8rem;\n    color: #768096;\n    margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
