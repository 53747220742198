import React, { useEffect, useState } from "react";
import CommonHeader from "../../common/CommonHeader";
import {
  av1,
  av2,
  av3,
  av4,
  av5,
  av6,
  av7,
  av8,
  av9,
  av10,
  av11,
  av12,
  av13,
  av14,
  av15,
  av16,
  av17,
  av18,
  av19,
  av20,
  checkImg,
} from "../../../assets/media";
import { changeUserAvatar, getUser } from "../../../utils/UserController";
import { showLoader } from "../../../utils/Utils";
import { useNavigate } from "react-router-dom";

const AvatarChange = () => {
  const [av, setAv] = useState(3);
  const navigate = useNavigate();

  const avatars = [
    av1,
    av2,
    av3,
    av4,
    av5,
    av6,
    av7,
    av8,
    av9,
    av10,
    av11,
    av12,
    av13,
    av14,
    av15,
    av16,
    av17,
    av18,
    av19,
    av20,
  ];

  const handleChange = async (i) => {
    setAv(i)
    try {
      showLoader(true)
      const res = await changeUserAvatar(i);
      if (res.data.status) {
        showLoader(false);
      }

    } catch (error) {
      
    }
  };

  useEffect(() => {
    const getUserData = async () => {
      showLoader(true);
      try {
        const res = await getUser();
        showLoader(false);
        setAv(res.data.data.avatar);
      } catch (error) {
        console.log(error);
      }
    };
    getUserData();
  }, []);

  return (
    <>
      <CommonHeader
        path="/Setting"
        heading="Change avatar"
        svg={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
          >
            <path
              fill="var(--lightText)"
              d="m3.55 12l7.35 7.35q.375.375.363.875t-.388.875t-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675T.825 12t.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388t.375.875t-.375.875z"
            />
          </svg>
        }
      />
      <div className="avatar-grid">
        {avatars.map((v, i) => (
          <div className={`image ${av === i ? "active" : ""}`}>
            <img
              key={i}
              src={v}
              alt=""
              onClick={() => {
                handleChange(i);
              }}
            />
            {av === i ? (
              <div
                className="check"
                style={{ backgroundImage: `url(${checkImg})` }}
              ></div>
            ) : (
              ""
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default AvatarChange;
