import React, { useEffect, useRef, useState } from "react";
import CustomHeader from "../../common/CustomHeader";
import { useNavigate } from "react-router-dom";
import { formatDate, formatMoney, showLoader } from "../../../utils/Utils";
import DatePicker from "../DatePicker";
import ListPicker from "../ListPicker";
import { getTransactionHistory } from "../../../utils/UserController";
import { noData } from "../../../assets/media";

const TransactionHistory = () => {
  const now = new Date();
  const currentdate = `${now.getFullYear()}-${
    now.getMonth() + 1
  }-${now.getDate()}`;
  const [selectedDate, setSelectedDate] = useState(currentdate);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showItemPicker, setShowItemPicker] = useState(false);
  const [selectedItem, setSelectedItem] = useState("All");
  const navigate = useNavigate();
  const [historyItems, setHistoryItems] = useState([]);

  const handleOpenDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleReturnDate = (date) => {
    setSelectedDate((val) => {
      return date;
    });
  };

  const handleOpenListPicker = () => {
    setShowItemPicker(!showItemPicker);
  };

  const handleReturnList = (item) => {
    setSelectedItem((val) => {
      return item;
    });
  };

  const filterList = ["All", "Processing", "Completed", "Rejected"];

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const getThData = async () => {
      showLoader(true);
      try {
        const res = await getTransactionHistory();
        setHistoryItems(res.data.data);
        showLoader(false);
      } catch (error) {
        console.log(error);
      }
    };
    getThData();
  }, []);

  return (
    <>
      <CustomHeader
        click={goBack}
        bg={"var(--themeBlack)"}
        color={"var(--mainText)"}
        heading={"Transaction history"}
      />
      <div className="select-container">
        <div className="select" onClick={handleOpenListPicker}>
          <span>{selectedItem}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m7 10l5 5l5-5"
            />
          </svg>
        </div>
        <div className="select" onClick={handleOpenDatePicker}>
          <span>{selectedDate}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m7 10l5 5l5-5"
            />
          </svg>
        </div>
      </div>

      <div className="d-tab-content">
        <div className="deposit-history">
          { historyItems && historyItems.length > 0 ? (
            historyItems.map((v, i) => (
              <div className="deposit-history-item" key={i}>
                <div className="history-item-header">
                  <div className="text-white">{v.detail}</div>
                </div>
                
                <div className="history-item-content">
                <div className="d-flex justify-between align-center">
                    <span>Detail</span>
                    <span>{v.detail}</span>
                  </div>
                <div className="d-flex justify-between align-center">
                    <span>Time</span>
                    <span>{v.time}</span>
                  </div>
                  <div className="d-flex justify-between align-center">
                    <span>Balance</span>
                    <span className={`balance ${ v.type === 'Win' ? 'text-green' : v.type === 'Bet' ? 'text-red' : ''}`}>{formatMoney(v.amount)}</span>
                  </div>
                  
                  {/* <div className="d-flex justify-between align-center">
                    <span>Remark</span>
                    <span className="balance">{v.remark}</span>
                  </div> */}
                </div>
              </div>
            ))
          ) : (
            <div className="empty">
              <img src={noData} alt="" className="noData" />
              <span>No Data</span>
            </div>
          )}
        </div>
      </div>
      <DatePicker openDate={showDatePicker} returnDate={handleReturnDate} />
      <ListPicker
        openList={showItemPicker}
        returnItem={handleReturnList}
        list={filterList}
        heading={"an option"}
      />
    </>
  );
};

export default TransactionHistory;
