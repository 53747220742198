import React from "react";
import CustomHeader from "../../common/CustomHeader";

const InvitationRules = () => {
  const list = [
    {
      bonus: 55.0,
      people: 1,
      amount: 300.0,
    },
    {
      bonus: 155.0,
      people: 3,
      amount: 300.0,
    },
    {
      bonus: 555.0,
      people: 10,
      amount: 500.0,
    },
    {
      bonus: 1555.0,
      people: 30,
      amount: 800.0,
    },
    {
      bonus: 2775.0,
      people: 50,
      amount: 1200.0,
    },
    {
      bonus: 4165.0,
      people: 75,
      amount: 1200.0,
    },
    {
      bonus: 5555.0,
      people: 100,
      amount: 1200.0,
    },
    {
      bonus: 11111.0,
      people: 200,
      amount: 1200.0,
    },
    {
      bonus: 27777.0,
      people: 500,
      amount: 1200.0,
    },
    {
      bonus: 55555.0,
      people: 1000,
      amount: 1200.0,
    },
    {
      bonus: 111111.0,
      people: 2000,
      amount: 1200.0,
    },
    {
      bonus: 277777.0,
      people: 5000,
      amount: 1200.0,
    },
  ];
  const rules = [
    "Only when the number of invited accounts is reached and each account can meet the recharge amount can you receive the bonus.",
    "The invitation account meets the requirements, but the recharge amount of the account does not meet the requirements, and the bonus cannot be claimed.",
    "Please claim the event bonus within the event period. All bonuses will be cleared after the event expires.",
    "Please complete the task within the event period. After the event expires, the invitation record will be cleared.",
  ];

  return (
    <>
      <CustomHeader
        bg={"var(--themeBlack)"}
        color={"var(--mainText)"}
        heading={"Invitation reward rules"}
        path={"/activity/InvitationBonus"}
      />
      <div className="tip-box">
        Invite friends and recharge to get additional platform rewards!
      </div>
      <div className="tip-box">
        After being claimed, the rewards will be directly distributed to the
        wallet balance within 10 minutes.
      </div>
      <div className="table-container game-rule-table">
        <div className="table">
          <div className="table-header">
            <div className="table-row">
              <div className="table-col">Invite account</div>
              <div className="table-col">Deposit amount</div>
              <div className="table-col">Bonus</div>
            </div>
          </div>
          <div className="table-body">
            {list.map((v, i) => (
              <div
                className={`table-row ${i % 2 === 1 ? "odd" : "even"}`}
                key={i}
              >
                <div className="table-col">{v.people}People</div>
                <div className="table-col">₹{v.amount.toLocaleString()}.0</div>
                <div className="table-col">₹{v.bonus.toLocaleString()}.0</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="rule-container">
        {rules.map((v, i) => (
          <div className="list" key={i}>
            <span>{v}</span>
          </div>
        ))}
      </div>
    </>
  );
};

export default InvitationRules;