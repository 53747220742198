import React, { useState } from "react";
import CommonHeader from "../../common/CommonHeader";
import GradientButton from "../GradientButton";
import { feedback } from "../../../assets/media";
import Toast from "../Toast";
import { useNavigate } from "react-router-dom";

const MyFeedback = () => {
  const [des, setDes] = useState("");

  const navigate = useNavigate();

  const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });
  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 3000);
  };

  const handleSubmit = () => {
    if (des.trim().length < 10) {
      showToast(`<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Write a proper feedback`);
    } else {
      showToast("Feedback submitted successfully");
      setTimeout(() => {
        navigate("/account");
      }, 1000);
    }
  };

  return (
    <>
      <CommonHeader
        path="/account"
        heading="Feedback"
        svg={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
          >
            <path
              fill="var(--lightText)"
              d="m3.55 12l7.35 7.35q.375.375.363.875t-.388.875t-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675T.825 12t.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388t.375.875t-.375.875z"
            />
          </svg>
        }
      />
      <div className="issue-container">
        <div className="input-container">
          <div className="input-group">
            <textarea
              className="d-input password"
              value={des}
              onChange={(e) => setDes(e.target.value)}
              placeholder="Welcome to feedback, please give feedback-please describe the problem in detail when providing feedback, preferably attach a screenshot of the problem you encountered, we will immediately process your feedback!"
              style={{
                resize: "vertical",
                height: "305px",
                fontFamily: "inter",
              }}
              rows={7}
            ></textarea>
          </div>
        </div>
        <div className="d-flex flex-col align-center">
          <span
            style={{
              textAlign: "center",
              color: "var(--lightText)",
              fontSize: ".85rem",
            }}
          >
            Send helpful feedback
          </span>
          <span
            style={{
              textAlign: "center",
              color: "var(--lightText)",
              fontSize: ".85rem",
            }}
          >
            Chance to win Mystery Rewards
          </span>
          <img
            src={feedback}
            alt=""
            className="mt-2"
            style={{ width: "200px" }}
          />
        </div>
        <div
          className="make-att-btn"
          style={{ margin: "50px auto" }}
          onClick={handleSubmit}
        >
          <GradientButton
            bg={"var(--main_gradient-color)"}
            text={"Submit"}
            color={"#fff"}
            fw={"bold"}
          />
        </div>
      </div>
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
    </>
  );
};

export default MyFeedback;
