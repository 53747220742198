import React, { useEffect, useState } from "react";
import CustomHeader from "../../common/CustomHeader";
import DatePicker from "../DatePicker";
import { formatMoney, showLoader } from "../../../utils/Utils";
import { commissionTeam } from "../../../utils/UserController";

const CommissionDetails = () => {
  const now = new Date();
  const currentdate = `${now.getFullYear()}-${
    now.getMonth() + 1
  }-${now.getDate()}`;
  const [selectedDate, setSelectedDate] = useState(currentdate);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [commissiondatasData, setPromotion] = useState([]);

  const handleOpenDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };
  const handleReturnDate = (date) => {
    setSelectedDate((val) => {
      return date;
    });
  };

  // commissionTeam
  useEffect(() => {
    const getcommissionTeam = async () => {
      showLoader(true);
      try {
        const res = await commissionTeam();
        setPromotion(res.data);
        showLoader(false);
      } catch (error) {
        console.log(error);
      }
    };
    getcommissionTeam();
  }, []);


  const calculateTotals = async (data) => {
    if (!Array.isArray(data)) {
      return { userLevel: 0, totalBetAmount: 0, totalCommsionsAmount: 0 };
    }
    return data.reduce(
      (totals, item) => {
        totals.userLevel += item.level || 0;
        totals.totalBetAmount += parseFloat(item.amount) || 0;
        totals.totalCommsionsAmount += parseFloat(item.commission) || 0;
        return totals;
      },
      { userLevel: 0, totalBetAmount: 0, totalCommsionsAmount: 0 }
    );
  };
  
  const filterDataByDate = async (data, date) => {
    if (!Array.isArray(data)) {
      return [];
    }
    return data.filter((item) => {
      if (item.date && item.date !== 0) {
        const itemDate = new Date(item.date).toISOString().split('T')[0];
        return itemDate === date;
      }
      return false;
    });
  };
  

  const [totals, setTotals] = useState({ userLevel: 0, totalBetAmount: 0, totalCommsionsAmount: 0 });
// console.log("dfff",totals)
useEffect(() => {
  const fetchTotals = async () => {
    if (commissiondatasData && commissiondatasData.length > 0) {
      const filteredData = selectedDate ? await filterDataByDate(commissiondatasData, selectedDate) : commissiondatasData;
      const calculatedTotals = await calculateTotals(filteredData);
      setTotals(calculatedTotals);
    }
  };

  fetchTotals();
}, [selectedDate, commissiondatasData]);


  return (
    <>
      <CustomHeader
        path={"/promotion"}
        bg={"var(--themeBlack)"}
        color={"var(--mainText)"}
        heading={"Commission details"}
      />
      <div className="select-container">
        <div className="select" onClick={handleOpenDatePicker}>
          <span>{selectedDate}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m7 10l5 5l5-5"
            />
          </svg>
        </div>
      </div>
      <DatePicker openDate={showDatePicker} returnDate={handleReturnDate} />

      <div className="container-section mt-5">
          <div className='nav-bg rounded-md mt-3 pb-7 px-3 pt-1' >
           <p className='text-sm gray-100'> Settlement successful</p>
           <p className='text-sm gray-100'> date</p>
           <p className='text-sm gray-100'> The commission has been automatically credited your balance</p>
           <hr className='border-color-slat mt-1' />
            <div className='mt-3 flex justify-between items-center gray-100 text-sm'>
              <span className='text-sm'>Number of bettors</span>
              <span className='text-sm '>{totals.userLevel
              }</span>
            </div>

            <div className=' flex justify-between items-center mt-3 gray-100 text-sm'>
              <span className='text-sm font-medium'>Bet Amount</span>
              <span className='text-sm font-medium color-yellow-200'>{totals.totalBetAmount.toFixed(2)}</span>
            </div>
            <div className=' flex justify-between items-center mt-3 gray-100 text-sm'>
              <span className='text-sm font-medium'>Commission payout</span>
              <span className='text-sm font-medium color-yellow-200'>{totals.totalCommsionsAmount.toFixed(2)}</span>
            </div>
            <div className=' flex justify-between items-center mt-3 gray-100 text-sm'>
              <span className='text-sm font-medium'>Time</span>
              <span className='text-sm font-medium color-yellow-200'>{}</span>
            </div>
            
          </div>
    </div>


    </>
  );
};

export default CommissionDetails;
