import React, { useEffect, useState } from "react";
import CustomHeader from "../../common/CustomHeader";
import { formatDate, formatMoney, formatString } from "../../../utils/Utils";
import { Link, useNavigate } from "react-router-dom";
import {
  getUser,
  getUserBank,
  getWithdrawalList,
} from "../../../utils/UserController";
import {
  balanceIcon,
  bankAddIcon,
  bankIcon,
  leftArrow,
  noData,
  refreshIcon,
  supportCall,
  usdtIcon,
} from "../../../assets/media";
import Toast from "../Toast";
import GradientButton from "../GradientButton";
import "../../../assets/css/wallet.css";
import { Widthdraw } from "../../../utils/PaymentController";

const Withdraw = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [activeOption, setActiveOption] = useState("Bank-Card");
  const [activeAmount, setActiveAmount] = useState("");
  const [usdtAmount, setUsdtAmount] = useState("");
  const [message, setMessage] = useState("");
  const [usdtMessage, setUsdtMessage] = useState("");
  const [trigger, setTrigger] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [userBanks, setUserBanks] = useState([]);
  const [withdrawHistory, setWithdrawHistory] = useState([]);

  const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });

  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 3000);
  };
  const handleOptionChange = (option) => {
    setActiveOption(option);
  };

  const handleAllClick = () => {
    if (user?.money > 200) {
      setActiveAmount(Math.floor(user?.money));
      setUsdtAmount((Math.floor(user?.money) * (1 / 96)).toFixed(0));
    } else {
      setMessage("Insufficient balance");
    }
  };
  const handleInputChange = (event) => {
    if (isNaN(event.target.value)) {
        setActiveAmount("");
      return;
    }
    if (event.target.value * (1 / 96) < 10) {
      setUsdtMessage("Not less than 10 USDT");
    } else {
      setUsdtMessage("");
    }
    setActiveAmount(Math.floor(event.target.value));
    setUsdtAmount((Math.floor(event.target.value) * (1 / 96)).toFixed(2));
  };
  const handleUsdtInputChange = (event) => {
    if (isNaN(event.target.value)) {
      return;
    }
    if (event.target.value < 10) {
      setUsdtMessage("Not less than 10 USDT");
    } else {
      setUsdtMessage("");
    }
    setUsdtAmount(event.target.value);
    setActiveAmount((event.target.value / 96).toFixed(2));
  };
  const moneyRefresh = async () => {
    const userData = await getUser();
      if (userData.status === 200) {
        if (userData.data.status === "expired") {
          navigate("/login");
        }
        setUser(userData.data.data);
        showToast(
          `<svg width="25px" height="25px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#fff" stroke="#fff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M56 2L18.8 42.909L8 34.729H2L18.8 62L62 2z" fill="#fff"></path></g></svg>Refresh successfully`
        );
      } else {
        navigate("/login");
      }
  };


  const withdraw = async () => {
      
      if (activeOption === "USDT") {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Please contact support.`
      );
      return;
    }
      
    if (userBanks.length === 0) {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Please add a bank account.`
      );
      return;
    }
    if (isNaN(activeAmount) || Number(activeAmount) == 0) {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Please enter a valid amount`
      );
      return;
    }
    if (Number(activeAmount) < 110) {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Please enter a amount more than ₹110.00`
      );
      return;
    }

    if (Number(activeAmount) > 100000) {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Please enter a amount less than ₹1 Lakh`
      );
      return;
    }
    if (Number(activeAmount) > Number(user?.money)) {
      showToast(
        `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>Please enter a amount less than you have already`
      );
      return;
    }
    try {
     
      const res = await Widthdraw(activeOption,activeAmount);
      showToast(res.message);
      if (res.status) {
        navigate("/wallet/WithdrawHistory");
      }
    } catch (error) {}
  };

  useEffect(() => {
    const getUserData = async () => {
      const userData = await getUser();
      if (userData.status === 200) {
        if (userData.data.status === "expired") {
          navigate("/login");
        }
        setUser(userData.data.data);
      } else {
        navigate("/login");
      }
    };
    const getUserBankData = async () => {
      try {
        const res = await getUserBank();
        
        if (res.data.status) {
          setUserBanks(res.data.datas);
          return;
        } else {
          showToast(
            `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>${res.data.message}`
          );
        }
      } catch (error) {
        console.log(error);
      }
    };
    getUserData();
    getUserBankData();
  }, [trigger]);
  useEffect(() => {
    const getUserData = async () => {
      const userData = await getUser();
      if (userData.status === 200) {
        if (userData.data.status === "expired") {
          navigate("/login");
        }
        setUser(userData.data.data);
      } else {
        navigate("/login");
      }
    };
    getUserData();
  }, []);

  useEffect(() => {
    const getWithdrawalListData = async () => {
      try {
        const res = await getWithdrawalList();
        setWithdrawHistory(res.data.datas);
      } catch (error) {
        setWithdrawHistory([]);
        console.log(error);
      }
    };
    getWithdrawalListData();
  }, [trigger]);

  return (
    <>
      <CustomHeader
        path={"/wallet"}
        bg={"var(--themeBlack)"}
        color={"var(--mainText)"}
        heading={"Withdraw"}
        others={`Withdrawal history`}
        otherpath={"/wallet/WithdrawHistory"}
      />
      <div className="deposit-container" style={{ marginTop: "20px" }}>
        <div className="d-flex align-center">
          <img className="b-icon" src={balanceIcon} alt="" />
          <span className="txt">Balance</span>
        </div>
        <div className="money-container">
          <span className="user-money">{formatMoney(user?.money_user)}</span>
          <img
            className="refresh-icon"
            src={refreshIcon}
            alt=""
            onClick={() => moneyRefresh() }
          />
        </div>
      </div>
      <div className="payment-option-container">
        <div
          className={`payment-option ${
            "Bank-Card" === activeOption ? "active" : ""
          }`}
          onClick={() => handleOptionChange("Bank-Card")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40px"
            height="40px"
            viewBox="0 0 24 24"
          >
            <defs>
              <mask id="solarCardBold0">
                <g fill="none">
                  <path
                    fill="#fff"
                    d="M14 4h-4C6.229 4 4.343 4 3.172 5.172c-.844.843-1.08 2.057-1.146 4.078h19.948c-.066-2.021-.302-3.235-1.146-4.078C19.657 4 17.771 4 14 4m-4 16h4c3.771 0 5.657 0 6.828-1.172C22 17.657 22 15.771 22 12c0-.442 0-.858-.002-1.25H2.002C2 11.142 2 11.558 2 12c0 3.771 0 5.657 1.172 6.828C4.343 20 6.229 20 10 20"
                  />
                  <path
                    fill="var(--lightText)"
                    fillRule="evenodd"
                    d="M5.25 16a.75.75 0 0 1 .75-.75h4a.75.75 0 0 1 0 1.5H6a.75.75 0 0 1-.75-.75m6.5 0a.75.75 0 0 1 .75-.75H14a.75.75 0 0 1 0 1.5h-1.5a.75.75 0 0 1-.75-.75"
                    clipRule="evenodd"
                  />
                </g>
              </mask>
            </defs>
            <path
              fill="#ffef62"
              d="M0 0h24v24H0z"
              mask="url(#solarCardBold0)"
            />
          </svg>
          <span>Bank Card</span>
        </div>
        <div
          className={`payment-option ${
            "USDT" === activeOption ? "active" : ""
          }`}
          onClick={() => handleOptionChange("USDT")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40px"
            height="40px"
            viewBox="0 0 32 32"
          >
            <g fill="none" fillRule="evenodd">
              <circle cx="16" cy="16" r="16" fill="#26a17b" />
              <path
                fill="#fff"
                d="M17.922 17.383v-.002c-.11.008-.677.042-1.942.042c-1.01 0-1.721-.03-1.971-.042v.003c-3.888-.171-6.79-.848-6.79-1.658c0-.809 2.902-1.486 6.79-1.66v2.644c.254.018.982.061 1.988.061c1.207 0 1.812-.05 1.925-.06v-2.643c3.88.173 6.775.85 6.775 1.658c0 .81-2.895 1.485-6.775 1.657m0-3.59v-2.366h5.414V7.819H8.595v3.608h5.414v2.365c-4.4.202-7.709 1.074-7.709 2.118c0 1.044 3.309 1.915 7.709 2.118v7.582h3.913v-7.584c4.393-.202 7.694-1.073 7.694-2.116c0-1.043-3.301-1.914-7.694-2.117"
              />
            </g>
          </svg>
          <span>USDT</span>
        </div>
      </div>
      <div className="bankadd-container">
        {activeOption === "USDT" ? (
          <div className="bankadd-btn" style={{ gap: "10px" }}>
            <img src={supportCall} alt="" style={{ width: "30px" }} />
            <span>Contact to the administator!</span>
          </div>
        ) : (
          <>
      
            {userBanks && userBanks?.length > 0 ? (
              userBanks
                .map((item, i) => (
                  <React.Fragment key={i}>
                    <Link to={"/wallet/BankAccount"}>
                      <div className="bank-item">
                        <div className="bank-icon">
                          <img src={bankIcon} alt="" />
                          <span className="bank-name">{item.name_bank
}</span>
                        </div>
                        <div className="account-no">
                          {formatString(item.stk, 6, 3)}
                        </div>
                        <img src={leftArrow} alt={""} className="left-arrow" />
                      </div>
                    </Link>
                    <div className="size-box-10"></div>
                  </React.Fragment>
                ))
            ) : (
              <Link to={"/wallet/AddBank"}>
                <div className="bankadd-btn">
                  <img src={bankAddIcon} alt="" />
                  <span>Add a bank account number</span>
                </div>
              </Link>
            )}
          </>
        )}
      </div>
      {userBanks.length === 0 ? (
        <div className="guid">
          Need to add beneficiary information to be able to withdraw money
        </div>
      ) : (
        ""
      )}

      <div className="withdraw-container">
        {activeOption === "USDT" ? (
          <>
            <div className="usdt-header">
              <img src={usdtIcon} alt="" />
              <span>Select amount of USDT</span>
            </div>
            <div
              className="withdraw-input-container"
              style={{ borderRadius: "5px", marginBottom: "10px" }}
            >
              <div className="symbol">₹</div>
              <input
                type="text"
                value={activeAmount}
                placeholder="Please enter the amount"
                onChange={handleInputChange}
              />
            </div>
            {true ? <span className="wm text-red">uyuyuyt{message}</span> : ""}
            {usdtMessage ? <span className="wm">{usdtMessage}</span> : ""}
            <div
              className="withdraw-input-container"
              style={{ borderRadius: "5px", marginBottom: "10px" }}
            >
              <div className="symbol">
                <img src={usdtIcon} alt="" />
              </div>
              <input
                type="text"
                value={usdtAmount}
                placeholder="Please enter the amount"
                onChange={handleUsdtInputChange}
              />
            </div>

            <div
              className="d-flex align-center justify-between"
              style={{
                padding: "10px 0px",
                fontSize: ".9rem",
                color: "#768096",
              }}
            >
              <span className="d-flex align-center">
                Withdrawable balance{" "}
                <span style={{ color: "#ff9832", marginLeft: "5px" }}>
                  {formatMoney(user?.deposite_money >= 1000 ? user?.money : 0)}
                </span>
              </span>
              <span className="wab" onClick={handleAllClick}>
                All
              </span>
            </div>
          </>
        ) : (
          <>
            <div className="withdraw-input-container">
              <div className="symbol">₹</div>
              <input
                type="text"
                value={activeAmount}
                placeholder="Please enter the amount"
                onChange={handleInputChange}
              />
            </div>
            {message ? <span className="wm">{message}</span> : ""}
            <div
              className="d-flex align-center justify-between"
              style={{
                padding: "10px 0px",
                fontSize: ".9rem",
                color: "#768096",
              }}
            >
              <span className="d-flex align-center">
                Withdrawable balance{" "}
                <span style={{ color: "#ff9832", marginLeft: "5px" }}>
                  {formatMoney(user?.deposite_money >= 1000 ? user?.money_user : 0)}
                </span>
              </span>
              <span className="wab" onClick={handleAllClick}>
                All
              </span>
            </div>
            <div
              className="d-flex align-center justify-between"
              style={{ fontSize: ".9rem", color: "#768096" }}
            >
              <span>Withdrawal amount received </span>
              <span style={{ color: "#ff9832", fontSize: "1.2rem" }}>
                {formatMoney(activeAmount)}
              </span>
            </div>
          </>
        )}
        <div className="btn" onClick={withdraw}>
          <GradientButton
            text={"Withdraw"}
            bg={"var(--main_gradient-color)"}
            color={"#fff"}
            padding={"13px 10px"}
          />
        </div>
        <div className="w-rule-container">
          <div className="list">
            <span>
              Need to bet{" "}
              <span class="text-red">
                {formatMoney(user?.needbet)}
              </span>{" "}
              to be able to withdraw
            </span>
          </div>
          <div className="list">
            <span>
              Withdraw time <span class="text-red">00:05-23:55</span>
            </span>
          </div>
          <div className="list">
            <span>
              Inday Remaining Withdrawal Times <span class="text-red">4</span>
            </span>
          </div>
          <div className="list">
            <span>
              Withdrawal amount range{" "}
              <span class="text-red">₹110.00-₹100,000.00</span>
            </span>
          </div>
          <div className="list">
            <span>
              Please confirm your beneficial account information before
              withdrawing. If your information is incorrect, our company will
              not be liable for the amount of loss
            </span>
          </div>
          <div className="list">
            <span>
              If your beneficial information is incorrect, please contact
              customer service
            </span>
          </div>
        </div>
      </div>
      <div className="deposit-history-header">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25px"
          height="25px"
          viewBox="0 0 24 24"
        >
          <path
            fill="none"
            stroke="#6485ff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="m3.346 16.198l12.829-12.83m2.457 7.688l-2.2 2.2m-1.877 1.854l-.979.979m-10.401.052a4.01 4.01 0 0 1 0-5.672l7.294-7.294a4.01 4.01 0 0 1 5.672 0l4.684 4.684a4.01 4.01 0 0 1 0 5.672l-7.294 7.294a4.01 4.01 0 0 1-5.672 0zM4 22h16"
            color="#23367d"
          />
        </svg>
        <span>Withdrawal history</span>
      </div>
      {withdrawHistory && withdrawHistory?.length > 0 ? (
        <>
          <div className="deposit-history">
            {withdrawHistory?.slice(0, 5).map((v, i) => (
              <div className="deposit-history-item" key={i}>
                <div className="history-item-header">
                  <div className="dtb" style={{background: "#f95959"}}>Withdraw</div>
                  <div className="d-flex align-center left-arrow">
                    <span
                      style={{
                        color:
                          v.status === 1
                            ? "#00ae50"
                            : v.status === 2
                            ? "#f95959"
                            : "#ff9832",
                      }}
                    >
                      {v.status === 1
                        ? "Completed"
                        : v.status === 2
                        ? "Rejected"
                        : "Processing"}
                    </span>
                    <img src={leftArrow} alt={""} />
                  </div>
                </div>
                <div className="history-item-content">
                  <div className="d-flex justify-between align-center">
                    <span>Balance</span>
                    <span className="balance">{formatMoney(v.money)}</span>
                  </div>
                  <div className="d-flex justify-between align-center">
                    <span>Type</span>
                    <span>{"BANK CARD"}</span>
                  </div>
                  <div className="d-flex justify-between align-center">
                    <span>Time</span>
                    <span>{v.today}</span>
                  </div>
                  <div className="d-flex justify-between align-center">
                    <span>Order number</span>
                    <div className="d-flex">
                      <span>{v.id_order}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15px"
                        height="15px"
                        viewBox="0 0 256 256"
                      >
                        <path
                          fill="#768096"
                          d="M216 32H88a8 8 0 0 0-8 8v40H40a8 8 0 0 0-8 8v128a8 8 0 0 0 8 8h128a8 8 0 0 0 8-8v-40h40a8 8 0 0 0 8-8V40a8 8 0 0 0-8-8m-56 176H48V96h112Zm48-48h-32V88a8 8 0 0 0-8-8H96V48h112Z"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <div className="d-flex justify-between align-center">
                    <span>Remark</span>
                    <span>{v.remark}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="btn-container">
            <GradientButton
              text={"All history"}
              bg={"var(--main_gradient-color)"}
              color={"#fff"}
              path={"/wallet/WithdrawHistory"}
            />
          </div>
        </>
      ) : (
        <div className="empty">
          <img src={noData} alt="" className="noData" />
          <span>No Data</span>
        </div>
      )}

      <div className="size-box-100"></div>
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
    </>
  );
};

export default Withdraw;
