import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { getUser } from "./UserController";
import loadingRing from "../assets/img/spin_ring.png";
import animlogo from "../assets/logo/main_logo.png";

const getTokenFromCookies = () => {
  const cookies = document.cookie.split("; ");
  const tokenCookie = cookies.find(row => row.startsWith("auth="));
  return tokenCookie ? tokenCookie.split("=")[1] : null;
};

export const ProtectedRoute = ({ element: Component, ...rest }) => {
  const cookies = document.cookie.split("; ");
  
  const [isLoading, setIsLoading] = useState(true);
  const [isToken, setIsToken] = useState(!!getTokenFromCookies())
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    const checkUser = async () => {
      if (isToken) {
        const userData = await getUser();
        setIsExpired(!userData.data.status);
      }
      setIsLoading(false);
    };
    checkUser();
  }, [isToken]);
  if (isLoading) {
    return (
      <div className="loader">
        <div className="loading-box">
          <img src={animlogo} alt="Loading logo" className="loading-mid" />
          <img src={loadingRing} alt="Loading ring" className="loading-ring amin-spin" />
        </div>
      </div>
    );
  }

  if (!isToken || isExpired) {
    return <Navigate to="/login" />;
  }

  return <Component {...rest} />;
};

export const LoginProtectedRoute = ({ element: Component, ...rest }) => {
  const isLoggedIn = !!localStorage.getItem("token");

  return isLoggedIn ? <Navigate to="/account" /> : <Component {...rest} />;
};
