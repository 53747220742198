import React, { useEffect, useRef, useState } from "react";
import CustomHeader from "../../common/CustomHeader";
import { useNavigate } from "react-router-dom";

import { allTab, leftArrow, usdtIcon, bankCard, noData } from "../../../assets/media";
import { formatDate, formatMoney } from "../../../utils/Utils";
import DatePicker from "../DatePicker";
import ListPicker from "../ListPicker";
import { getWithdrawalList } from "../../../utils/UserController";

const WithdrawHistory = () => {
  const now = new Date();
  const currentdate = `${now.getFullYear()}-${
    now.getMonth() + 1
  }-${now.getDate()}`;
  const [selectedDate, setSelectedDate] = useState("Choose a date");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showItemPicker, setShowItemPicker] = useState(false);
  const [selectedItem, setSelectedItem] = useState("All");
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const tabsRef = useRef(null);
  const [withdrawHistory, setWithdrawHistory] = useState([]);

  const tabs = [
    {
      image: allTab,
      label: "All",
    },
    {
      image: bankCard,
      label: "Bank-Card",
    },
    {
      image: usdtIcon,
      label: "USDT",
    },
  ];

  const handleOpenDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleReturnDate = (date) => {
    setSelectedDate((val) => {
      return date;
    });
  };

  const handleOpenListPicker = () => {
    setShowItemPicker(!showItemPicker);
  };

  const handleReturnList = (item) => {
    setSelectedItem((val) => {
      return item;
    });
  };

  const filterList = ["All", "Processing", "Completed", "Rejected"];

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const getWithdrawalListData = async () => {
      try {
        const res = await getWithdrawalList()
        setWithdrawHistory(res.data.datas);
      } catch (error) {
        setWithdrawHistory([]);
        console.log(error);
      }
    };

    getWithdrawalListData();
  }, [selectedItem, selectedDate, activeTab]);

  useEffect(() => {
    if (tabsRef.current) {
      const activeTabElement = tabsRef.current.children[activeTab];
      const tabContainerWidth = tabsRef.current.offsetWidth;
      const tabElementWidth = activeTabElement.offsetWidth;
      const scrollPosition =
        activeTabElement.offsetLeft -
        (tabContainerWidth / 2 - tabElementWidth / 2);
      tabsRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [activeTab]);

  return (
    <>
      <CustomHeader
        click={goBack}
        bg={"var(--themeBlack)"}
        color={"var(--mainText)"}
        heading={"Withdraw history"}
      />
      <div className="games-tabs-container" ref={tabsRef}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`d-tab ${index === activeTab ? "active" : ""}`}
            onClick={() => setActiveTab(index)}
          >
            <img
              src={tab.image}
              alt=""
              style={{
                filter: `${activeTab === 0 ? "brightness(5)" : ""}`,
              }}
            />
            {tab.label}
          </div>
        ))}
      </div>
      <div className="select-container">
        <div className="select" onClick={handleOpenListPicker}>
          <span>{selectedItem}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m7 10l5 5l5-5"
            />
          </svg>
        </div>
        <div className="select" onClick={handleOpenDatePicker}>
          <span>{selectedDate}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m7 10l5 5l5-5"
            />
          </svg>
        </div>
      </div>

      <div className="d-tab-content">
        <div className="deposit-history">
          {withdrawHistory && withdrawHistory?.length > 0 ? (
            withdrawHistory.map((v, i) => (
              <div className="deposit-history-item" key={i}>
                <div className="history-item-header">
                  <div className="dtb" style={{background: "#f95959"}} >Withdraw</div>
                  <div className="d-flex align-center left-arrow">
                    <span
                      style={{
                        color:
                          v.status === 1
                            ? "#00ae50"
                            : v.status === 2
                            ? "#f95959"
                            : "#ff9832",
                      }}
                    >
                      {v.status === 1
                        ? "Completed"
                        : v.status === 2
                        ? "Rejected"
                        : "Processing"}
                    </span>
                    <img src={leftArrow} alt={""} />
                  </div>
                </div>
                <div className="history-item-content">
                  <div className="d-flex justify-between align-center">
                    <span>Balance</span>
                    <span className="balance">{formatMoney(v.money)}</span>
                  </div>
                  <div className="d-flex justify-between align-center">
                    <span>Type</span>
                    <span>{v.type}</span>
                  </div>
                  <div className="d-flex justify-between align-center">
                    <span>Time</span>
                    <span>{v.today}</span>
                  </div>
                  <div className="d-flex justify-between align-center">
                    <span>Order number</span>
                    <div className="d-flex">
                      <span>{v.id_order}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15px"
                        height="15px"
                        viewBox="0 0 256 256"
                      >
                        <path
                          fill="#768096"
                          d="M216 32H88a8 8 0 0 0-8 8v40H40a8 8 0 0 0-8 8v128a8 8 0 0 0 8 8h128a8 8 0 0 0 8-8v-40h40a8 8 0 0 0 8-8V40a8 8 0 0 0-8-8m-56 176H48V96h112Zm48-48h-32V88a8 8 0 0 0-8-8H96V48h112Z"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <div className="d-flex justify-between align-center">
                    <span>Remark</span>
                    <span>{v.remark}</span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="empty">
              <img src={noData} alt="" className="noData" />
              <span>No Data</span>
            </div>
          )}
        </div>
      </div>
      <DatePicker openDate={showDatePicker} returnDate={handleReturnDate} />
      <ListPicker
        openList={showItemPicker}
        returnItem={handleReturnList}
        list={filterList}
        heading={"an option"}
      />
    </>
  );
};

export default WithdrawHistory;
