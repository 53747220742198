import React, { useEffect, useState } from "react";
import CustomHeader from "../../common/CustomHeader";
import DatePicker from "../DatePicker";
import ListPicker from "../ListPicker";
import { getPromotion, myTeamReportSubordinate } from "../../../utils/UserController";
import { formatMoney, showLoader } from "../../../utils/Utils";
import Toast from "../Toast";


const SubordinateData = () => {
  const now = new Date();
  const currentdate = `${now.getFullYear()}-${now.getMonth() + 1
    }-${now.getDate()}`;
  const [selectedDate, setSelectedDate] = useState(currentdate);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showItemPicker, setShowItemPicker] = useState(false);
  const [selectedItem, setSelectedItem] = useState("All");
  const [promotion, setPromotion] = useState([]);
  const [searchInput, setSearchInput] = useState(null);
  const [searchLevels, setSearchLevels] = useState(null);



  const handleOpenDatePicker = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleReturnDate = async (date) => {
    const res = await myTeamReportSubordinate(date);
    setPromotion(res.data);
    setSelectedDate((val) => {
      return date;
    });
  };

  const handleOpenListPicker = () => {
    setShowItemPicker(!showItemPicker);
  };



  useEffect(() => {
    const getPromorationData = async () => {
      showLoader(true);
      try {
        const getYesterdayDate = () => {
          const today = new Date();
          today.setDate(today.getDate() - 1);
          const year = today.getFullYear();
          const month = String(today.getMonth() + 1).padStart(2, "0");
          const day = String(today.getDate()).padStart(2, "0");
          return `${year}-${month}-${day}`;
        };
        const res = await myTeamReportSubordinate(getYesterdayDate());
        setPromotion(res.data);
        showLoader(false);
      } catch (error) {
        console.log(error);
      }
    };
    getPromorationData();
  }, []);


  let filteredData = promotion?.datas?.filter((item) => {
    const matchesId =
      searchInput !== null
        ? item?.userId?.toString().includes(searchInput)
        : true; // Show all if no searchInput is provided
    const match = selectedItem?.match(/\d+/);
    const number = match ? parseInt(match[0], 10) : null;

    const matchesLevel =
      number !== null ? item.userLevel.toString().includes(selectedItem) : true; // Show all if no searchLevel is provided
    return matchesId && matchesLevel;
  });

  let filteredDataLevel
  const handleReturnList = (item) => {
    filteredDataLevel = promotion?.levelData?.filter((item) => {
      const match = selectedItem?.match(/\d+/);
      const number = match ? parseInt(match[0], 10) : null;
      const matchesLevel =
        number !== null ? item.level.toString().includes(selectedItem) : true; // Show all if no searchLevel is provided
      return matchesLevel;
    });

    setSelectedItem((val) => {
      return item;
    });
  };



  const filterList = [
    "All",
    "Tier 1",
    "Tier 2",
    "Tier 3",
    "Tier 4",
    "Tier 5",
    "Tier 6",
  ];

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });
  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 3000);

    // Hide after 3 seconds
  };
  const handleCopy = (txt) => {
    navigator.clipboard
      .writeText(txt)
      .then(() => {
        showToast(
          `<svg width="25px" height="25px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" class="iconify iconify--emojione-monotone" preserveAspectRatio="xMidYMid meet" fill="#fff" stroke="#fff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M56 2L18.8 42.909L8 34.729H2L18.8 62L62 2z" fill="#fff"></path></g></svg>Copy successful`
        );
      })
      .catch((err) => {
        showToast("Failed to copy");
      });
  };

  return (
    <>
      <CustomHeader
        path={"/promotion"}
        bg={"var(--themeBlack)"}
        color={"var(--mainText)"}
        heading={"Subordinate data"}
      />
      <div className="search-container">
        <input type="text" placeholder="Search subordinate UID" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
        <div className="search-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.5"
              d="m21 21l-4.343-4.343m0 0A8 8 0 1 0 5.343 5.343a8 8 0 0 0 11.314 11.314"
            />
          </svg>
        </div>
      </div>
      <div className="select-container">
        <div className="select" onClick={handleOpenListPicker}>
          <span>{selectedItem}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m7 10l5 5l5-5"
            />
          </svg>
        </div>
        <div className="select" onClick={handleOpenDatePicker}>
          <span>{selectedDate}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25px"
            height="25px"
            viewBox="0 0 24 24"
          >
            <path
              fill="none"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m7 10l5 5l5-5"
            />
          </svg>
        </div>
      </div>
      <DatePicker openDate={showDatePicker} returnDate={handleReturnDate} />
      <ListPicker
        openList={showItemPicker}
        returnItem={handleReturnList}
        list={filterList}
        heading={"an item"}
      />
      <div className="dash-container">
        <div className="dash-box">

          {selectedItem === "All" ? (
            <span className="amt">{promotion ? promotion?.total_recharge_count : 0}</span>
          ) : (
            <span className="amt">
              {filteredDataLevel && filteredDataLevel.length > 0
                ? filteredDataLevel[0]?.total_recharge_count
                : 0}
            </span>

          )}
          <span className="txt">Deposit number</span>
        </div>
        <div className="dash-box">
          {selectedItem === "All" ? (

            <span className="amt">{promotion ? promotion?.total_recharge_amount : 0}</span>
          ) : (
            <span className="amt">  {filteredDataLevel && filteredDataLevel.length > 0
              ? filteredDataLevel[0]?.total_recharge_amount
              : 0}</span>

          )}



          <span className="txt">Deposit amount</span>
        </div>
        <div className="dash-box">
          {selectedItem === "All" ? (
            <span className="amt">{promotion ? promotion?.better_number : 0}</span>

          ) : (

            <span className="amt">
              {filteredDataLevel && filteredDataLevel.length > 0
                ? filteredDataLevel[0]?.better_number
                : 0}
            </span>
          )}
          <span className="txt">Number of bettors</span>
        </div>
        <div className="dash-box">
          {selectedItem === "All" ? (

            <span className="amt">{promotion ? promotion?.total_bet_count : 0}</span>
          ) : (
            <span className="amt">
              {filteredDataLevel && filteredDataLevel.length > 0
                ? filteredDataLevel[0]?.total_bet_count
                : 0}
            </span>

          )}
          <span className="txt">Total bet</span>
        </div>
        <div className="dash-box">
          {selectedItem === "All" ? (

            <span className="amt">{promotion ? promotion?.total_first_recharge_count : 0}</span>
          ) : (
            <span className="amt">
              {filteredDataLevel && filteredDataLevel.length > 0
                ? filteredDataLevel[0]?.total_first_recharge_count
                : 0}
            </span>

          )}
          <span className="txt">Number of people making first deposit</span>
        </div>
        <div className="dash-box">
          <span className="amt">{0}</span>
          <span className="txt">First deposit amount</span>
        </div>
      </div>
      <div className="container-section mt-5">
        {Array.isArray(filteredData) &&
          filteredData.map((item, i) => (
            <div className="nav-bg rounded-md mt-3 pb-7 px-3 pt-1" key={i}>
              <div className=" mt-3 flex justify-between items-center rounded-sm  text-sm pb-2 border-b border-[--grey-200]">
                <span className="text-sm font-medium text-white p-1 flex items-center border-b">
                  UID: {item?.userId}{" "}
                  <svg

                    onClick={() => handleCopy(item.userId)}
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    height="20px"
                    viewBox="0 0 256 256"
                  >
                    <path
                      fill="#768096"
                      d="M216 32H88a8 8 0 0 0-8 8v40H40a8 8 0 0 0-8 8v128a8 8 0 0 0 8 8h128a8 8 0 0 0 8-8v-40h40a8 8 0 0 0 8-8V40a8 8 0 0 0-8-8m-56 176H48V96h112Zm48-48h-32V88a8 8 0 0 0-8-8H96V48h112Z"
                    />
                  </svg>
                </span>
              </div>
              <div className="mt-3 flex justify-between items-center gray-100 text-sm">
                <span className="text-sm font-medium">Level</span>
                <span className="text-sm font-medium ">{item?.userLevel}</span>
              </div>

              <div className=" flex justify-between items-center mt-3 gray-100 text-sm">
                <span className="text-sm font-medium">Deposit Amount</span>
                <span className="text-sm font-medium color-yellow-200">
                  {item.totalRechargeAmount}
                </span>
              </div>
              <div className=" flex justify-between items-center mt-3 gray-100 text-sm">
                <span className="text-sm font-medium">Bet Amount</span>
                <span className="text-sm font-medium color-yellow-200">
                  {item.totalBetAmount}
                </span>
              </div>
              <div className=" flex justify-between items-center  mt-3 gray-100 text-sm">
                <span className="text-sm font-medium">Commission</span>
                <span className="fs-sm font-medium color-yellow-200">
                  {item.totalCommsionsAmount}
                </span>
              </div>
              <div className=" flex justify-between items-center  mt-3 gray-100 text-sm">
                <span className="text-sm font-medium">Time</span>
                <span className="text-sm font-medium flex items-center">
                  {formatDate(Number(item.dates))}{" "}
                </span>
              </div>
            </div>
          ))}
      </div>
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
    </>
  );
};

export default SubordinateData;
