import React from "react";
import { Link } from "react-router-dom";
import CustomHeader from "../../common/CustomHeader";
import "../../../assets/css/activity.css";
import { superJackpotBanner } from "../../../assets/media";
import GradientButton from "../GradientButton";

const SuperJackpot = () => {
  return (
    <>
      <CustomHeader
        bg={"var(--themeBlack)"}
        color={"var(--mainText)"}
        heading={"Super Jackpot"}
        path={"/activity"}
      />
      <div
        className="hero-header"
        style={{
          backgroundImage: `url(${superJackpotBanner}), linear-gradient(94deg, #f99937 2.72%, #ff6922 43.54%, #ff8039 98.54%)`,
          height: "200px",
          backgroundSize: "100% 100%",
        }}
      >
        <div className="hero-div1">
          <div className="hero-div1-heading" style={{ fontSize: "1.4rem" }}>
            Super Jackpot
          </div>
          <span className="hero-div1-text">
            When you get the Super Jackpot in 【Slots】Can get 1 additional
            bonus
          </span>
          <span className="hero-div1-text">
            The reward is valid for 1 day, and you will not be able to claim it
            after it expires!
          </span>
        </div>
      </div>
      <GradientButton
        text={"Receive in batches"}
        width={"95%"}
        textsize="0.85rem"
        bg={"linear-gradient(90deg, #CCCEDC 15.38%, #CDCFDD 98.73%)"}
        color={"#fff"}
      />
      <div className="super-tabs">
        <Link to={"#"}>
          <div className="super-tab">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25px"
              height="25px"
              viewBox="0 0 24 24"
            >
              <g
                fill="none"
                stroke="#6485ff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
              >
                <path d="M20 12V5.749a.6.6 0 0 0-.176-.425l-3.148-3.148A.6.6 0 0 0 16.252 2H4.6a.6.6 0 0 0-.6.6v18.8a.6.6 0 0 0 .6.6H13M8 10h8M8 6h4m-4 8h3" />
                <path d="M16 2v3.4a.6.6 0 0 0 .6.6H20m-.008 9.125l2.556.649c.266.068.453.31.445.584C22.821 22.116 19.5 23 19.5 23s-3.321-.884-3.493-6.642a.59.59 0 0 1 .445-.584l2.556-.649c.323-.082.661-.082.984 0" />
              </g>
            </svg>
            Rule
          </div>
        </Link>
        <Link to={"#"}>
          <div className="super-tab">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20px"
              height="20px"
              viewBox="0 0 36 36"
              style={{ marginBottom: "3px" }}
            >
              <path
                fill="#6485ff"
                d="M2.6 11.93a1.4 1.4 0 1 0 1.4 1.4a1.4 1.4 0 0 0-1.4-1.4"
              />
              <circle cx="33.83" cy="13.33" r="1.39" fill="#6485ff" />
              <path
                fill="#6485ff"
                d="M18.22 6.39A1.39 1.39 0 1 0 16.84 5a1.39 1.39 0 0 0 1.38 1.39m13.41 9.71A18.6 18.6 0 0 0 28 17.34a21.6 21.6 0 0 0-4 2.49a19.2 19.2 0 0 1-2.26-3.49a49 49 0 0 1-2.52-6.58a1 1 0 0 0-1-.71a1 1 0 0 0-1 .71a48.4 48.4 0 0 1-2.52 6.58a18.7 18.7 0 0 1-2.26 3.48a22.8 22.8 0 0 0-4-2.48A19 19 0 0 0 4.9 16.1a1 1 0 0 0-1 .33a1 1 0 0 0-.13 1.07a56 56 0 0 1 4 13.5a1 1 0 0 0 1 .83h19a1 1 0 0 0 1-.83a56 56 0 0 1 4-13.5a1 1 0 0 0-.13-1.07a1 1 0 0 0-1.01-.33M11.08 28.55a1.11 1.11 0 1 1 1.1-1.11a1.11 1.11 0 0 1-1.1 1.11m7.15 0a1.11 1.11 0 0 1 0-2.21a1.11 1.11 0 0 1 0 2.21m7.16 0a1.11 1.11 0 1 1 1.1-1.11a1.11 1.11 0 0 1-1.1 1.11"
              />
            </svg>
            Winning star
          </div>
        </Link>
      </div>
      <GradientButton
        text={"Go bet"}
        width={"90%"}
        path={"/"}
        textsize="0.9rem"
        bg={"var(--main_gradient-color)"}
        color={"#fff"}
      />
    </>
  );
};

export default SuperJackpot;
