import React, { useState, useRef, useEffect } from "react";
import CommonHeader from "../../common/CommonHeader";
import "../../../assets/css/games.css";
import lotteryLogo from "../../../assets/logo/lottery.png";
import Lottery from "../games/Lottery";
import MiniGame from "../games/MiniGame";
import Popular from "../games/Popular";
import Slots from "../games/Slots";
import Fishing from "../games/Fishing";
import PVC from "../games/PVC";
import Casino from "../games/Casino";
import Sports from "../games/Sports";
import { casino, fishing, pvc, slots, sports } from "../../../assets/media";
import miniGameLogo from "../../../assets/logo/minigamelogo.png";
import popularLogo from "../../../assets/logo/populargame.png";
import Toast from "../Toast";



const Games = () => {
  const [activeTab, setActiveTab] = useState(0);
  const tabsRef = useRef(null);
  const [toast, setToast] = useState({
    isVisible: false,
    message: "",
  });

  const showToast = (message) => {
    setToast({ isVisible: true, message });
    setTimeout(() => {
      setToast({ isVisible: false, message: "" });
    }, 5000);
  };
  const callGame = () => {
    showToast(
      `<svg xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="0 0 24 24"><path fill="#fff" d="M13 2v14.5h-2V2zm-2 17h2.004v2.004H11z"></path></svg>इस गेम को खेलने के लिए आपको कम से कम ₹500.0/- रुपये का रिचार्ज कराना होगा।`
    );
  };
  const tabs = [
    {
      image: lotteryLogo,
      label: "Lottery",
      comoponent: <Lottery viewall={false} callGame={callGame} />,
    },
    {
      image: miniGameLogo,
      label: "Mini Game",
      comoponent: <MiniGame viewall={false} callGame={callGame} />,
    },
    {
      image: popularLogo,
      label: "Popular",
      comoponent: <Popular viewall={false} callGame={callGame} />,
    },
    {
      label: "Slots",
      image: slots,
      comoponent: <Slots viewall={false} callGame={callGame} />,
    },
    {
      label: "Fishing",
      image: fishing,
      comoponent: <Fishing viewall={false} />,
    },
    {
      label: "PVC",
      image: pvc,
      comoponent: <PVC viewall={false} callGame={callGame} />,
    },
    {
      label: "Casino",
      image: casino,
      comoponent: <Casino viewall={false} callGame={callGame} />,
    },
    {
      label: "Sports",
      image: sports,
      comoponent: <Sports viewall={false} callGame={callGame} />,
    },

  ];
  
  useEffect(() => {
    if (tabsRef.current) {
      const activeTabElement = tabsRef.current.children[activeTab];
      const tabContainerWidth = tabsRef.current.offsetWidth;
      const tabElementWidth = activeTabElement.offsetWidth;
      const scrollPosition =
        activeTabElement.offsetLeft -
        (tabContainerWidth / 2 - tabElementWidth / 2);
      tabsRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }, [activeTab]);
  return (
    <>
      <CommonHeader
        path="/"
        heading="All"
        svg={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
          >
            <path
              fill="var(--lightText)"
              d="m3.55 12l7.35 7.35q.375.375.363.875t-.388.875t-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675T.825 12t.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388t.375.875t-.375.875z"
            />
          </svg>
        }
      />
      <div className="games-tabs-container" ref={tabsRef}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`games-tab ${index === activeTab ? "active" : ""}`}
            onClick={() => setActiveTab(index)}
          >
            <img src={tab.image} alt="" />
            {tab.label}
          </div>
        ))}
      </div>
      <div className="games-tab-content">
        <span className="games-tab-lebel">{tabs[activeTab].label}</span>
        {tabs[activeTab].comoponent}
      </div>
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        onClose={() => setToast({ isVisible: false, message: "" })}
      />
    </>
  );
};

export default Games;
