import React from "react";

const DownMode = () => {
  return (
    <div className="down-container">
      <div class="maintenance-icon">
        <div class="spinner"></div>
      </div>
      <h1>We're Currently Under Maintenance!</h1>
      <p>We’re working hard to improve our game. Please check back later.</p>
    </div>
  );
};

export default DownMode;
